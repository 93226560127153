import qs from 'query-string';

import {HTTP_CODES, TIME} from '@stubhub/general-utils';
import {Storage} from '@stubhub/legacy-cache/lib';
import {Controller} from '@stubhub/react-store-provider';
import {get, post, put} from '@stubhub/rest-method';
import {getDomainName, parseUrl} from '@stubhub/sh-url-utils';

import {socialButtonEnabledSelector} from './selectors';

const PERSISTENT_COOKIE_NAME_LIST = ['session_CSRFtoken', 'session_userGUID', 'track_session_userGUID'];
const ONE_YEAR_HOURS = TIME.DAYS_OF_YEAR * TIME.HOURS_OF_DAY;

const setCookiesData = (cookies, name, value) => {
  let domain = '.';
  let protocol = '';
  const loginSessionPersistent = PERSISTENT_COOKIE_NAME_LIST.some((item) => name === item);

  domain = __CLIENT__ && getDomainName(window.location.href);
  protocol = __CLIENT__ && parseUrl(window.location.href).protocol;

  if (name === 'session_CSRFtoken') {
    Storage.setInCookie(name, value, !loginSessionPersistent, ONE_YEAR_HOURS, domain, '/', protocol === 'https:');
  } else {
    cookies.set(name, value, {
      domain,
      path: '/',
      secure: protocol === 'https:',
      expires: loginSessionPersistent ? TIME.DAYS_OF_YEAR : undefined,
    });
  }
};

const _hasValidError = (response) => {
  const responseJson = (response && response.body) || /* istanbul ignore next */ {};
  const errorCode = responseJson.error && responseJson.error.code;
  const responseStatus = response && response.status;

  return (
    responseStatus === HTTP_CODES.BAD_REQUEST /* istanbul ignore next */ ||
    (responseStatus === HTTP_CODES.UNAUTHORIZED &&
      errorCode ===
        'com.stubhub.domain.security.iam.services.exception.UnAuthorizedException') /* istanbul ignore next */ ||
    (responseStatus === HTTP_CODES.RESOURCE_NOT_FOUND &&
      errorCode === 'com.stubhub.domain.security.iam.services.exception.InvalidSessionException')
  );
};

const clearSession = (cookies) => {
  cookies.remove('session_uAuthenticated');
  cookies.remove('session_proxyLogin');
  cookies.remove('session_login_type');
  cookies.remove('STUB_SESS');
  cookies.remove('session_sessionId');
  cookies.remove('session_contactGUID');
  cookies.remove('session_loginStatus');
  cookies.remove('session_CSRFtoken');
  cookies.remove('session_userGUID');
};

const WEB_SUB_DOMAIN_REG_EXP = /^www\.(.*)$/;

const SIGNIN_FLOW = {
  STARTED: 'STARTED',
  SIGNIN: 'SIGNIN',
  TOKEN_INIT: 'TOKEN_INIT',
  FETCH_USER_DATA: 'FETCH_USER_DATA',
};
const SIGNIN_TOPIC = {
  SET_SIGNIN_FLOW: 'SET_SIGNIN_FLOW',
  RESET_SIGNIN: 'RESET_SIGNIN',
};

const LOGIN_TYPE = {
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  CLOSE: 'CLOSE',
  TWO_FA_SEND_CODE: 'TWO_FA_SEND_CODE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DECISION_SOCIAL: 'DECISION_SOCIAL',
  CONNECT_SOCIAL: 'CONNECT_SOCIAL',
  REGISTER_SOCIAL: 'REGISTER_SOCIAL',
  DIRECT_SOCIAL: 'DIRECT_SOCIAL',
  SIGN_UP_SOCIAL: 'SIGN_UP_SOCIAL',
  BIND_SOCIAL: 'BIND_SOCIAL',
  SIGN_OUT: 'SIGN_OUT',
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
};
const LOGIN_TOPIC = {
  SET_LOGIN_TYPE: 'SET_LOGIN_TYPE',
  SET_PRIMARY_CONTACT: 'SET_PRIMARY_CONTACT',
  RESET_LOGIN_TYPE: 'RESET_LOGIN_TYPE',
  CACHE_TWO_FA_DATA: 'CACHE_TWO_FA_DATA',
  CACHE_FORCE_RESET_DATA: 'CACHE_FORCE_RESET_DATA',
  CACHE_ACCERTIFY_DATA: 'CACHE_ACCERTIFY_DATA',
};

const NAMESPACE = 'login';

if (!Object.values) {
  Object.values = function (o) {
    return Object.keys(o).map((k) => {
      return o[k];
    });
  };
}

const controller = new Controller({
  namespace: NAMESPACE,
  reducers: Object.values(LOGIN_TOPIC),
  actionCreators: {
    connectSocialAccounts,
    setLoginType,
    resetLoginType,
    autoLogin,
    signIn, // The signIn actionCreator will be used both in signup flow and signin flow, but in signup flow, the signup controller will not listen to the related dispatched topic, only in signin flow will do.
    signout,
    cacheAccertifyData,
  },
  mapStateToProps(state) {
    const localState = this.getLocalState(state);
    const {loginType} = localState;
    const {login_social} = state;
    const socialData = login_social ? login_social.socialData : null;
    const idpType = socialData ? socialData.idpType : null;

    return {
      loginType,
      idpType,
      socialButtonEnabled: socialButtonEnabledSelector(state),
    };
  },
});

function resetLoginType() {
  return (dispatch) => {
    dispatch({type: LOGIN_TOPIC.RESET_LOGIN_TYPE, loginType: null});
  };
}

function cacheAccertifyData(accertifyData) {
  return (dispatch) => {
    dispatch({type: LOGIN_TOPIC.CACHE_ACCERTIFY_DATA, accertifyData});
  };
}

// TODO delete this
async function updateUserAgreement({userGuid, state, payload}) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  Object.assign(headers, _getRequestHeaders(state));
  try {
    await put({
      host: process.env.REACT_APP_API_HOST,
      path: `/login/user/customers/v2/${userGuid}/ua`,
      headers,
      body: JSON.stringify(payload),
    });
  } catch (e) {
    console.log('--- update user agreement error', e); // eslint-disable-line
    throw e;
  }
}

function setLoginType(loginType) {
  return (dispatch) => {
    dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType});
  };
}

function _getRequestHeaders(state) {
  const ret = {};
  const {lang} = state;
  const w = __CLIENT__ && window;
  const hostname = w && w.location.hostname;
  const tld = state.gsConfig.defaultWebTLD;

  /* istanbul ignore else */
  if (hostname && !hostname.match(WEB_SUB_DOMAIN_REG_EXP)) {
    ret.tld = tld;
  }
  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
}

function connectSocialAccounts(formData) {
  return (dispatch, getState) => {
    let fbCredentials;
    const state = getState();
    /* istanbul ignore else */
    if (__CLIENT__ && window.sessionStorage) {
      fbCredentials = sessionStorage.getItem('session_fbCredentials') || '{}';
      fbCredentials = JSON.parse(fbCredentials);
    }
    const providerToken = (fbCredentials || /* istanbul ignore next */ {}).accessToken;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    };
    Object.assign(headers, _getRequestHeaders(state));

    return post({
      host: process.env.REACT_APP_API_HOST,
      path: '/social/connect/social/account',
      headers,
      timeout: TIME.MILLIS_OF_SECOND * TIME.SECONDS_OF_MIN,
      body: qs.stringify({...formData, providerToken}),
    });
  };
}

function autoLogin(defaultLoginType) {
  return async (dispatch, getState, {cookies}) => {
    let sessionId;
    let userGuid;
    let primaryContact;
    const state = getState();

    try {
      sessionId = await sessionValidate({cookies, state});
      await tokenInit({sessionId, cookies, state});
      userGuid = cookies.get('session_userGUID');
      primaryContact = await fetchUserData({cookies, userGuid, state});

      /* istanbul ignore else */
      if (primaryContact) {
        dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType: LOGIN_TYPE.LOGIN_SUCCESS});
        dispatch({type: LOGIN_TOPIC.SET_PRIMARY_CONTACT, primaryContact});
      }
    } catch (e) {
      console.log('-- autoLogin error --', e); // eslint-disable-line
      dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType: defaultLoginType});
      dispatch({type: LOGIN_TOPIC.SET_PRIMARY_CONTACT, primaryContact: null});
    }
  };
}

async function sessionValidate({cookies, state}) {
  let sessionRes;
  let session_id;

  try {
    sessionRes = await post({
      host: process.env.REACT_APP_API_HOST,
      path: '/login/session/validate',
      json: true,
      headers: _getRequestHeaders(state),
    });

    session_id = sessionRes.session && sessionRes.session.session_id;
    /* istanbul ignore else */
    if (session_id) {
      setCookiesData(cookies, 'session_sessionId', session_id);
    }

    return session_id;
  } catch (e) {
    console.log('-- session validate error --', e); // eslint-disable-line
    /* istanbul ignore else */
    if (_hasValidError(e)) {
      clearSession(cookies);
    }

    throw e; // Re-throw the error when session validate fail, so the consumer could still caputure the associated error
  }
}

function signout() {
  return async (dispatch, getState, {cookies}) => {
    try {
      await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/iam/logout',
      });
      clearSession(cookies);
      resetLoginType()(dispatch);
    } catch (e) {
      console.error(e); //eslint-disable-line
      throw e;
    }
  };
}

// TODO try to deprecate this
function signIn(userInputdata) {
  return async (dispatch, getState, {cookies}) => {
    let userGuid;
    let primaryContact;
    const state = getState();

    dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.STARTED, error: null});

    try {
      await login({userInputdata, cookies, state, dispatch});
      userGuid = cookies.get('session_userGUID');
      primaryContact = await fetchUserData({cookies, userGuid, state, dispatch});

      /* istanbul ignore else */
      if (primaryContact) {
        dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType: LOGIN_TYPE.LOGIN_SUCCESS});
        dispatch({type: LOGIN_TOPIC.SET_PRIMARY_CONTACT, primaryContact});
      }
    } catch (e) {
      console.log('-- error in signIn --', e); // eslint-disable-line
    }
  };
}

// TODO try to deprecate this
async function login({userInputdata, cookies, state, dispatch, breakUADeadLoop = 1}) {
  let signInRes;
  let userGuid;
  let csrfToken;
  let sessionId;
  try {
    signInRes = await post({
      host: process.env.REACT_APP_API_HOST,
      path: '/login/signin',
      json: true,
      headers: _getRequestHeaders(state),
      body: userInputdata,
    });
    userGuid = signInRes.guid;
    /* eslint-disable prefer-destructuring */
    csrfToken = signInRes.csrfToken;
    sessionId = signInRes.sessionId;
    /* eslint-disable prefer-destructuring */
    setCookiesData(cookies, 'session_sessionId', sessionId);

    dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.SIGNIN, error: null});

    /* istanbul ignore else */
    if (userGuid) {
      setCookiesData(cookies, 'session_userGUID', userGuid);
      setCookiesData(cookies, 'track_session_userGUID', userGuid);
      setCookiesData(cookies, 'session_uAuthenticated', 1);
    }

    /* istanbul ignore else */
    if (csrfToken) {
      setCookiesData(cookies, 'session_CSRFtoken', csrfToken);
    }

    return sessionId;
  } catch (e) {
    /*
     * If and only if user agreement missing error happens, then will internal handle such kind of error
     * otherwise immediately throw the error
     */
    let error = e;
    let shouldDispatchError = true;
    /* istanbul ignore else */
    if (e.status === HTTP_CODES.UNAUTHORIZED) {
      // TODO switch to 2fa here directly?
      const errorJson = e.body.error;

      /* istanbul ignore else */
      if (errorJson) {
        // For both two-fa and user-agreement missing 401 error, the error response will both contains the "user_guid" and "csrf_token" fields;
        userGuid = errorJson.user_guid;
        csrfToken = errorJson.csrf_token;
        setCookiesData(cookies, 'session_userGUID', userGuid);
        setCookiesData(cookies, 'session_CSRFtoken', csrfToken);
      }
      /* istanbul ignore else */
      if (
        errorJson.code === 'com.stubhub.domain.security.iam.services.exception.UserAgreementMissingError' &&
        breakUADeadLoop
      ) {
        try {
          const payload = buildUserAgreementPayload(state);
          breakUADeadLoop = 0;
          await updateUserAgreement({userGuid, state, payload});
          await login({userInputdata, cookies, state, breakUADeadLoop});
          userGuid = cookies.get('session_userGUID');
          const primaryContact = await fetchUserData({cookies, userGuid, state});

          shouldDispatchError = false;
          /* istanbul ignore else */
          if (primaryContact) {
            dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType: LOGIN_TYPE.LOGIN_SUCCESS});
            dispatch({type: LOGIN_TOPIC.SET_PRIMARY_CONTACT, primaryContact});
          }
        } catch (uaError) {
          console.log('--- user agreement error ---', e); // eslint-disable-line
          error = uaError;
        }
      }
    }

    shouldDispatchError && dispatch && dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.SIGNIN, error});
    throw error; // Re-throw the error when login fail, so the consumer could still caputure the associated error
  }
}

// TODO try to deprecate this
function buildUserAgreementPayload(state) {
  const countryDestinationMapping = {
    GB: 'UK',
  };
  const {currentLocale} = state.gsConfig;
  const country = currentLocale && currentLocale.split('-')[1].toUpperCase();
  const userDestination = countryDestinationMapping[country] || country;
  const payload = {
    customer: {
      acceptedAgreement: {
        destination: userDestination,
      },
    },
  };

  return payload;
}

// TODO try to deprecate this
async function tokenInit({sessionId, cookies, state, tokenInitSuccess, tokenInitFail, dispatch}) {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    };

    Object.assign(headers, _getRequestHeaders(state));

    /*
     * The reason why can not pass the `json: true` to the post method here is that token/init API return 204 - No content as the success
     * while that will throw JSON parse error then it try to parse a empty response
     */
    await post({
      host: process.env.REACT_APP_API_HOST,
      path: '/login/session/token/init',
      headers,
      body: `si=${sessionId}`,
    });

    dispatch && dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.TOKEN_INIT, error: null});
    setCookiesData(cookies, 'session_login_type', 'stubhub');
    setCookiesData(cookies, 'session_loginStatus', true);
    tokenInitSuccess && tokenInitSuccess();
  } catch (e) {
    console.log('--- token init', e); // eslint-disable-line
    dispatch && dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.TOKEN_INIT, error: e});
    setCookiesData(cookies, 'session_loginStatus', false);
    tokenInitFail && tokenInitFail();
    throw e; // Re-throw the error when token init fail, so the consumer could still caputure the associated error
  }
}

// TODO try to deprecate this
async function fetchUserData({cookies, userGuid, state, dispatch}) {
  let userData;
  let contacts;
  let primaryContact;

  try {
    userData = await get({
      host: process.env.REACT_APP_API_HOST,
      path: `/login/user/customers/v1/${userGuid}/contactsV2`,
      json: true,
      headers: _getRequestHeaders(state),
    });

    contacts = (userData.contactsV2 && userData.contactsV2.contactV2) || /* istanbul ignore next */ [];
    primaryContact = contacts.find((obj) => obj.defaultInd === 'Y') || /* istanbul ignore next */ {};

    /* istanbul ignore else */
    if (primaryContact && primaryContact.contactGuid) {
      setCookiesData(cookies, 'session_contactGUID', primaryContact.contactGuid);
      dispatch && dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.FETCH_USER_DATA, error: null});
    }

    return primaryContact;
  } catch (e) {
    dispatch && dispatch({type: SIGNIN_TOPIC.SET_SIGNIN_FLOW, flow: SIGNIN_FLOW.FETCH_USER_DATA, error: e});
    throw e; // Re-throw the error when fetch user data fail, so the consumer could still caputure the associated error
  }
}

export {LOGIN_TOPIC, LOGIN_TYPE, SIGNIN_TOPIC, SIGNIN_FLOW, sessionValidate};
export default controller;
