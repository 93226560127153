import {createSelector} from 'reselect';

import {Controller} from '@stubhub/react-store-provider';

import {processPasswordLoginWith2FA, switchLoginTypeAction} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

const SIGNIN_START = 'SIGNIN_START';
const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
const SIGNIN_FAIL = 'SIGNIN_FAIL';
const SIGNIN_RESET = 'SIGNIN_RESET';

const viewportSelector = createSelector(
  (state = {}) => state.viewport,
  (viewport = {}) => viewport.screenSize
);

const NAMESPACE = 'login_signin';

const loginWithPassword = ({username, password, staySignIn, source}, funcAfterSuccess, funcAfterFail) => {
  return async (dispatch, getState, {cookies}) => {
    dispatch(start());
    await processPasswordLoginWith2FA(
      {username, password, staySignIn, source},
      async (dispatch) => {
        dispatch(success());
        dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
        await funcAfterSuccess();
      },
      async (dispatch, error) => {
        dispatch(fail(error));
        await funcAfterFail(error);
      }
    )(dispatch, getState, {cookies});
  };
};

function start() {
  return {type: SIGNIN_START, submitting: true};
}
function success() {
  return {type: SIGNIN_SUCCESS, submitting: true};
}
function fail(error) {
  return {type: SIGNIN_FAIL, error, submitting: false};
}

function reset() {
  return (dispatch) => {
    dispatch({type: SIGNIN_RESET});
  };
}

const controller = new Controller({
  namespace: NAMESPACE,
  actionCreators: {
    reset,
    loginWithPassword,
  },
  reducers: [SIGNIN_START, SIGNIN_SUCCESS, SIGNIN_FAIL],
  mapStateToProps(state) {
    const {submitting} = this.getLocalState(state);
    const {lang} = state;

    return {
      lang,
      submitting,
      isMobile: viewportSelector(state) === 'small',
    };
  },
});

controller.addResetReducer([SIGNIN_RESET]);

export default controller;
