// Define Global Layout for your application
import React from 'react';
import PropTypes from 'prop-types';
import {GoogleOAuthProvider} from '@react-oauth/google';

import CookieModal from '@stubhub/react-cookie-modal';
import FeatureToggle from '@stubhub/react-feature-toggle';
import VirtualAssistantLazyLoad from '@stubhub/react-lazy-virtual-assistant';

import TrackListener from './tracking/track-listener';
import FEATURE_FLAGS from './util/featureFlags';

import './layout.scss';

export default function Layout(props) {
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const projectID = process.env.REACT_APP_VOICE_FLOW_PROJECT_ID;
  const {defaultLocale: locale} = props;

  return (
    <>
      <TrackListener />
      <GoogleOAuthProvider clientId={google_client_id}>{props.children}</GoogleOAuthProvider>
      <FeatureToggle name={FEATURE_FLAGS.common.cookiesModal} key="cookiesModal">
        <CookieModal />
      </FeatureToggle>
      <FeatureToggle name={FEATURE_FLAGS.login.voiceFlow}>
        <VirtualAssistantLazyLoad type="VOICE_FLOW" projectID={projectID} locale={locale} />
      </FeatureToggle>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  defaultLocale: PropTypes.string,
};

/*
 * Uncomment below and remove above sample Layout to use Unified Layout for stubhub ecomm site
 * Also remember to install @stubhub/react-unified-app to dependencies
 * export {UnifiedLayoutWithRouter as default} from '@stubhub/react-unified-app';
 */
