/*
 * @stubhub/speedy-react-renderer should provide an isomorphic implementation for its `start` method
 * for client, it will bootstrap the client side app
 * for server, it should return a middleware to render app on server side
 */
import {start} from '@stubhub/speedy-react-renderer';

import Layout from './layout';
import getRoutes from './routes';

export function render() {
  return start({
    layout: Layout,
    getRoutes,
  });
}
