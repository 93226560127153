import {defineMessages} from 'react-intl';

const messages = defineMessages({
  'common.title': {
    id: 'CentralLogin.twofa.common.title',
    defaultMessage: 'Verify your account',
  },

  validateaccount: {
    id: 'CentralLogin.twofa.common.validateaccount',
    defaultMessage: 'For security reasons, we must verify your account.',
  },

  'sendcode.reminder': {
    id: 'CentralLogin.twofa.sendcode.reminder',
    defaultMessage: 'We\'ll send an email with the code to:',
  },
  receivecode: {
    id: 'CentralLogin.twofa.howto.receivecode',
    defaultMessage: 'Please choose how you’d like to receive the code:',
  },
  'sendcode.button.label': {
    id: 'CentralLogin.twofa.sendcode.button.label',
    defaultMessage: 'Send code',
  },
  'verify.button.label': {
    id: 'CentralLogin.twofa.verify.button.label',
    defaultMessage: 'Verify',
  },
  'sendcode.text': {
    id: 'CentralLogin.twofa.sendcode.text',
    defaultMessage: 'Send text to',
  },
  'sendcode.email': {
    id: 'CentralLogin.twofa.sendcode.email',
    defaultMessage: 'Send email to',
  },
  'sendcode.havecode': {
    id: 'CentralLogin.twofa.sendcode.havecode',
    defaultMessage: 'I already have a code',
  },
  'verify.code': {
    id: 'CentralLogin.twofa.verify.code',
    defaultMessage: 'Code',
  },

  'sendcode.disclaimer': {
    id: 'CentralLogin.twofa.sendcode.disclaimer',
    defaultMessage: 'Standard text messaging rates may apply.',
  },
  'error.sendcode.generic': {
    id: 'CentralLogin.twofa.error.sendcode.generic',
    defaultMessage: 'There was a problem sending the code. Please request a new one.',
  },
  'error.sendcode.maxlimitexceeded': {
    id: 'CentralLogin.twofa.error.sendcode.maxlimitexceeded',
    defaultMessage:
      'There appears to be an issue sending out the code. For your security, please contact us and we\'ll sort it out.',
  },
  'error.verifycode.generic': {
    id: 'CentralLogin.twofa.error.verifycode.generic',
    defaultMessage: 'There was a problem with the code. Give it another shot.',
  },
  'error.verifycode.invalidcode': {
    id: 'CentralLogin.twofa.error.verifycode.invalidcode',
    defaultMessage: 'The verification code you have entered is incorrect. Please try again.',
  },
  'error.verifycode.expiredcode': {
    id: 'CentralLogin.twofa.error.verifycode.expiredcode',
    defaultMessage: 'The verification code you have entered has expired. Please request a new one.',
  },
  'error.verifycode.maxlimitexceeded': {
    id: 'CentralLogin.twofa.error.verifycode.maxlimitexceeded',
    defaultMessage: 'There appears to be an issue. For your security, please contact us and we\'ll sort it out.',
  },
  'error.verifycode.callcustservice': {
    id: 'CentralLogin.twofa.error.verifycode.callcustservice',
    defaultMessage: 'For your security, please contact us and we\'ll sort it out.',
  },
  'error.usercontactmissing': {
    id: 'CentralLogin.twofa.error.usercontactmissing',
    defaultMessage: 'We don\'t have a phone number or email address for you.',
  },
  'contactcustservice.message': {
    id: 'CentralLogin.twofa.contactcustservice.message',
    defaultMessage: 'To get a verification code for your account, please contact us.',
  },

  'entercode.phonenumber': {
    id: 'CentralLogin.twofa.entercode.phonenumber',
    defaultMessage: 'Enter the code sent to phone {phone}. This code will expire in 30 minutes.',
  },
  'entercode.email': {
    id: 'CentralLogin.twofa.entercode.email',
    defaultMessage: 'Enter the code sent to email {email}. This code will expire in 30 minutes.',
  },
  sendnewcode: {
    id: 'CentralLogin.twofa.sendnewcode',
    defaultMessage: 'Resend code',
  },
  cancel: {
    id: 'CentralLogin.twofa.cancel',
    defaultMessage: 'Cancel',
  },
  instruction: {
    id: 'CentralLogin.forgotPassword.instruction',
    defaultMessage: 'Still having trouble?',
  },
  contact: {
    id: 'CentralLogin.forgotPassword.contact.support',
    defaultMessage: 'Contact support',
  },
});

export default messages;
