import {publish} from '@stubhub/pubsub-utils';
import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';
import uuid from '@stubhub/visitor-id';

import {SIGNUP_EVENTS} from '../../apps/events-enum';
import {isMarketingOptInFFEnabledSelector} from '../../store/gsConfig/selectors';
import {switchLoginTypeAction, postLoginSuccess} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

const REGISTER_START = 'REGISTER_START';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAILURE = 'REGISTER_FAILURE';
const REGISTER_RESET = 'REGISTER_RESET';

const massageData = ({
  accertifyRefId,
  first_name,
  last_name,
  email,
  password,
  country,
  userDestination,
  marketing_email_opt_in,
  removeDefaultPhone,
  phoneNumber = '0000000000',
  countryCallingCode,
}) => {
  const newUserData = {
    accertifyRefId,
    emailAddress: email,
    password,
    defaultContact: {
      name: {
        firstName: first_name,
        lastName: last_name,
      },
      address: {
        country,
      },
      phone: {
        phoneNumber,
        countryCallingCode,
      },
    },
    marketingEmailOptIn: marketing_email_opt_in,
    acceptedAgreements: [
      {
        destination: userDestination,
      },
    ],
  };

  /* istanbul ignore else */
  if (removeDefaultPhone && (phoneNumber === '0000000000' || !phoneNumber)) {
    newUserData.action = 'websignup';
    delete newUserData.defaultContact.phone;
  }

  return newUserData;
};

const _getRequestHeaders = (lang) => {
  const ret = {};
  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

export const handleSubmit = (data, afterFail, redirect) => {
  return async (dispatch, getState, {cookies}) => {
    const {lang, login = {}} = getState();
    const {accertifyData = {}} = login;
    const {accertifyRefId = uuid()} = accertifyData;
    const MILLISECONDS_OF_TIMEOUT = 15000;

    dispatch(start());
    try {
      const response = await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/login/signup',
        json: true,
        headers: _getRequestHeaders(lang),
        body: massageData({
          accertifyRefId,
          ...data,
        }),
        timeout: MILLISECONDS_OF_TIMEOUT,
      });

      const {guid, csrfToken, sessionId} = response;
      dispatch(success(response));
      postLoginSuccess(cookies, {guid, sessionId, csrfToken});
      const {
        email: userEmail,
        first_name: userFirstName,
        last_name: userLastName,
        marketing_email_opt_in: marketingEmailOptIn,
      } = data;
      publish(SIGNUP_EVENTS.SUCCESS, {
        redirect,
        method: 'stubhub',
        userEmail,
        userFirstName,
        userLastName,
        marketingEmailOptIn,
      });
      dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
    } catch (error) {
      dispatch(failure(error));
      afterFail?.(error);
    }
  };

  function start() {
    return {type: REGISTER_START, submitting: true};
  }
  function success(response) {
    return {type: REGISTER_SUCCESS, submitting: true, registerPayload: response};
  }
  function failure(errors) {
    return {type: REGISTER_FAILURE, errors, submitting: false};
  }
};

const NAMESPACE = 'login_signup';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const {submitting} = this.getLocalState(state);
    const {lang} = state;

    return {
      submitting,
      lang,
      uncheckMarketingOptIn: isMarketingOptInFFEnabledSelector(state),
    };
  },
  actionCreators: {
    handleSubmit,
    reset,
  },
  reducers: [REGISTER_START, REGISTER_SUCCESS, REGISTER_FAILURE],
});

controller.addResetReducer([REGISTER_RESET]);

function reset() {
  return (dispatch) => {
    dispatch({type: REGISTER_RESET});
  };
}

export default controller;
