import React from 'react';

import ResetPasswordContainer from '../react-login-reset-password';

import RemoveParams from './RemoveParams';

const ReadToken = (props) => {
  return (
    <RemoveParams>
      <ResetPasswordContainer {...props} />
    </RemoveParams>
  );
};

export default ReadToken;
