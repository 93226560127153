import React from 'react';
import cls from 'classnames';

import './login-alert.scss';

const LoginAlert = (props) => {
  return <section className={cls('login-alert__wrapper', props.className)}>{props.children}</section>;
};

export default LoginAlert;
