import React from 'react';
import {PropTypes} from 'prop-types';
import classNames from 'classnames';

import './index.scss';

class UIButton extends React.Component {
  constructor(props) {
    super(props);
    this.getButtonRef = this.getButtonRef.bind(this);
  }

  componentDidUpdate() {
    if (this.props.autoFocus) {
      this.buttonRef?.focus();
    }
  }

  getButtonRef(el) {
    this.buttonRef = el;
  }

  getClassNames(props) {
    const classPrefix = 'UIKIT-UIButton--';
    const {size = 'default', secondary = false, tertiary = false, disabled = false} = this.props;
    const btnFlow =
      classPrefix + (tertiary ? 'Tertiary' : !secondary ? 'Primary' : 'Secondary') + (!disabled ? '' : 'Disabled');
    const btnSize = classPrefix + size;

    return classNames(btnFlow, btnSize, props.className);
  }

  render() {
    const buttonType = this.props.type;

    return (
      <button
        ref={this.getButtonRef}
        onClick={this.props.onClick}
        title={this.props.title}
        // eslint-disable-next-line react/button-has-type
        type={buttonType || 'button'}
        disabled={this.props.disabled}
        className={`UIKIT-UIButton ${this.getClassNames(this.props)}`}
      >
        {this.props.children || this.props.text}
      </button>
    );
  }
}

UIButton.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default UIButton;
