import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import FormattedLink from '@stubhub/react-formatted-link';

import UIButton from '../react-uikit-button';

import './index.scss';

// eslint-disable-next-line
class DecisionSocial extends React.Component {
  static propTypes = {
    goToRegisterSocial: PropTypes.func,
    goToConnectSocial: PropTypes.func,
    goToForgotPassword: PropTypes.func,
  };

  static contextTypes = {
    track: PropTypes.object,
  };

  changeRegisterSocial = () => {
    this.trackingOptions('facebook SH Decision: New Account Click');
    const {goToRegisterSocial} = this.props;
    goToRegisterSocial && goToRegisterSocial();
  };

  changeConnectSocial = () => {
    this.trackingOptions('facebook SH Decision: Link Existing Account Click');
    const {goToConnectSocial} = this.props;
    goToConnectSocial && goToConnectSocial();
  };

  changeForgotPassword = () => {
    this.trackingOptions('facebook SH Decision: Forgot Password Click');
    const {goToForgotPassword} = this.props;
    goToForgotPassword && goToForgotPassword();
  };

  trackingOptions(appInteraction) {
    const {track} = this.context;
    const appInteractionType = 'NE: Login Component';
    const s = __CLIENT__ && window.s;

    if (!s) {
      return;
    }

    const trackingObj = {
      appInteraction: `${appInteractionType}: ${appInteraction}`,
      appInteractionType,
      userStatus: s.eVar69,
      userGUID: s.eVar66,
      socialSiteInteraction: s.eVar25,
      login_type: s.eVar105,
    };

    if (track) {
      track.click(trackingObj.appInteractionType, trackingObj);
    }

    return trackingObj;
  }

  render() {
    return (
      <div className="central-login__decision-social">
        <div className="decision-social__header">
          <FormattedMessage id="CentralLogin.decisionSocial.header" defaultMessage="Have we met?" />
        </div>
        <div className="decision-social__sub-note">
          <div className="decision-social__sub-note__content">
            <FormattedMessage
              id="CentralLogin.decisionSocial.subNote"
              defaultMessage="Do you want to create a new StubHub account or link an existing account to Facebook?"
            />
          </div>
        </div>
        <div className="decision-social__form">
          <UIButton secondary className="decision-social__register-social" onClick={this.changeRegisterSocial}>
            <FormattedMessage id="CentralLogin.decisionSocial.createAccount" defaultMessage="Create new account" />
          </UIButton>
          <UIButton secondary className="decision-social__connect-social" onClick={this.changeConnectSocial}>
            <FormattedMessage id="CentralLogin.decisionSocial.linkAccount" defaultMessage="Link existing account" />
          </UIButton>
        </div>
        <div className="decision-social__link">
          <FormattedLink
            className="decision-social__forgot-password formattedlink_without_href"
            onClick={this.changeForgotPassword}
          >
            <FormattedMessage id="CentralLogin.decisionSocial.forgotPassword" defaultMessage="Forgot your password?" />
          </FormattedLink>
        </div>
      </div>
    );
  }
}

export default DecisionSocial;
