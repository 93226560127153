import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';

import {cacheForceResetDataAction, getDestination, postLoginSuccess, _getRequestHeaders} from '../../util/api';
import {connectSocial} from '../react-login-connect-social/controller';

const TWOFA_FLOW = {
  SEND_CHALLENGE: 'SEND_CHALLENGE',
  VERIFICATION: 'VERIFICATION',
  SUCCESS: 'SUCCESS',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
};
const TWOFA_TOPIC = {
  SET_TWOFA_FLOW: 'SET_TWOFA_FLOW',
  RESET_TWOFA: 'RESET_TWOFA',
};

const ERROR_CODE = {
  SENDCODE_GENERIC: 'error.sendcode.generic',
  SENDCODE_MAXLIMITEDEXCEEDED: 'error.sendcode.maxlimitexceeded',
  VERIFYCODE_GENERIC: 'error.verifycode.generic',
  VERIFYCODE_INVALIDCODE: 'error.verifycode.invalidcode',
  VERIFYCODE_EXPIREDCODE: 'error.verifycode.expiredcode',
  VERIFYCODE_MAXLIMITEDEXCEEDED: 'error.verifycode.maxlimitexceeded',
  VERIFYCODE_CALLCUSTSERVICE: 'error.verifycode.callcustservice',
};

const NAMESPACE = 'twofa';

const MILLISECONDS_OF_TIMEOUT = 30000;

const controller = new Controller({
  namespace: NAMESPACE,
  reducers: Object.values(TWOFA_TOPIC),
  actionCreators: {
    onSuccess,
    setTwoFaFlow,
    sendCode,
    verifyCode,
    reset,
  },
  mapStateToProps(state) {
    const localState = this.getLocalState(state);
    const {twoFaData} = state.login;

    return {
      flow: localState.flow,
      error: localState.error,
      customerContact: twoFaData.customerContact,
      authId: twoFaData.auth_id,
    };
  },
});

controller.addResetReducer(TWOFA_TOPIC.RESET_TWOFA);

function reset() {
  return (dispatch) => {
    dispatch({type: TWOFA_TOPIC.RESET_TWOFA});
  };
}

function onSuccess() {
  return (dispatch) => {
    dispatch({type: TWOFA_TOPIC.SET_TWOFA_FLOW, flow: TWOFA_FLOW.SUCCESS});
  };
}

function setTwoFaFlow(flow) {
  return (dispatch) => {
    dispatch({type: TWOFA_TOPIC.SET_TWOFA_FLOW, flow});
  };
}

/**
 * Fetch the error code from send challenge API error: use JSON.parse here since the e.body is a JSON stringified string
 * Feel free to handle more error code here if there is a necessary to show different error message according to different error code
 * @param {Object} e - API error
 * @param {function} onExpired - A function to be executed when error is "EXPIRED"
 * @returns {string} - an error code
 */
function fetchSendCodeError(e, onExpired) {
  let errorCode = ERROR_CODE.SENDCODE_GENERIC;
  try {
    const {error} = JSON.parse(e.body);
    if (error === 'EXCEED_LIMIT') {
      errorCode = ERROR_CODE.SENDCODE_MAXLIMITEDEXCEEDED;
    } else if (error === 'EXPIRED') {
      onExpired();
    }
  } catch (e) {
    errorCode = ERROR_CODE.SENDCODE_GENERIC;
  }

  return errorCode;
}

function sendCode(authId, medium, data, trackingFunc, updateState, onExpired) {
  return async (dispatch, getState) => {
    let error;

    updateState({
      loading: true,
      error: null,
    });

    try {
      await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/mfa/sendchallenge',
        body: JSON.stringify({
          auth_id: authId,
          medium,
          data,
        }),
        headers: {
          ..._getRequestHeaders(getState()),
          'Content-type': 'application/json',
        },
        timeout: MILLISECONDS_OF_TIMEOUT,
      });

      trackingFunc && trackingFunc('sendCode success');
      dispatch({type: TWOFA_TOPIC.SET_TWOFA_FLOW, flow: TWOFA_FLOW.VERIFICATION});
    } catch (e) {
      error = fetchSendCodeError(e, onExpired);
      updateState({
        loading: false,
        error,
      });
    }
  };
}

/**
 * Fetch the error code from verify code API error
 * Feel free to handle more error code here if there is a necessary to show different error message according to different error code
 * @param {Object} e - API error
 * @param {function} onExpired - A function to be executed when error is "EXPIRED"
 * @returns {string} - an error code
 */
function fetchVerifyCodeError(e, onExpired) {
  let error = ERROR_CODE.VERIFYCODE_GENERIC;
  if (e && e.body && e.body.error) {
    switch (e.body.error) {
      case 'EXPIRED':
        error = ERROR_CODE.VERIFYCODE_EXPIREDCODE;
        onExpired();
        break;
      case 'EXCEED_LIMIT':
        error = ERROR_CODE.VERIFYCODE_MAXLIMITEDEXCEEDED;
        break;
      case 'USER_NOT_AUTHORIZED':
        error = ERROR_CODE.VERIFYCODE_CALLCUSTSERVICE;
        break;
      case 'INVALID_CODE':
        error = ERROR_CODE.VERIFYCODE_INVALIDCODE;
        break;
    }
  }

  return error;
}

function verifyCode({authId, verifyCode}, trackingFunc, updateState, onExpired, onCompromisedAccount) {
  return async (dispatch, getState, {cookies}) => {
    let error;
    const state = getState();
    const {login, lang} = state;
    const {twoFaData = {}} = login;
    const {bindData} = twoFaData;
    const destination = getDestination(lang);

    updateState({
      loading: true,
      error: null,
    });

    try {
      const resBody = await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/mfa/verifycode',
        body: {
          twofaData: {
            auth_id: authId,
            verifyCode,
          },
          uaData: {
            customer: {
              acceptedAgreement: {destination},
            },
          },
        },
        json: true,
        headers: _getRequestHeaders(state),
        timeout: MILLISECONDS_OF_TIMEOUT,
      });

      const {csrfToken, guid, sessionId, isCompromisedCredential} = resBody;
      const cookiesToBeSet = {csrfToken, guid, sessionId};

      trackingFunc && trackingFunc('verifyCode success');
      postLoginSuccess(cookies, cookiesToBeSet);

      try {
        if (bindData) {
          await connectSocial({...bindData, getState});
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error: ---', e);
      }

      // Save for formal release

      if (isCompromisedCredential) {
        const {otpId, otpCode} = resBody;
        const forceResetData = {guid, otpId, otpCode};

        dispatch(cacheForceResetDataAction(forceResetData));
        onCompromisedAccount();
      } else {
        dispatch({type: TWOFA_TOPIC.SET_TWOFA_FLOW, flow: TWOFA_FLOW.SUCCESS});
      }

      // Dispatch({type: TWOFA_TOPIC.SET_TWOFA_FLOW, flow: TWOFA_FLOW.SUCCESS});
    } catch (e) {
      error = fetchVerifyCodeError(e, onExpired);

      updateState({
        loading: false,
        error,
      });
    }
  };
}

export {TWOFA_FLOW, ERROR_CODE, NAMESPACE};
export default controller;
