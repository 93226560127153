import {validations} from '../react-uikit-validator';

function validate(values) {
  return Object.keys(values).reduce((errors, field) => {
    const value = values[field];

    return validators[field]
      ? {
          ...errors,
          [field]: validators[field](value),
        }
      : {
          ...errors,
        };
  }, {});
}

const PHONE_REGEX = /^[1-9]{1}[0-9]{9}$/;

/**
 * Check the password strength
 * The strength `score` rule is defined as below: 0 - weak, 1 - good, 2 - strong, 3 - very strong
 * 1. length check [8-20], if not meet, then score - 0
 * 2. complexity check - [digit, lowerCase, upperCase, symbol]
 *  a. password contains one or zero types of above, then score - 0
 *  b. password contains at least 2 types of above, then score - 1, 2, 3
 * @param {string} [value=''] - the password value
 * @returns {Object} - an object contains score and validators - with rule passed / failed
 */
const password_validator = (value = '') => {
  const str = value.length;
  const minLength = 8;
  const maxLength = 20;
  const length = str >= minLength && str <= maxLength;
  const digit = /\d+/g.test(value);
  const lowerCase = /[a-z]+/g.test(value);
  const upperCase = /[A-Z]+/g.test(value);
  const symbol = /[ !@#$%^&*)(+=._-]+/g.test(value);

  const validators = {digit, lowerCase, upperCase, symbol};
  const count = Object.values(validators).filter((val) => val).length;
  let score = 0;

  if (!length) {
    score = 0;
  } else {
    switch (count) {
      case 0:
      case 1:
        score = 0;
        break;
      case 2:
        score = 1;
        break;
      case 3:
        score = 2;
        break;
      case 4:
        score = 3;
        break;
    }
  }

  validators.length = length;

  return {
    score,
    validators,
  };
};

const validators = {
  email: (value) => !validations.isEmail(value),
  password: (value) => !password_validator(value).score,
  phone: (value) => !value && PHONE_REGEX.test(value),
  last_name: (value) => validations.isEmpty(value),
  first_name: (value) => validations.isEmpty(value),
  country: (value) => {
    return !value;
  },
};

export {password_validator};
export default validate;
