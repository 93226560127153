import {createSelector} from 'reselect';

import FEATURE_FLAGS from '../../util/featureFlags';
import {featureFlagsSelector} from '../gsConfig/selectors';

const isAdvisoryCurrencyFFEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.advisory.enabled]
);
const isAdvisoryCurrencyOptOutFFEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.advisory.enableOptOut]
);
const currencyExchangeSelector = (state) => state.currencyExchange || {};
const advisoryCurrencySelector = createSelector(
  isAdvisoryCurrencyFFEnabledSelector,
  currencyExchangeSelector,
  (isAdvisoryEnabled, currencyExchange = {}) =>
    isAdvisoryEnabled && currencyExchange.selectedCurrency && currencyExchange.selectedCurrency !== 'null'
      ? currencyExchange.selectedCurrency
      : null
);

export {isAdvisoryCurrencyFFEnabledSelector, isAdvisoryCurrencyOptOutFFEnabledSelector, advisoryCurrencySelector};
