import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

import {TIME} from '@stubhub/general-utils';
import FormattedLink from '@stubhub/react-formatted-link';
import ModalDialog from '@stubhub/react-modal-dialog';
import {connect} from '@stubhub/react-store-provider';

import {FooterLinks, getRedirectUrl} from '../../util/helper';
import PinCodeInput from '../react-ui-components/code-input';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import UIButton from '../react-uikit-button';
import RadioButton from '../react-uikit-radio-button';

import controller, {ERROR_CODE, TWOFA_FLOW} from './controller';
import messages from './messages';

import './index.scss';

const MEDIUM = {
  EMAIL: 'Email',
  SMS: 'SMS',
};
const MILLISECONDS_OF_TIMEOUT = 30;
const customerServiceConfig = require('./customer-service-config.json');
// Const FooterLinks = require('@stubhub/react-footer-new/lib/footer-links-data').FooterLinks;

class TwoFA extends Component {
  static propTypes = {
    authId: PropTypes.string,
    customerContact: PropTypes.object,
    flow: PropTypes.string,
    onTwoFAClose: PropTypes.func,
  };

  static defaultProps = {
    customerContact: {},
    authId: '',
    flow: TWOFA_FLOW.SEND_CHALLENGE,
    onTwoFAClose: () => {
      // Do nothing.
    },
  };

  static contextTypes = {
    cookies: PropTypes.object,
    globalRegistry: PropTypes.object,
    track: PropTypes.object,
  };

  trackingOptions(appInteraction) {
    const {track} = this.context;
    const appInteractionType = 'NE: 2FA Component';
    const s = __CLIENT__ && window.s;
    if (!s) {
      return;
    }

    const trackingObj = {
      appInteraction: `${appInteractionType}: ${appInteraction}`,
      appInteractionType,
      userStatus: s.eVar69,
      userGUID: s.eVar66,
      socialSiteInteraction: s.eVar25,
    };

    if (track) {
      track.click(trackingObj.appInteractionType, trackingObj);
    }

    return trackingObj;
  }

  constructor(props, ctx) {
    super(props, ctx);

    // For any domains other than US, remove phone option.
    const storeId = this.context.globalRegistry.getStoreId();
    const customerServiceNumber = customerServiceConfig[`customerservice.contact.${storeId}`]
      ? customerServiceConfig[`customerservice.contact.${storeId}`]
      : customerServiceConfig['customerservice.contact.1'];
    this.state = {
      customerServiceNumber,
      storeId,
    };
  }

  populateSelectedOption = (data) => {
    this.selectedOption = {...data};
  };

  fetchErrorMsg = (error) => {
    let msg;
    const {intl} = this.props;
    const {customerServiceNumber} = this.state;
    const callServiceErrorCodeList = [
      ERROR_CODE.SENDCODE_MAXLIMITEDEXCEEDED,
      ERROR_CODE.VERIFYCODE_MAXLIMITEDEXCEEDED,
      ERROR_CODE.VERIFYCODE_CALLCUSTSERVICE,
    ];

    if (error) {
      msg = intl.formatMessage(messages[error]);
    }

    if (callServiceErrorCodeList.indexOf(error) !== -1) {
      msg = msg + customerServiceNumber;
    }

    return msg;
  };

  componentWillUnmount() {
    // Reset TwoFA data on "twofa" namespace
    this.props.reset();
  }

  onSuccess = () => {
    this.props.onSuccess();
    this.props.onTwoFAClose();
  };

  render() {
    const {customerContact} = this.props;
    const emailOption = customerContact.emailAddress;
    const phoneOption = customerContact.phone || [];
    // Strip out 0000 number
    let cleanPhoneOption = phoneOption.filter((item) => item.masked !== '******0000');
    if (cleanPhoneOption.length > 2) {
      cleanPhoneOption = cleanPhoneOption.slice(0, 2);
    }
    if (this.state.storeId > 1) {
      cleanPhoneOption = [];
    }
    const emailOnly = emailOption && cleanPhoneOption.length === 0;

    /**
     * This SHOULD never happen according to latest communication with T&N team
     * isEmptyCustomerContact should be always FALSE in any case
     */
    const isEmptyCustomerContact = !emailOption && cleanPhoneOption.length === 0;
    const data = {
      emailOnly,
      emailOption,
      phoneOption: cleanPhoneOption,
      customerServiceNumber: this.state.customerServiceNumber,
    };

    const {intl, location} = this.props;
    let {flow} = this.props;
    let props = {};
    let Child = null;

    if (isEmptyCustomerContact) {
      flow = TWOFA_FLOW.CUSTOMER_SERVICE;
    }

    switch (flow) {
      case TWOFA_FLOW.CUSTOMER_SERVICE:
        Child = CustomerService;
        props = {
          ...this.props,
          ...data,
          onSuccess: this.onSuccess,
        };
        break;
      case TWOFA_FLOW.SEND_CHALLENGE:
        Child = SendChallenge;
        props = {
          ...this.props,
          ...data,
          trackingOptions: this.trackingOptions.bind(this),
          populateSelectedOption: this.populateSelectedOption,
          fetchErrorMsg: this.fetchErrorMsg,
        };
        break;
      case TWOFA_FLOW.VERIFICATION:
        Child = Verification;
        props = {
          ...this.props,
          ...data,
          ...this.selectedOption,
          trackingOptions: this.trackingOptions.bind(this),
          fetchErrorMsg: this.fetchErrorMsg,
        };
        break;
      case TWOFA_FLOW.SUCCESS:
      default:
        Child = null;
        break;
    }

    if (!Child && __CLIENT__) {
      window.location.href = getRedirectUrl(location);

      return null;
    }

    return (
      <ModalDialog closable={false} responsive={false} className="central-login__container">
        <section className="central-login-twofa__container">
          <section className="twofa_content__container twofa__panel">
            <LoginHeader title={intl.formatMessage(messages['common.title'])}>
              {intl.formatMessage(messages['common.title'])}
            </LoginHeader>
            <Child {...props} />
          </section>
        </section>
      </ModalDialog>
    );
  }
}

class SendChallenge extends Component {
  constructor(props) {
    super(props);

    // Once the this.props.emailOnly is true, then it indicate there is no phone option
    this.state = {
      medium: this.props.emailOnly ? MEDIUM.EMAIL : null,
      encrypted: this.props.emailOnly ? this.props.emailOption.encrypted : null,
      masked: this.props.emailOnly ? this.props.emailOption.masked : null,

      loading: false, // Ajax loading or not
      error: this.props.error, // This is used to indicate whehter there is API error or not
    };
  }

  updateState = (data) => {
    this.setState(data);
  };

  onCodeOptionClick = (props) => {
    const {medium, encrypted, masked} = props;
    const {loading} = this.state;

    if (loading) {
      return false;
    }

    this.setState({
      medium,
      encrypted,
      masked,
      error: null,
    });
  };

  sendCode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {medium, encrypted, masked} = this.state;
    const {populateSelectedOption, sendCode, authId, trackingOptions, onTwoFAExpired} = this.props;

    populateSelectedOption({medium, encrypted, masked});
    sendCode(authId, medium, encrypted, trackingOptions, this.updateState, onTwoFAExpired);
  };

  render() {
    const {emailOnly, emailOption, phoneOption, intl, fetchErrorMsg} = this.props;
    const title = emailOnly
      ? intl.formatMessage(messages['sendcode.reminder'])
      : intl.formatMessage(messages.receivecode);

    const {encrypted, loading, error} = this.state;
    const errorMsg = fetchErrorMsg(error);

    const isErrorHidden = loading === true && error === null;
    const btnDisabled = loading || (!emailOnly && !encrypted);
    const isErrorShown = !!errorMsg && !isErrorHidden;

    return (
      <form className="twofa_form__wrapper send_challenge__form" onSubmit={this.sendCode}>
        {/* istanbul ignore next */ isErrorShown && <LoginAlert>{errorMsg}</LoginAlert>}
        <div className="info-title">{intl.formatMessage(messages.validateaccount)}</div>

        <div className="info-sub-title">
          {title}
          {emailOnly ? <div className="email-mask">{emailOption.masked}</div> : null}
        </div>

        {!emailOnly && (
          <div className={clx('option-list', loading && 'fade')}>
            {phoneOption &&
              phoneOption.map((item, idx) => {
                return (
                  <RadioButton
                    key={idx}
                    medium={MEDIUM.SMS}
                    encrypted={item.encrypted}
                    masked={item.masked}
                    name="2FACodeOption"
                    handleChange={this.onCodeOptionClick}
                  >
                    {intl.formatMessage(messages['sendcode.text'])}&nbsp;
                    <strong>{item.masked}</strong>
                  </RadioButton>
                );
              })}
            {emailOption && (
              <RadioButton
                medium={MEDIUM.EMAIL}
                encrypted={emailOption.encrypted}
                masked={emailOption.masked}
                name="2FACodeOption"
                handleChange={this.onCodeOptionClick}
              >
                {intl.formatMessage(messages['sendcode.email'])}&nbsp;
                <strong>{emailOption.masked}</strong>
              </RadioButton>
            )}
          </div>
        )}

        <UIButton disabled={btnDisabled} type="submit">
          {intl.formatMessage(messages['sendcode.button.label'])}
        </UIButton>
        <p className="disclaimer-text">{intl.formatMessage(messages['sendcode.disclaimer'])}</p>
      </form>
    );
  }
}

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',

      loading: false,
      error: null,
      counter: MILLISECONDS_OF_TIMEOUT,
    };
  }

  static contextTypes = {
    globalRegistry: PropTypes.object,
  };

  updateState = (data) => {
    this.setState(data);
  };

  onChange = (code) => {
    this.setState({
      code,
    });
  };

  showSendCodeView = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.setTwoFaFlow(TWOFA_FLOW.SEND_CHALLENGE);
  };

  verifyCode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const requestData = {
      authId: this.props.authId,
      verifyCode: this.state.code,
    };

    const {verifyCode, trackingOptions, goToForceChangePassword, onTwoFAExpired} = this.props;
    verifyCode(requestData, trackingOptions, this.updateState, onTwoFAExpired, goToForceChangePassword);
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      const {counter} = this.state;
      counter > 0 &&
        this.setState({
          counter: counter - 1,
        });
    }, TIME.MILLIS_OF_SECOND);
  }

  getResendButton = () => {
    const {counter} = this.state;
    const {intl} = this.props;

    if (counter > 0) {
      return (
        <UIButton className="resend-code__btn" type="button" secondary disabled onClick={this.showSendCodeView}>
          {intl.formatMessage(messages.sendnewcode)}&nbsp;
          <span className="counter">({counter}S)</span>
        </UIButton>
      );
    }
    clearInterval(this.timer);

    return (
      <UIButton className="resend-code__btn" type="button" secondary onClick={this.showSendCodeView}>
        {intl.formatMessage(messages.sendnewcode)}
      </UIButton>
    );
  };

  render() {
    const {medium, masked, intl, fetchErrorMsg} = this.props;
    const {code, loading, error} = this.state;
    const errorMsg = fetchErrorMsg(error);
    const submitBtnDisabled = loading || code.length === 0;
    let title = '';
    const {globalRegistry} = this.context;
    const links = FooterLinks(globalRegistry.gsConfig.shstoreId, globalRegistry.gsConfig.currentLocale);

    switch (medium) {
      case MEDIUM.SMS:
        title = <FormattedMessage {...messages['entercode.phonenumber']} values={{phone: <b>{masked}</b>}} />;
        break;
      case MEDIUM.EMAIL:
        title = <FormattedMessage {...messages['entercode.email']} values={{email: <b>{masked}</b>}} />;
        break;
    }

    return (
      <form className="twofa_form__wrapper verification__form" onSubmit={this.verifyCode}>
        {!!errorMsg && <LoginAlert>{errorMsg}</LoginAlert>}
        <div className="info-title">{title}</div>
        <section className={clx('verify-code__container', loading && 'fade')}>
          <PinCodeInput type="number" fields={6} value={code} name="code" onChange={this.onChange} />
          <UIButton disabled={submitBtnDisabled} type="submit">
            {intl.formatMessage(messages['verify.button.label'])}
          </UIButton>
        </section>
        {this.getResendButton()}
        <LinksContainer intl={intl} helpLinks={links.help.help.href} />
      </form>
    );
  }
}

const LinksContainer = ({intl, helpLinks}) => {
  return (
    <div className="links__container">
      <div className="instruction">
        {intl.formatMessage(messages.instruction)}&nbsp;
        <FormattedLink href={helpLinks} className="auth-link">
          {intl.formatMessage(messages.contact)}
        </FormattedLink>
      </div>
    </div>
  );
};

const CustomerService = ({intl, onSuccess, customerServiceNumber}) => {
  return (
    <section className="twofa_form__wrapper customer_service__form">
      <div className="info-title">{intl.formatMessage(messages['error.usercontactmissing'])}</div>
      <p className="customer-service-msg">{intl.formatMessage(messages['contactcustservice.message'])}</p>
      <div className="customer-service-number">{customerServiceNumber}</div>
      <UIButton onClick={onSuccess}>{intl.formatMessage(messages.cancel)}</UIButton>
    </section>
  );
};

export {MEDIUM};
export default connect(controller)(injectIntl(TwoFA));
