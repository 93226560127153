import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import controller from './controller';
import termsMessages from './termsMessages';

import './index.scss';

export const UserAgreementLink = (chunks) => (
  <FormattedLink href="/legal/?section=ua" target="_blank">
    {chunks}
  </FormattedLink>
);
export const PrivacyNoticeLink = (chunks) => (
  <FormattedLink href="/legal/?section=pp" target="_blank">
    {chunks}
  </FormattedLink>
);

const LegalTerms = ({isSignUp, uncheckMarketingOptIn}) => {
  return (
    <div className="accept-term__container">
      {isSignUp ? (
        <>
          <p>
            <FormattedMessage
              {...termsMessages.termstext_userAgreement}
              values={{
                ua: UserAgreementLink,
                pp: PrivacyNoticeLink,
              }}
            />
          </p>
          <p>
            {uncheckMarketingOptIn ? (
              <FormattedMessage {...termsMessages.termstext_with_marketing} />
            ) : (
              <FormattedMessage {...termsMessages.termstext_wo_marketing} />
            )}
          </p>
        </>
      ) : (
        <p>
          <FormattedMessage
            {...termsMessages.termstext_intl}
            values={{
              ua: UserAgreementLink,
              pp: PrivacyNoticeLink,
            }}
          />
        </p>
      )}
    </div>
  );
};

LegalTerms.propTypes = {
  /** Flag to determine if the component is being used in a sign up flow */
  isSignUp: PropTypes.bool,

  /** Flag to determine if the marketing opt in should be unchecked */
  uncheckMarketingOptIn: PropTypes.bool,
};

export default connect(controller)(LegalTerms);
