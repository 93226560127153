/* istanbul ignore file */

/**
 * This is a Bullet App convention file.
 * DON'T RENAME OR MOVE TO OTHER FOLDERS.
 *
 * Please only export a default method e.g. called `getRoutes`,
 * which returns an array of `Route` (from react-router-dom v4.x)
 */

import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {Logout} from '../modules/react-login';
import {LOGIN_TYPE, sessionValidate} from '../modules/react-login/controller';
import ProxyLoginContainer from '../modules/react-login-proxy';
import TwoFA, {MEDIUM} from '../modules/react-twofa';

import routeConfig from './route-config';

const {
  base,
  bindSocial,
  signUpSocial,
  signup,
  signOut,
  forgotPassword,
  resetPassword,
  createPassword,
  forceChangePassword,
} = routeConfig;

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ '../modules/react-login'),
  loading: () => null,
});

const LoginComp = ProxyLoginContainer(Login);

const onGuestClick = (langPath) => {
  if (__CLIENT__ && window !== 'undefined') {
    window.location.href = `${langPath}/my/guest/orderlookup`;
  }
};

export default function getRoutes({currentLocale, defaultLocale}) {
  const lang = currentLocale !== defaultLocale ? currentLocale.substr(0, 2) : '';
  const langPath = lang ? `/${lang}` : '';

  const routes = [
    <Route
      {...base}
      exact
      path={`${langPath}/login`}
      render={({location}) => (
        <LoginComp
          onGuestClick={onGuestClick.bind(this, langPath)}
          location={location}
          defaultLoginType={LOGIN_TYPE.SIGNIN}
        />
      )}
      key="default"
    />,
    <Route
      {...bindSocial}
      path={`${langPath}/login/social-connect`}
      render={({location}) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.BIND_SOCIAL} />}
      key={LOGIN_TYPE.BIND_SOCIAL}
    />,
    <Route
      {...signUpSocial}
      path={`${langPath}/login/social-signup`}
      render={({location}) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.SIGN_UP_SOCIAL} />}
      key={LOGIN_TYPE.SIGN_UP_SOCIAL}
    />,
    <Route
      {...signup}
      path={`${langPath}/login/signup`}
      render={({location}) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.SIGNUP} />}
      key={LOGIN_TYPE.SIGNUP}
    />,
    <Route
      {...signOut}
      path={`${langPath}/login/signout`}
      render={({location}) => <Logout location={location} />}
      key={LOGIN_TYPE.SIGN_OUT}
    />,
    <Route
      {...forgotPassword}
      path={`${langPath}/login/forgot-password`}
      render={({location}) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.FORGOT_PASSWORD} />}
      key={LOGIN_TYPE.FORGOT_PASSWORD}
    />,
    <Route
      {...resetPassword}
      path={`${langPath}/login/reset-password`}
      render={(location) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.RESET_PASSWORD} />}
      key={LOGIN_TYPE.RESET_PASSWORD}
    />,
    <Route
      {...createPassword}
      path={`${langPath}/login/create-password`}
      render={(location) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.CREATE_PASSWORD} />}
      key={LOGIN_TYPE.CREATE_PASSWORD}
    />,
    <Route
      {...forceChangePassword}
      path={`${langPath}/login/change-your-password`}
      render={(location) => <LoginComp location={location} defaultLoginType={LOGIN_TYPE.FORCE_CHANGE_PASSWORD} />}
      key={LOGIN_TYPE.FORCE_CHANGE_PASSWORD}
    />,
  ];

  return routes;
}

export {TwoFA, MEDIUM, sessionValidate};
export {LoginComp as Login};
