const FEATURE_FLAGS = {
  common: {
    advisory: {
      /** Is advisory currency enabled? */
      enabled: 'common.enableAdvisoryCurrency',

      /** Is advisory currency opt-out enabled? */
      enableOptOut: 'common.advisory.enableOptOut',
    },

    /** Is cookies modal enabled? */
    cookiesModal: 'common.cookiesModal.show',

    register: {
      /** Is marketing opt-in checkbox unchecked? */
      uncheckMarketingOptIn: 'common.register.uncheckMarketingOptIn',
    },
  },
  login: {
    social: {
      /** Is google login enabled? */
      google: 'login.enableGoogleLogin',
    },

    /** VoiceFlow Chatbot enabled */
    voiceFlow: 'login.voiceflow.enable',
  },
};

export default FEATURE_FLAGS;
