/* eslint-disable react/no-unused-state */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {GoogleLogin} from '@react-oauth/google';
import cn from 'classnames';
import jwt_decode from 'jwt-decode';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {injectIntl} from 'react-intl';

import FeatureToggle from '@stubhub/react-feature-toggle';
import {connect} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';

import FEATURE_FLAGS from '../../util/featureFlags';
import UIButton from '../react-uikit-button';

import {FACEBOOK_APP_VERSION, FACEBOOK_PERMISSIONS} from './config';
import controller from './controller';

import './index.scss';

const SocialLogin = (
  {
    cacheSocialTypeAndData,
    isFacebookIdentityLoginProcessing,
    lang,
    onLoginSuccess,
    processSocialDirectLogin,
    propagateError,
    fbAppId,
  },
  {logger, cookies}
) => {
  const lang_low_dash = lang?.replace('-', '_');

  const [isFacebookSDKLoginProcessing, setIsFacebookSDKLoginProcessing] = useState(false);
  const [isAppleSDKLoginProcessing, setIsAppleSDKLoginProcessing] = useState(false);
  const [isGoogleSDKLoginProcessing, setIsGoogleSDKLoginProcessing] = useState(false);

  const handleAppleWrapperClick = () => {
    startSocialLogin('apple');

    const script = document.createElement('script');
    script.src = process.env.REACT_APP_APPLE_AUTH_SRC;
    script.async = true;
    document.head.appendChild(script);
    script.addEventListener('load', async () => {
      const params = {
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        redirectURI: `https://${window.location.host}${process.env.REACT_APP_APPLE_REDIRECT_URI}`,
        usePopup: true,
        scope: process.env.REACT_APP_APPLE_SCOPE,
      };

      window.AppleID.auth.init(params);

      try {
        const data = await window.AppleID.auth.signIn();

        setIsAppleSDKLoginProcessing(false);

        const response = await post({
          path: '/bfx-login/api/social/redirect',
          body: data,
          json: true,
        });
        logger.info('response: ', response);

        onAppleSDKLoginSuccess(response);
      } catch (err) {
        logger.error('react-login-social-login: ', err);
        appleLoginFailHandler();
        setIsAppleSDKLoginProcessing(false);
      }
    });
  };

  // Call before social login process is started

  const startSocialLogin = (idptype) => {
    if (idptype === 'facebook') {
      setIsFacebookSDKLoginProcessing(true);
    } else if (idptype === 'apple') {
      setIsAppleSDKLoginProcessing(true);
    } else if (idptype === 'google') {
      setIsGoogleSDKLoginProcessing(true);
    } else {
      // eslint-disable-next-line no-console
      console.error('invalid idptype');
    }
  };

  // Facebook login success handler
  const onFacebookSDKLoginSuccess = async (fbUserData) => {
    // eslint-disable-next-line no-console
    console.log('fb data: ---', fbUserData);
    setIsFacebookSDKLoginProcessing(false);
    cacheSocialTypeAndData('facebook', fbUserData);
    const isSuccess = await onSocialSDKLoginSuccess('facebook', fbUserData.accessToken, fbUserData.userID);
    if (isSuccess) {
      onLoginSuccess('facebook');
    }
  };

  // Apple login success handler
  const onAppleSDKLoginSuccess = async (appleUserData) => {
    setIsAppleSDKLoginProcessing(false);
    cacheSocialTypeAndData('apple', appleUserData);
    const isSuccess = await onSocialSDKLoginSuccess('apple', appleUserData.accessToken, appleUserData.userID);
    if (isSuccess) {
      onLoginSuccess('apple');
    }
  };

  const onSocialSDKLoginSuccess = async (idpType, idpToken, idpUserId) => {
    return await processSocialDirectLogin({idpType, idpToken, idpUserId, cookies});
  };

  // Facebook login fail handler
  const fbLoginFailHandler = () => {
    setIsFacebookSDKLoginProcessing(false);
    propagateError('facebookLoginNotValidError');
  };

  // Apple login fail handler
  const appleLoginFailHandler = () => {
    setIsAppleSDKLoginProcessing(false);
    propagateError('appleLoginNotValidError');
  };

  // Google login handlers
  const googleLoginSuccessHandler = async (response) => {
    const decoded = jwt_decode(response.credential);
    setIsGoogleSDKLoginProcessing(false);
    cacheSocialTypeAndData('google', {
      tokenId: response.credential,
      decoded,
    });

    const isSuccess = await onSocialSDKLoginSuccess('google', response.credential, decoded.sub);
    if (isSuccess) {
      onLoginSuccess('google');
    }
  };

  const googleLoginFailHandler = (response) => {
    logger.log('Google Login Failure: ', response);
    setIsGoogleSDKLoginProcessing(false);
    propagateError('googleLoginNotValidError');
  };

  useEffect(() => {
    /*
     * Context:
     * Usually react-facebook-login will load facebook sdk async, and write fbAsyncInit function, so that once sdk is loaded, fbAsyncInit function is called.
     * If facebook sdk is loaded already before login component is rendered, react-facebook-login will not invoke FB.init function.
     * Solution:
     * Ensure that FB.init is called if the case above is triggered.
     */
    window?.FB?.init({
      version: `v${FACEBOOK_APP_VERSION}`,
      appId: fbAppId,
    });
  }, [fbAppId]);

  const facebookLoginProcessing = isFacebookSDKLoginProcessing || isFacebookIdentityLoginProcessing;

  /*
   * Const errorMessage = errorMsgKey ? intl.formatMessage(messages[errorMsgKey]) : '';
   * const fbProcessingText = intl.formatMessage(messages.facebookLoginProcessing);
   */

  return (
    <div className="social-login">
      <FacebookLogin
        appId={fbAppId}
        autoLoad={false}
        language={lang_low_dash}
        isMobile={false}
        fields="name,email,picture,first_name,last_name"
        render={(renderProps) => (
          <UIButton
            secondary
            className={cn('facebook', {disabled: facebookLoginProcessing})}
            onClick={() => {
              startSocialLogin('facebook');
              renderProps.onClick();
            }}
            disabled={facebookLoginProcessing}
          >
            <div className="svg-button" aria-label="Facebook" />
          </UIButton>
        )}
        onFailure={fbLoginFailHandler}
        callback={onFacebookSDKLoginSuccess}
        version={FACEBOOK_APP_VERSION}
        scope={FACEBOOK_PERMISSIONS}
      />
      <FeatureToggle name={FEATURE_FLAGS.login.social.google} key="loginSocialGoogleEnabled">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={googleLoginSuccessHandler}
          onFailure={googleLoginFailHandler}
          isSignedIn={false}
          type="icon"
          shape="circle"
          size="large"
          enabled={!isGoogleSDKLoginProcessing}
        />
      </FeatureToggle>
      <UIButton
        secondary
        className={cn(['apple-wrapper', {disabled: isAppleSDKLoginProcessing}])}
        type="button"
        onClick={handleAppleWrapperClick}
      >
        <div id="appleText" aria-label="Apple" />
      </UIButton>
    </div>
  );
};

SocialLogin.contextTypes = {
  cookies: PropTypes.object,
  track: PropTypes.object,
  logger: PropTypes.object,
};

SocialLogin.propTypes = {
  cacheSocialTypeAndData: PropTypes.func,
  isFacebookIdentityLoginProcessing: PropTypes.bool,
  lang: PropTypes.string,
  onLoginSuccess: PropTypes.func,
  processSocialDirectLogin: PropTypes.func,
  propagateError: PropTypes.func,
  fbAppId: PropTypes.string,
};

export const IntlSocialLogin = injectIntl(SocialLogin);
export default connect(controller)(IntlSocialLogin);
