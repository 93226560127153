import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const isExisty = function (value) {
  return value !== null && value !== undefined;
};

const isEmpty = function (value) {
  return value === '' || value === undefined || value === null;
};

const isEmptyTrimed = function (value) {
  if (typeof value === 'string') {
    return value.trim() === '';
  }

  return true;
};

export const validations = {
  matchRegexp: (value, regexp) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);

    return !isExisty(value) || isEmpty(value) || validationRegexp.test(value);
  },

  isEmail: (value) =>
    validations.matchRegexp(
      value,
      // eslint-disable-next-line
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
    ),
  isEmpty: (value) => isEmpty(value),
  required: (value) => !isEmpty(value),
  trim: (value) => !isEmptyTrimed(value),
  isNumber: (value) => validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),
  isFloat: (value) => validations.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),
  isPositive: (value) => {
    if (isExisty(value)) {
      return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
    }

    return true;
  },
  maxNumber: (value, max) => !isExisty(value) || isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),
  minNumber: (value, min) => !isExisty(value) || isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),
  isString: (value) => !isEmpty(value) || typeof value === 'string' || value instanceof String,
  minStringLength: (value, length) => validations.isString(value) && value.length >= length,
  maxStringLength: (value, length) => validations.isString(value) && value.length <= length,
  minNumberCount: (value, count) =>
    validations.matchRegexp(value, /\d+/gi) && value && value.match(/\d{1}/gi).length >= count,
  minLetterCount: (value, count) =>
    validations.matchRegexp(value, /[a-zA-Z]+/gi) && value && value.match(/[a-zA-Z]{1}/gi).length >= count,
};

class Validation extends Component {
  state = {
    errors: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.children.props[nextProps.valueProp] !== this.props.children.props[this.props.valueProp]) {
      const errors = [];
      // If (nextProps.children.props[nextProps.valueProp]) {
      nextProps.validators.forEach((element) => {
        const {validator, errorMessage} = element;
        if (Array.isArray(validator)) {
          if (!validations[validator[0]](nextProps.children.props[nextProps.valueProp], validator[1])) {
            errors.push({validator: validator[0], errorMessage});
          }
        } else if (!validations[validator](nextProps.children.props[nextProps.valueProp])) {
          errors.push({validator, errorMessage});
        }
      });
      // }
      this.props.onChangeCallback && this.props.onChangeCallback(errors);
      if (errors.length > 0) {
        this.setState({errors});
      } else {
        this.setState({
          errors: [],
        });
      }
    }
  }
  render() {
    const validatorClass = cx(
      {
        validator: true,
        visible: this.props.visible,
      },
      this.props.className
    );

    return (
      <>
        {this.props.children}
        {this.props.visible && this.state.errors && this.state.errors.length > 0 && (
          <div className={validatorClass} style={this.props.errorStyle}>
            {this.state.errors.map((item) => (
              <span key={item.validator}>{item.errorMessage}</span>
            ))}
          </div>
        )}
      </>
    );
  }
}

Validation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element]),
  // eslint-disable-next-line react/no-unused-prop-types
  validators: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.arrayOf(PropTypes.string)]),
  visible: PropTypes.bool,
  className: PropTypes.string,
  onChangeCallback: PropTypes.func,
  valueProp: PropTypes.string,
  errorStyle: PropTypes.object,
};

Validation.defaultProps = {
  onChangeCallback: null,
  valueProp: 'value',
  visible: true,
  errorStyle: {},
};

export default Validation;
