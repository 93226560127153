import {Controller} from '@stubhub/react-store-provider';

import {isMarketingOptInFFEnabledSelector} from '../../store/gsConfig/selectors';

const controller = new Controller({
  namespace: 'legal_terms',
  mapStateToProps(state) {
    return {
      uncheckMarketingOptIn: isMarketingOptInFFEnabledSelector(state),
    };
  },
});

export default controller;
