import {createSelector} from 'reselect';

import FEATURE_FLAGS from '../../util/featureFlags';

const gsConfigSelector = (state) => (state && state.gsConfig) || {};
const storeIdSelector = createSelector(gsConfigSelector, (gsConfig = {}) => gsConfig.shstoreId);
const isAdvisoryEnabledSelector = createSelector(
  (state) => state.gsConfig || {},
  (gsConfig) => gsConfig.features && gsConfig.features[FEATURE_FLAGS.common.advisory.enabled]
);
const featureFlagsSelector = createSelector(gsConfigSelector, (gsConfig = {}) => gsConfig.features || {});
const currentLocaleSelector = createSelector(gsConfigSelector, (gsConfig = {}) => gsConfig.currentLocale);

const isMarketingOptInFFEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.register.uncheckMarketingOptIn]
);

export {
  storeIdSelector,
  isAdvisoryEnabledSelector,
  featureFlagsSelector,
  currentLocaleSelector,
  isMarketingOptInFFEnabledSelector,
};
