import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.defaultChecked || false,
    };
  }
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    handleCheckboxChange: PropTypes.func,
  };
  static defaultProps = {
    name: 'input-checkbox',
    label: '',
    value: 'on',
  };

  toggleCheckboxChange = () => {
    this.setState(
      ({isChecked}) => ({
        isChecked: !isChecked,
      }),
      () => {
        if (this.props.handleCheckboxChange && typeof this.props.handleCheckboxChange === 'function') {
          this.props.handleCheckboxChange(this.state.isChecked, this.props.value);
        }
      }
    );
  };

  render() {
    const {label, name, value} = this.props;
    const {isChecked} = this.state;

    return (
      <div className="uikit-input-checkbox__container">
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
          onChange={this.toggleCheckboxChange}
          id="input-checkbox"
        />
        <label htmlFor="input-checkbox">
          <span>{label}</span>
        </label>
      </div>
    );
  }
}

export default Checkbox;
