import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'CentralLogin.signIn.title',
    defaultMessage: 'Sign in',
  },
  email: {
    id: 'CentralLogin.signIn.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'CentralLogin.signIn.password',
    defaultMessage: 'Password',
  },
  signing: {
    id: 'CentralLogin.signIn.signing',
    defaultMessage: 'One moment...',
  },
  signin: {
    id: 'CentralLogin.signIn.signin',
    defaultMessage: 'Sign in',
  },
  signup: {
    id: 'CentralLogin.signIn.signup',
    defaultMessage: 'Create an account',
  },
  haveAccessCode: {
    id: 'CentralLogin.signIn.haveAccessCode',
    defaultMessage: 'Have an access code?',
  },
  findYourOrder: {
    id: 'CentralLogin.signIn.findYourOrder',
    defaultMessage: 'Find your order',
  },
  newtostubhub: {
    id: 'CentralLogin.signIn.newtostubhub',
    defaultMessage: 'New to StubHub?',
  },
  staySignIn: {
    id: 'CentralLogin.signIn.staySignIn',
    defaultMessage: 'Remember me',
  },
  forgotPassword: {
    id: 'CentralLogin.signIn.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
  resetPassword: {
    id: 'CentralLogin.signIn.resetPassword',
    defaultMessage: 'Forgot your password?',
  },
  emptyEmailPasswordError: {
    id: 'CentralLogin.signIn.error.emptyEmailPassword',
    defaultMessage: 'We need your email and password.',
  },
  emptyEmailError: {
    id: 'CentralLogin.signIn.error.emptyEmail',
    defaultMessage: 'Oops! We need an email address.',
  },
  emptyPasswordError: {
    id: 'CentralLogin.signIn.error.emptyPassword',
    defaultMessage: 'Oops! We need a password.',
  },
  emailFormatError: {
    id: 'CentralLogin.signIn.error.emailFormat',
    defaultMessage: 'Oops! We didn\'t recognize that email.',
  },
  userAgreementMissingError: {
    id: 'CentralLogin.signIn.error.userAgreementMissing',
    defaultMessage: 'User Agreement not accepted.',
  },
  maxAttemptsExceedsError: {
    id: 'CentralLogin.signIn.error.maxAttemptsExceeds',
    defaultMessage:
      'Your account has been locked due to multiple invalid attempts. Please contact Customer Service at {phone} to unlock your account. ',
  },
  maxAttemptsExceedsErrorToContactUs: {
    id: 'CentralLogin.signIn.error.maxAttemptsExceedsToContactUs',
    defaultMessage:
      'Your account has been locked due to multiple invalid attemps. Please {contactUsUrl} to unlock your account.',
  },
  contactUs: {
    id: 'CentralLogin.signIn.error.contactUs',
    defaultMessage: 'contact us',
  },
  invalidCompoError: {
    id: 'CentralLogin.signIn.error.invalidCompo',
    defaultMessage: 'Username or password is incorrect',
  },
  timeoutError: {
    id: 'CentralLogin.signIn.error.timeout',
    defaultMessage: 'Oopse, the request took too long. Please try again.',
  },
  genericError: {
    id: 'CentralLogin.signIn.error.generic',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
  emailError: {
    id: 'CentralLogin.signIn.error.email',
    defaultMessage: 'Incorrect email format',
  },
  invalid_password: {
    id: 'CentralLogin.register.invalidPWD',
    defaultMessage:
      'Oops! Your password needs to be 8-20 characters; a combo of numbers, letters, and/or special characters; and cannot be your email address.',
  },
  guest: {
    id: 'CentralLogin.signIn.guest',
    defaultMessage: 'Continue as guest',
  },
});

export default messages;
