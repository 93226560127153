import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default class LoginLink extends PureComponent {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static contextTypes = {
    globalRegistry: PropTypes.object,
  };

  render() {
    let {to} = this.props;
    const {children, ...otherProps} = this.props;
    const {globalRegistry} = this.context;

    const defaultLocale = globalRegistry && globalRegistry.getDefaultLocale();
    const currentLocale = globalRegistry && globalRegistry.getCurrentLocale();
    const lang = currentLocale !== defaultLocale ? currentLocale.substr(0, 2) : '';
    const langPath = lang ? `/${lang}` : '';

    // Support multi-lang URL
    if (typeof to === 'object') {
      to = {...to, pathname: `${langPath}${to.pathname}`};
    } else {
      to = `${langPath}${to}`;
    }

    return (
      <Link to={to} {...otherProps}>
        {children}
      </Link>
    );
  }
}
