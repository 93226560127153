/* eslint-disable react/display-name */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import '@stubhub/react-font-icons';

import './input.scss';

class Input extends Component {
  constructor(props) {
    super(props);
    const {value = '', type} = props;
    this.state = {
      isFocused: false,
      value,
      type,
    };
  }

  static defaultProps = {type: 'text'};
  static propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    subLabel: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    isNumber: PropTypes.bool,
    isCapitalized: PropTypes.bool,
    isPassword: PropTypes.bool,
    isAutofilled: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.focused && nextProps.focused) {
      this._input.focus();
    }
    if (this.props.value !== nextProps.value) {
      this.setState({value: nextProps.value});
    }
  }

  checkIsNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  isHighlighted = () => {
    return __CLIENT__ && window.getSelection && window.getSelection().type === 'Range';
  };

  onChange = (e) => {
    const {onChange, isNumber} = this.props;
    const {value: stateValue} = this.state;
    const {value = ''} = e.target;
    if (isNumber && value.length && !this.checkIsNumeric(value)) {
      this._input.value = stateValue;

      return;
    }
    if (!e.currentTarget.classList.contains('input-hide')) {
      this.setState({value});
      onChange && onChange(value, e);
    }
    this.handleAutoAdvance(e);
  };

  onKeyPress = (e) => {
    const {isNumber, onKeyPress, maxLength} = this.props;
    const value = e.key;
    const highlighted = this.isHighlighted();
    if (
      (isNumber && !this.checkIsNumeric(value)) ||
      (!highlighted && maxLength && e.target.value.length + value.length > maxLength)
    ) {
      e.preventDefault();

      return;
    }
    onKeyPress && onKeyPress(e);
  };

  handleAutoAdvance = (e) => {
    const {target} = e;
    const {value = ''} = target;
    const {autoTab, maxLength} = this.props;
    if (autoTab && maxLength && value.length >= maxLength) {
      autoTab(this._input);
    }
  };

  componentDidMount() {
    if (this.props.focused) {
      setTimeout(() => this._input.focus());
    }
  }

  renderInput = (onBlur, onFocus, type, value, id, props) => {
    return (
      <input
        ref={(input) => {
          this._input = input;
        }}
        {...{
          onBlur: () => {
            this.setState({
              isFocused: false,
            });
            onBlur && onBlur();
          },
          onFocus: () => {
            this.setState({
              isFocused: true,
            });
            onFocus && onFocus();
          },
          id,
          onKeyPress: this.onKeyPress,
          onChange: this.onChange,
          type,
          style: {width: '100%'},
          value,
          autoCorrect: 'off',
          spellCheck: false,
          className: 'input-wrapper__child',
          ...props,
        }}
      />
    );
  };

  render() {
    const {
      onBlur,
      onFocus,
      label,
      id,
      subLabel,
      allCaps, // Ignoring field
      isNumber, // Ignoring field
      onChange, // Ignoring field
      autoTab, // Ignoring field
      focused, // Ignoring field,
      isPassword,
      value: ignoreValue,
      className,
      isAutofilled,
      ...otherProps
    } = this.props;
    const {isFocused, value, type} = this.state;

    const handleOnClickClearValue = (e) => {
      this._input.value = '';
      this.onChange(e);
    };

    const handleOnClickChangeType = (e) => {
      this._input.type = this._input.type === 'text' ? 'password' : 'text';
      this.setState({type: this.state.type === 'text' ? 'password' : 'text'});
      this.onChange(e);
    };

    return (
      <div
        className={cx(className, {
          'input-wrapper': true,
          'input-focused': isFocused,
          'input-all-caps': allCaps,
          'with-sublabel': subLabel && value,
        })}
      >
        {label && (
          <label
            htmlFor={id}
            className={cx({
              'input-label': true,
              'input-label--upper': value || isFocused || isAutofilled,
            })}
          >
            {label}
          </label>
        )}
        <div className={cx('input__with--sublabel', {'input__active-wrapper': value || isFocused || isAutofilled})}>
          {subLabel && value && (
            <div className="input__label--sub">
              <span>{subLabel}</span>
            </div>
          )}
          {this.renderInput(onBlur, onFocus, type, value, id, otherProps)}
        </div>
        {isFocused && value && !isPassword && (
          <div className="input-clear" onMouseDown={handleOnClickClearValue} onTouchStart={handleOnClickClearValue}>
            <span className="sh-iconset sh-iconset-close clear-search-button" />
          </div>
        )}
        {value && isPassword && (
          <div
            className="input-hide input-clear"
            onMouseDown={handleOnClickChangeType}
            onTouchStart={handleOnClickChangeType}
          >
            <span
              className={cx(
                'sh-iconset',
                {'sh-iconset-unhide': type === 'password'},
                {'sh-iconset-hide': type === 'text'}
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

export const withError =
  (WrappedComponent) =>
  ({errorMessage, showErrorBorder, ...props}) => {
    const {isPassword} = props;

    if (!isPassword) {
      return (
        <div
          className={cx({
            inputwitherror: true,
            inputwitherror__error: !!errorMessage,
          })}
        >
          <WrappedComponent {...props} />
          <div className="inputwitherror__error-container">{errorMessage}</div>
        </div>
      );
    }

    return (
      <div
        className={cx({
          inputwitherror: true,
          inputwitherror__error: showErrorBorder,
        })}
      >
        <WrappedComponent {...props} />
        <div className="inputwitherror__error-container">{errorMessage}</div>
      </div>
    );
  };

export const InputWithError = withError(Input);

export default Input;
