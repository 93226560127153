import {defineMessages} from 'react-intl';

const messages = defineMessages({
  forceChangePasswordTitle: {
    id: 'CentralLogin.forceChangePassword.title',
    defaultMessage: 'Change your password',
  },
  forceChangePasswordInstruction1: {
    id: 'CentralLogin.forceChangePassword.instruction1',
    defaultMessage: 'Our systems have detected your login credentials may have been compromised.',
  },
  forceChangePasswordHelpLink: {
    id: 'CentralLogin.forceChangePassword.helpLink',
    defaultMessage: 'What does this mean?',
  },
  forceChangePasswordInstruction2: {
    id: 'CentralLogin.forceChangePassword.instruction2',
    defaultMessage: 'We recommend changing your password on StubHub immediately to protect your account.',
  },
  forceChangePasswordSubmit: {
    id: 'CentralLogin.forceChangePassword.submit',
    defaultMessage: 'Reset password',
  },
});

export default messages;
