import {defineMessages} from 'react-intl';

const messages = defineMessages({
  password_strength: {
    id: 'CentralLogin.passwordValidation.strength',
    defaultMessage: 'Password strength:',
  },
  password_rule_length: {
    id: 'CentralLogin.passwordValidation.rule.length',
    defaultMessage: 'At least 8 characters',
  },
  password_rule_complexity: {
    id: 'CentralLogin.passwordValidation.rule.complexity',
    defaultMessage: 'Contains a number or symbol',
  },
  password_strength_score_0: {
    id: 'CentralLogin.passwordValidation.weak',
    defaultMessage: 'weak',
  },
  password_strength_score_1: {
    id: 'CentralLogin.passwordValidation.good',
    defaultMessage: 'good',
  },
  password_strength_score_2: {
    id: 'CentralLogin.passwordValidation.strong',
    defaultMessage: 'strong',
  },
  password_strength_score_3: {
    id: 'CentralLogin.passwordValidation.veryStrong',
    defaultMessage: 'very strong',
  },
  confirmPwdNotMatch: {
    id: 'CentralLogin.passwordValidation.notMatch',
    defaultMessage: 'Passwords don\'t match ',
  },
});

export default messages;
