/* eslint-disable react/no-unused-state */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';

import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import LegalTerms from '../react-legal-terms';
import Input from '../react-ui-components/input';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import validate from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';

import controller from './controller';
import connectSocialMessages from './messages';

import './index.scss';

export const PageHeader = ({socialType, intl}) => (
  <LoginHeader title={intl.formatMessage(connectSocialMessages.connectTitle, {socialType})}>
    <FormattedMessage
      {...connectSocialMessages.connectTitle}
      values={{socialType: <span className="socialType">{socialType}</span>}}
    />
  </LoginHeader>
);

class ConnectSocial extends Component {
  static propTypes = {
    goToSignUp: PropTypes.func,
    goToForgotPassword: PropTypes.func,
    hasAPIError: PropTypes.bool,
  };

  static contextTypes = {
    cookies: PropTypes.object,
    track: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMsg: this.props.errorMsg || '',
      isLinking: false,
      isValidInput: false,
      email: this.props.signUpEmail,
      password: '',
    };
  }

  trackingOptions(appInteraction) {
    const {track} = this.context;
    const appInteractionType = 'NE: Login Component';
    const s = __CLIENT__ && window.s;

    if (!s) {
      return;
    }

    const trackingObj = {
      appInteraction: `${appInteractionType}: ${appInteraction}`,
      appInteractionType,
      userStatus: s.eVar69,
      userGUID: s.eVar66,
      socialSiteInteraction: s.eVar25,
      login_type: s.eVar105,
    };

    if (track) {
      track.click(trackingObj.appInteractionType, trackingObj);
    }

    return trackingObj;
  }

  validateInput = () => {
    const {email, password} = this.state;
    let isValidInput = false;
    if (email && password) {
      isValidInput = true;
      this.setState({
        isValidInput,
        hasError: false,
        errorMsg: '',
      });
    } else {
      isValidInput = false;
      if (!email) {
        this.setState({
          isValidInput,
          hasError: true,
          errorMsg: 'emptyEmailInput',
        });
      } else {
        this.setState({
          isValidInput,
          hasError: false,
          errorMsg: '',
        });
      }
    }

    return isValidInput;
  };

  linkAccountHandler = () => {
    const {isLinking, email, password} = this.state;
    const isValidInput = this.validateInput();

    if (isLinking || !isValidInput) {
      return;
    }

    this.trackingOptions('Link Existing Account: Link Account Click');

    // Clear errors
    this.setState({
      hasError: false,
      errorMsg: '',
      isLinking: true,
    });

    const formData = {
      username: email,
      password,
    };

    this.props.beforeSocialBindLogin();
    this.props.processSocialBindLogin(
      {
        ...this.props.socialData,
        ...formData,
      },
      () => {
        // Do nothing.
      },
      () => {
        this.setState({
          isLinking: false,
        });
        this.trackingOptions();
      }
    );
  };

  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    const errors = {...this.state.errors, ...validate(values)};
    const {handleOnChange} = this.props;

    handleOnChange && handleOnChange(field, value);
    this.setState({
      [field]: value,
      errors,
      showError: Object.values(errors).filter((it) => it).length > 0,
    });
  };

  goToSignUp = () => {
    this.trackingOptions('Link Existing Account: Sign Up Click');
    const {goToSignUp} = this.props;
    goToSignUp && goToSignUp();
  };

  goToForgotPassword = () => {
    this.trackingOptions('Link Existing Account: Forgot Password Click');
    const {goToForgotPassword} = this.props;
    goToForgotPassword && goToForgotPassword(this.state.email);
  };

  getError = () => {
    let errorMessage = '';
    let needHelp = false;
    if (this.state.hasError) {
      const {errorMsg} = this.state;
      errorMessage = <FormattedMessage {...connectSocialMessages[errorMsg]} />;
    } else {
      const {apiErrorMsgKey} = this.props;
      errorMessage = <FormattedMessage {...connectSocialMessages[apiErrorMsgKey]} />;
      needHelp = true;
    }

    return (
      <LoginAlert>
        {errorMessage}
        &nbsp;
        {needHelp && (
          <FormattedLink
            className="connect-social__reset-password-link formattedlink_without_href"
            onClick={this.goToForgotPassword}
          >
            <FormattedMessage {...connectSocialMessages.loginConnectForgotPassword} />
          </FormattedLink>
        )}
      </LoginAlert>
    );
  };

  renderConnectSocialForm = () => {
    const {intl, socialData = {}} = this.props;
    const {idpType} = socialData;
    const {hasError, isLinking} = this.state;
    const {email, password} = this.state;

    const buttonLabel = isLinking ? (
      <FormattedMessage {...connectSocialMessages.loadingTextLabel} />
    ) : (
      <FormattedMessage {...connectSocialMessages.linkAccountTextLabel} />
    );

    return (
      <div className="central-login-connect-social">
        <div className="connect-social__title">
          <PageHeader intl={intl} socialType={idpType} />
        </div>
        <div className="connect-social__sub-note">
          <div className="connect-social__sub-note__content">
            <FormattedMessage
              {...connectSocialMessages.subNote}
              values={{email: <b>{email}</b>, socialType: <span className="socialType">{idpType}</span>}}
            />
          </div>
        </div>

        <div className="connect-social__form">
          {(hasError || this.props.hasAPIError) && this.getError()}

          <Input
            label={intl.formatMessage(connectSocialMessages.passwordPlaceholder)}
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            autoCapitalize="off"
            spellCheck={false}
            autoCorrect="off"
            onChange={this.handleOnChange('password')}
            value={password}
            isPassword
            tabIndex={2}
          />
          <div className="connect-social__link connect-social__forgot-password-link">
            <FormattedLink
              className="formattedlink_without_href connect-social__forgot-password-link__text"
              onClick={this.goToForgotPassword}
            >
              <FormattedMessage {...connectSocialMessages.loginConnectForgotPassword} />
            </FormattedLink>
          </div>
          <UIButton disabled={isLinking} onClick={this.linkAccountHandler}>
            {buttonLabel}
          </UIButton>
          <LegalTerms />
        </div>
        <div className="connect-social__link connect-social__register-link">
          <FormattedMessage {...connectSocialMessages.registerLogan} />
          &nbsp;
          <FormattedLink className="formattedlink_without_href" onClick={this.goToSignUp}>
            <FormattedMessage {...connectSocialMessages.signUp} />
          </FormattedLink>
        </div>
      </div>
    );
  };

  render() {
    return this.renderConnectSocialForm();
  }
}

export default connect(controller)(injectIntl(ConnectSocial));
