import React from 'react';
import {PropTypes} from 'prop-types';
import cs from 'classnames';

import Logo, {LOGO_TYPES, BRAND_TYPES as LOGO_BRANDS} from '@stubhub/react-logo';

import './login-header.scss';

const LoginLogo = ({children, className, title}, {globalRegistry: {gsConfig} = {}}) => {
  const useAltBrand = gsConfig?.features?.['common.useAltBrand'];
  title && document && (document.title = title);

  return (
    <header className={cs('central-login__header', className, {altLogo: useAltBrand})}>
      <Logo
        className="central-login__logo"
        type={LOGO_TYPES.MERCH}
        brand={useAltBrand ? LOGO_BRANDS.ALTERNATIVE : LOGO_BRANDS.DEFAULT}
      />
      {children && <h1 className="central-login__title">{children}</h1>}
    </header>
  );
};

LoginLogo.contextTypes = {
  globalRegistry: PropTypes.object,
};

LoginLogo.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  title: PropTypes.string,
};

export default LoginLogo;
