import {defineMessages} from 'react-intl';

const termsMessages = defineMessages({
  termstext_intl: {
    id: 'CentralLogin.signUp.termsTextIntl',
    defaultMessage:
      'By signing in, you agree you have read and accepted our <ua>User Agreement</ua>. Please read our <pp>Privacy Notice</pp> for information regarding the processing of your data.',
  },
  termstext_wo_marketing: {
    id: 'CentralLogin.signUp.termsText.dataWithUnsubscribe',
    defaultMessage:
      'Your data will be used to create and manage your StubHub International account. We may also send you updates and offers; you can unsubscribe at any time using the link in our emails.',
  },
  termstext_with_marketing: {
    id: 'CentralLogin.signUp.termsText.dataNoUnsubscribe',
    defaultMessage:
      'Your data will be used to create and manage your StubHub International account. We may also send you updates related to your purchase, including event-related information.',
  },
  termstext_userAgreement: {
    id: 'CentralLogin.signUp.termsText.userAgreement',
    defaultMessage:
      'By creating an account, you agree you have read and accepted our <ua>User Agreement</ua>. Please read our <pp>Privacy Notice</pp> for information regarding the processing of your data.',
  },
});

export default termsMessages;
