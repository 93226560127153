import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';

import {_getRequestHeaders} from '../../util/api';

const FLOW = {
  STARTED: 'STARTED',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};
const ACTION_TYPE = {
  SET_FORGOT_PASSWORD_FLOW: 'SET_FORGOT_PASSWORD_FLOW',
  RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD',
};

const NAMESPACE = 'login_forgot_password';

const controller = new Controller({
  namespace: NAMESPACE,
  actionCreators: {
    forgotPassword,
    reset,
  },
  reducers: [ACTION_TYPE.SET_FORGOT_PASSWORD_FLOW],
  mapStateToProps(state) {
    const localState = this.getLocalState(state);

    return {
      flow: localState.flow,
    };
  },
});

controller.addResetReducer([ACTION_TYPE.RESET_FORGOT_PASSWORD]);

function reset() {
  return (dispatch) => {
    dispatch({type: ACTION_TYPE.RESET_FORGOT_PASSWORD});
  };
}

function forgotPassword(email, trackingFunc) {
  return (dispatch, getState) => {
    const state = getState();
    const shstore = state.gsConfig.shstoreId;
    dispatch({type: ACTION_TYPE.SET_FORGOT_PASSWORD_FLOW, flow: FLOW.STARTED});

    /*
     *For resetPassword feature, since the API will return `204 - no content` when API call success,
     *but that will cause JSON.parse error in browser client side if the options.json is set to true
     *due to the source code here - https://github.corp.ebay.com/Stubhub-npm/request-using-fetch/blob/master/index.js#L122
     *So, FE need to obviously set the headers (both accept and content-type) to application/json value, and the options.body should be JSON.stringify
     */
    return post({
      host: process.env.REACT_APP_API_HOST,
      path: '/password/forgot',
      headers: _getRequestHeaders(getState()),
      query: {
        shstore,
        source: 'central-login',
      },
      json: true,
      body: {
        email,
      },
    }).then(
      // eslint-disable-next-line promise/always-return
      () => {
        dispatch({type: ACTION_TYPE.SET_FORGOT_PASSWORD_FLOW, flow: FLOW.FORGOT_PASSWORD});
      },
      () => {
        trackingFunc('Reset Password: Invalid Email');
        dispatch({type: ACTION_TYPE.SET_FORGOT_PASSWORD_FLOW, flow: FLOW.FORGOT_PASSWORD});
      }
    );
  };
}

export {FLOW};
export default controller;
