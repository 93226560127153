import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

import {HTTP_CODES} from '@stubhub/general-utils';
import {publish} from '@stubhub/pubsub-utils';
import {connect} from '@stubhub/react-store-provider';

import {SIGNUP_EVENTS} from '../../apps/events-enum';
import {getRedirectUrl} from '../../util/helper';
import LegalTerms from '../react-legal-terms';
import LoginLink from '../react-login-link';
import Input, {InputWithError} from '../react-ui-components/input';
import InputError from '../react-ui-components/input-error';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import PasswordValidator from '../react-ui-components/password-validator';
import validate, {password_validator} from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';
import Checkbox from '../react-uikit-checkbox';
import {Col, Row} from '../react-uikit-layout';
import PhoneInput from '../react-uikit-phone-input';

import controller from './controller';
import messages from './messages';

import './index.scss';

@injectIntl
export class SignUpForm extends Component {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    marketing_email_opt_in: 'Y',
    backendError: '',
    errors: {},
    country: null,
    userDestination: null,
    showError: false,
    phoneNumber: '',
    formattedNumber: '',
  };
  static propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    intl: PropTypes.object,
    location: PropTypes.object,
  };

  static defaultProps = {
    submitting: false,
  };

  static contextTypes = {
    globalRegistry: PropTypes.object,
    location: PropTypes.object,
  };

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidMount() {
    const {globalRegistry} = this.context;
    const {location = {search: ''}, uncheckMarketingOptIn} = this.props;
    const country = globalRegistry && globalRegistry.getCurrentCountry && globalRegistry.getCurrentCountry();
    const removeDefaultPhone =
      globalRegistry &&
      globalRegistry.getFeatureValue &&
      globalRegistry.getFeatureValue('common.register.removeDefaultPhone');
    const countryDestinationMapping = {
      GB: 'UK',
    };
    const userDestination = countryDestinationMapping[country] || country;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      country,
      userDestination,
      removeDefaultPhone,
      marketing_email_opt_in: uncheckMarketingOptIn ? 'N' : 'Y',
    });
    publish(SIGNUP_EVENTS.LOADED, getRedirectUrl(location));
  }

  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    const errors = {...this.state.errors, ...validate(values)};

    this.setState({
      [field]: value,
      errors,
      showError: Object.values(errors).filter((it) => it).length > 0,
    });
  };

  handleCheckboxChange = (state) => {
    this.setState({
      showError: false,
      marketing_email_opt_in: state ? 'Y' : 'N',
    });
  };

  afterFail = (error) => {
    let errorMessage = '';

    try {
      const {body} = error;
      const code = body.error;
      const {status} = body;

      switch (code) {
        case 'INVALID_PASSWORD_LENGTH':
        case 'WEAK_CHARACTER_COMBINATION':
          errorMessage = <FormattedMessage {...messages.invalidPassword} />;
          break;
        case 'REMOTE_API_ERROR':
          errorMessage = (
            <FormattedMessage {...messages[status === HTTP_CODES.CONFLICT ? 'accountExists' : 'genericError']} />
          );
          break;
        default:
          errorMessage = <FormattedMessage {...messages.genericError} />;
      }
    } catch (e) {
      errorMessage = <FormattedMessage {...messages.genericError} />;
    }

    this.setState({
      backendError: errorMessage,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {handleSubmit} = this.props;
    const newState = {
      backendError: '',
      showError: false,
    };
    this.setState(newState);
    handleSubmit?.(this.state, this.afterFail, getRedirectUrl(this.props.location));
  };
  unformatContactNumber = (formattedNumber, countryCallingCode) => {
    return formattedNumber.replace(/\D/g, '').replace(countryCallingCode, '');
  };
  handleContactNumberChange = (formattedNumber, countryData = {}, isValid) => {
    const {dialCountryCode = ''} = countryData;
    this.setState({
      allowSave: isValid,
      countryCallingCode: dialCountryCode,
      formattedNumber,
      phoneNumber: this.unformatContactNumber(formattedNumber, dialCountryCode),
    });
  };
  getSubmitButton = () => {
    if (this.props.submitting) {
      return (
        <UIButton disabled type="submit">
          <FormattedMessage {...messages.submitting} />
        </UIButton>
      );
    }

    return (
      <UIButton
        type="submit"
        disabled={
          !(this.state.last_name && this.state.first_name && this.state.password && this.state.email) ||
          this.state.showError
        }
      >
        <FormattedMessage {...messages.signUp} />
      </UIButton>
    );
  };

  render() {
    const {intl} = this.props;
    const {globalRegistry} = this.context;
    const hideOptIn = globalRegistry && globalRegistry.getFeatureValue('common.register.uncheckMarketingOptIn');
    const {showError, errors, email, password, backendError} = this.state;
    const showEmailError = showError && email && errors.email;
    const {score, validators} = password_validator(password);

    return (
      <form name="register-form" id="register-form" onSubmit={this.onSubmit} autoComplete="no">
        {backendError && <LoginAlert>{backendError}</LoginAlert>}
        <Row>
          <Col md={6} xs={6}>
            <Input
              {...{
                id: 'first-name',
                onChange: this.handleOnChange('first_name'),
                label: intl.formatMessage(messages.firstName),
                type: 'text',
                autoCapitalize: 'on',
                value: this.state.first_name,
                tabIndex: 1,
                name: 'first_name',
                autoComplete: 'given-name',
                className: 'address-form__input address-form__group-input address-form__group-input--first-child',
              }}
            />
          </Col>
          <Col md={6} xs={6}>
            <Input
              {...{
                id: 'last-name',
                type: 'text',
                autoComplete: 'family-name',
                autoCapitalize: 'on',
                name: 'last_name',
                onChange: this.handleOnChange('last_name'),
                label: intl.formatMessage(messages.lastName),
                value: this.state.last_name,
                tabIndex: 2,
                className: 'address-form__input address-form__group-input address-form__group-input--last-child',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <InputWithError
              label={intl.formatMessage(messages.emailPlaceHolder)}
              id="email"
              name="email"
              type="email"
              autoComplete="chrome-off"
              autoCapitalize="off"
              onChange={this.handleOnChange('email')}
              value={email}
              tabIndex={3}
              errorMessage={
                showEmailError && (
                  <InputError
                    {...{
                      errorMessage: intl.formatMessage(messages.emailPwdError),
                    }}
                  />
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <PhoneInput
              // DefaultCountry={(country || 'us').toLowerCase()}
              placeholder={intl.formatMessage(messages.phonePlaceHolder)}
              disableAreaCodes
              enableSearchField
              onChange={this.handleContactNumberChange}
              value={this.state.formattedNumber}
              id="phone-input"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <InputWithError
              label={intl.formatMessage(messages.passwordPlaceHolder)}
              id="password"
              name="new-password"
              type="password"
              autoComplete="new-password"
              autoCapitalize="off"
              onChange={this.handleOnChange('password')}
              value={password}
              isPassword
              showErrorBorder={password && !score}
              tabIndex={4}
              errorMessage={
                password && (
                  <PasswordValidator
                    {...{
                      score,
                      validators,
                    }}
                  />
                )
              }
            />
          </Col>
        </Row>
        {hideOptIn && (
          <Row middle="md">
            <Col md={12} xs={12}>
              <Checkbox
                label={intl.formatMessage(messages.emailBox)}
                name="marketing_email_opt_in"
                handleCheckboxChange={this.handleCheckboxChange}
              />
            </Col>
          </Row>
        )}
        {this.getSubmitButton()}
      </form>
    );
  }
}

export const ExternalLinks = injectIntl((props) => {
  return (
    <div className="links__container">
      <span>
        <FormattedMessage {...messages.haveStubHubAccount} />
      </span>
      <LoginLink
        to={{
          pathname: '/login',
          search: props.search,
        }}
        className="auth-link signin"
        onClick={props.onSignInClick}
      >
        <FormattedMessage {...messages.signIn} />
      </LoginLink>
    </div>
  );
});

export const PageHeader = ({intl}) => (
  <LoginHeader title={intl.formatMessage(messages.title)}>
    <FormattedMessage {...messages.title} />
  </LoginHeader>
);

export const SignUpLayout = (SignUpForm) => {
  class WrappedComponent extends Component {
    static contextTypes = {
      cookies: PropTypes.object,
    };
    static propTypes = {
      socialButtonEnabled: PropTypes.bool,
      location: PropTypes.object,
      renderSocialLogin: PropTypes.func,
    };
    static defaultProps = {
      socialButtonEnabled: true,
    };

    onSignInClick = () => {
      this.props.onSignInClick();
    };

    UNSAFE_componentWillMount() {
      /* istanbul ignore else */
      if (__CLIENT__ && window.sessionStorage) {
        let fbCredentials = sessionStorage.getItem('session_fbCredentials') || '{}';
        fbCredentials = JSON.parse(fbCredentials);
        this.userData = fbCredentials;
      }
    }

    render() {
      return (
        <Row
          className={cx('central-login-signup__layout', {
            'central-login-signup__layout--socialEnabled': !this.props.socialButtonEnabled,
          })}
        >
          <Col lg={12}>
            <section className="signup__container">
              <div className="signup__form-wrapper">
                <PageHeader intl={this.props.intl} />
                <ExternalLinks intl={this.props.intl} onSignInClick={this.onSignInClick} search={location.search} />
                <SignUpForm {...this.props} {...this.userData} cookies={this.context.cookies} />
                {this.props.socialButtonEnabled && this.props.renderSocialLogin()}
                <LegalTerms isSignUp />
              </div>
            </section>
          </Col>
        </Row>
      );
    }
  }
  SignUpLayout.displayName = `SignUpLayout(${SignUpForm.displayName || SignUpForm.name || 'SignUpPageComponent'}`;

  return injectIntl(WrappedComponent);
};

export const ConnectedSignUpForm = connect(controller)(SignUpForm);
export default SignUpLayout(ConnectedSignUpForm);
