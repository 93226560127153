export const LOGIN_EVENTS = {
  MAIN: 'login',
  LOADED: 'login.loaded',
  SUCCESS: 'login.success',
};

export const SIGNUP_EVENTS = {
  MAIN: 'sign_up',
  LOADED: 'sign_up.loaded',
  SUCCESS: 'sign_up.success',
  SOCIAL: 'sign_up.success.social',
};
