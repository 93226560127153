import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ErrorPanel from '@stubhub/react-error-panel';

import LoginHeader from '../react-ui-components/login-header';

import messages from './messages';

import './styles.scss';

const TooManyAttempts = () => {
  const intl = useIntl();

  return (
    <div>
      <LoginHeader title={intl.formatMessage(messages.title)} />
      <ErrorPanel
        className="too-many-attempts"
        header={<FormattedMessage {...messages.title} />}
        subheader={<FormattedMessage {...messages.subheader} />}
        paragraph={<FormattedMessage {...messages.message} />}
      />
    </div>
  );
};

export default TooManyAttempts;
