/* istanbul ignore file : bullet convention file */
/**
 * This is a Bullet App convention file.
 * You can modify at below `TODO` spots to add more app routes
 *
 *
 * Please only export a default method, e.g. called `getRoutes`,
 * which returns an array of `Route` (from react-router-dom v4.x)
 */
import appRoutes from './apps/routes';
// TODO: Import other apps routes if other apps have been installed as npm module

export default function getRoutes({currentLocale, defaultLocale}) {
  return [
    ...appRoutes({currentLocale, defaultLocale}),
    // TODO: Add other app routes here
  ];
}
