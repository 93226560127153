import {HTTP_CODES} from '@stubhub/general-utils';
import {publish} from '@stubhub/pubsub-utils';
import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';
import uuid from '@stubhub/visitor-id';

import {SIGNUP_EVENTS} from '../../apps/events-enum';
import {isMarketingOptInFFEnabledSelector} from '../../store/gsConfig/selectors';
import {postLoginSuccess, SOCIAL_RESET_ALL_ACTION, switchLoginTypeAction} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

const SOCIAL_SIGN_UP_START = 'SOCIAL_SIGN_UP_START';
const SOCIAL_SIGN_UP_SUCCESS = 'SOCIAL_SIGN_UP_SUCCESS';
const SOCIAL_SIGN_UP_FAIL = 'SOCIAL_SIGN_UP_FAIL';
const SOCIAL_SIGN_UP_SET_EMAIL = 'SOCIAL_SIGN_UP_SET_EMAIL';

const _getRequestHeaders = (lang) => {
  const ret = {};
  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

export const processSocialSignUpLogin = ({
  idpType,
  idpToken,
  idpUserId,
  firstName,
  lastName,
  email,
  redirect,
  marketingEmailOptIn,
}) => {
  return async (dispatch, getState, {cookies}) => {
    const {lang, gsConfig, login = {}} = getState();
    const {accertifyData = {}} = login;
    const {accertifyRefId = uuid()} = accertifyData;
    const shstoreId = (gsConfig || {}).shstoreId || 1;
    dispatch(signUpLoginClicked());
    const MILLISECONDS_OF_TIMEOUT = 30000;
    try {
      const resBody = await post({
        host: process.env.REACT_APP_API_HOST,
        path: `/social/signup/${idpType}`,
        json: true,
        headers: _getRequestHeaders(lang),
        timeout: MILLISECONDS_OF_TIMEOUT,
        body: {
          idpToken,
          idpUserId,
          email,
          firstName,
          lastName,
          shstore: shstoreId.toString(),
          accertifyRefId,
        },
      });
      postLoginSuccess(cookies, resBody);
      publish(SIGNUP_EVENTS.SUCCESS, {
        redirect,
        method: idpType,
        userEmail: email,
        userFirstName: firstName,
        userLastName: lastName,
        marketingEmailOptIn,
      });
      dispatch(signUpLoginSuccess());
      dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
      dispatch({type: SOCIAL_RESET_ALL_ACTION});
    } catch (e) {
      const errorBody = e.body;
      if (e.status === HTTP_CODES.CONFLICT && errorBody && errorBody.error === 'EMAIL_CONFLICT') {
        dispatch({type: SOCIAL_SIGN_UP_SET_EMAIL, signUpEmail: email});
        dispatch(switchLoginTypeAction(LOGIN_TYPE.BIND_SOCIAL));
      } else {
        dispatch(propagateError('generic_error'));
      }
    }
  };
};

const signUpLoginClicked = () => {
  return {
    type: SOCIAL_SIGN_UP_START,
    submitting: true,
  };
};
const signUpLoginSuccess = () => {
  return {
    type: SOCIAL_SIGN_UP_SUCCESS,
    submitting: false,
  };
};

const propagateError = (errorMsgKey) => {
  return {
    type: SOCIAL_SIGN_UP_FAIL,
    errorMsgKey,
    submitting: false,
    showError: true,
  };
};

const NAMESPACE = 'login_social_signup';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const {login_social = {}} = state;
    const {socialData = {}} = login_social;
    const {submitting, showError, errorMsgKey, signUpEmail} = this.getLocalState(state);

    return {
      submitting,
      showError,
      errorMsgKey,
      socialData,
      signUpEmail,
      uncheckMarketingOptIn: isMarketingOptInFFEnabledSelector(state),
    };
  },
  actionCreators: {
    processSocialSignUpLogin,
  },
  reducers: [SOCIAL_SIGN_UP_START, SOCIAL_SIGN_UP_SUCCESS, SOCIAL_SIGN_UP_FAIL, SOCIAL_SIGN_UP_SET_EMAIL],
});

controller.addResetReducer([SOCIAL_RESET_ALL_ACTION]);

export default controller;
