import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class RadioButton extends Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  static defaultProps = {
    name: 'input-radio',
    label: '',
  };

  toggleRadioChange = (evt) => {
    /* istanbul ignore else */
    if (this.props.handleChange && typeof this.props.handleChange === 'function') {
      this.props.handleChange(this.props, evt);
    }
  };

  render() {
    const {name, label, defaultChecked = false, children} = this.props;

    return (
      <div className="L-input-radio__container">
        <label className="input-radio__label">
          <input defaultChecked={defaultChecked} type="radio" name={name} onChange={this.toggleRadioChange} />
          <div>
            <span>{children || label}</span>
          </div>
        </label>
      </div>
    );
  }
}

export default RadioButton;
