import React from 'react';
import PropTypes from 'prop-types';

import './Grid.scss';

const rowKeys = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'around', 'between'];

const propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  reverse: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  className: PropTypes.string,
  tagName: PropTypes.string,
};

function createProps(propTypes, props, classNames) {
  const newProps = {};

  Object.keys(props)
    .filter((key) => key === 'children' || !propTypes[key])
    .forEach((key) => (newProps[key] = props[key]));

  const className = classNames.filter((cn) => cn).join(' ');

  return {...newProps, className};
}

function getRowClassNames(props) {
  const modificators = [props.className, 'L-uikit-layout-row'];

  for (let i = 0; i < rowKeys.length; ++i) {
    const key = rowKeys[i];
    const value = props[key];
    if (value) {
      modificators.push(`${key}-${value}`);
    }
  }

  if (props.reverse) {
    modificators.push('reverse');
  }

  return modificators;
}

export function getRowProps(props) {
  return createProps(propTypes, props, getRowClassNames(props));
}

export default function Row(props) {
  return React.createElement(props.tagName || 'div', getRowProps(props));
}

Row.propTypes = propTypes;
