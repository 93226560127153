import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import {connect} from '@stubhub/react-store-provider';

import {InputWithError} from '../react-ui-components/input';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import PasswordValidator, {ConfirmPwdValidator} from '../react-ui-components/password-validator';
import validate, {password_validator} from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';

import controller from './controller';
import messages from './messages';

import './index.scss';

export class ResetPassword extends Component {
  state = {
    password: '',
    confirmPwd: '',
    backendError: false,
    showPasswordError: false,
    showConfirmPwdError: false,
    email: '',
  };

  static propTypes = {
    // Props injected by RemoveParams
    token: PropTypes.string, // Required, this the token in the reset password link
    // Props injected by associated controller
    submitting: PropTypes.bool,
    signInSuccess: PropTypes.bool,
    resetPassword: PropTypes.func,
    changePassword: PropTypes.func,
    reset: PropTypes.func,
    closeResetPassword: PropTypes.func,
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    let showPasswordError = false;
    let showConfirmPwdError = false;

    /* istanbul ignore else */
    if (field === 'password') {
      showPasswordError = validate(values)[field];
      /* istanbul ignore next */
      showConfirmPwdError = this.state.confirmPwd && this.state.confirmPwd !== value;
    } else if (field === 'confirmPwd') {
      showConfirmPwdError = value && value !== this.state.password;
    }

    this.setState({
      [field]: value,
      showPasswordError,
      showConfirmPwdError,
    });
  };

  getSubmitButton = () => {
    if (this.props.submitting) {
      return (
        <UIButton disabled type="submit">
          <FormattedMessage {...messages.resetPassword} />
        </UIButton>
      );
    }

    return (
      <UIButton
        disabled={
          !(this.state.password && this.state.confirmPwd) ||
          this.state.showPasswordError ||
          this.state.showConfirmPwdError
        }
        type="submit"
      >
        <FormattedMessage {...messages.resetPassword} />
      </UIButton>
    );
  };

  afterResetSuccess = (res) => {
    this.setState({email: res && res.email});
  };

  afterChangeSuccess = (username) => {
    this.setState({email: username});
  };

  afterFail = (error) => {
    let errorMessage = '';
    const {intl} = this.props;

    try {
      const {body} = error;

      /*
       * Error is defined when calling reset-pwd api
       * must parse error body when calling change-pwd api
       */
      const code = body.error ? body.error : JSON.parse(body).error;

      switch (code) {
        case 'INVALID_TOKEN':
        case 'INVALID_REQUEST_MISSING_TOKEN':
          errorMessage = intl.formatMessage(messages.invalidToken);
          break;
        case 'INVALID_PASSWORD_LENGTH':
        case 'WEAK_CHARACTER_COMBINATION':
          errorMessage = intl.formatMessage(messages.invalidPassword);
          break;
        case 'SAME_OLD_AND_NEW_PASSWORD':
          errorMessage = intl.formatMessage(messages.usedpwd);
          break;
        case 'USED_PASSWORD':
          errorMessage = intl.formatMessage(messages.usedpwd);
          break;
        default:
          errorMessage = intl.formatMessage(messages.genericError);
      }
    } catch (e) {
      errorMessage = intl.formatMessage(messages.genericError);
    }

    this.setState({
      backendError: errorMessage,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {resetPassword, changePassword, token, username, isAccountCompromised, forceResetData} = this.props;
    const newState = {
      backendError: '',
      showPasswordError: false,
      showConfirmPwdError: false,
    };
    this.setState(newState);
    /* istanbul ignore else */

    if (isAccountCompromised) {
      const {guid, otpId, otpCode} = forceResetData;
      changePassword &&
        changePassword(username, guid, otpId, otpCode, this.state.password, this.afterChangeSuccess, this.afterFail);
    } else {
      resetPassword && resetPassword(token, this.state.password, this.afterResetSuccess, this.afterFail);
    }
  };

  render() {
    const {intl, username, signInSuccess, closeResetPassword, lang} = this.props;
    const {password, confirmPwd, backendError, showConfirmPwdError} = this.state;
    const {score, validators} = password_validator(password);
    if (signInSuccess) {
      return (
        <ResetPasswordSuccess
          {...{
            intl,
            closeResetPassword,
            responseEmail: this.state.email,
          }}
        />
      );
    }

    return (
      <div className="reset-password__container">
        <LoginHeader title={intl.formatMessage(messages.resetPassword)}>
          <FormattedMessage {...messages.resetPassword} />
        </LoginHeader>
        <div className="reset-password__wrapper">
          {backendError && <LoginAlert>{backendError}</LoginAlert>}
          {username && lang === 'en' && (
            <div className="reset-password__hint">
              <FormattedMessage {...messages.resetPasswordFor} values={{email: <b>{username}</b>}} />
            </div>
          )}
          <form name="resetpassword-form" className="reset-password__form" onSubmit={this.onSubmit}>
            <div className="reset-password__form__resetpassword">
              <InputWithError
                label={intl.formatMessage(messages.passwordPlaceHolder)}
                id="new-password"
                name="password"
                type="password"
                autoComplete="password"
                autoCapitalize="off"
                onChange={this.handleOnChange('password')}
                value={password}
                isPassword
                showErrorBorder={password && !score}
                errorMessage={
                  password && (
                    <PasswordValidator
                      {...{
                        score,
                        validators,
                      }}
                    />
                  )
                }
              />
              <InputWithError
                label={intl.formatMessage(messages.repeatPwdPlaceHolder)}
                id="confirm-password"
                name="confirmPwd"
                type="password"
                autoComplete="password"
                autoCapitalize="off"
                onChange={this.handleOnChange('confirmPwd')}
                value={confirmPwd}
                isPassword
                showErrorBorder={showConfirmPwdError}
                errorMessage={showConfirmPwdError && <ConfirmPwdValidator />}
              />
              {this.getSubmitButton()}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const ResetPasswordSuccess = ({intl, responseEmail, closeResetPassword}) => {
  return (
    <div className="reset-password__container">
      <LoginHeader title={intl.formatMessage(messages.resetPasswordSuccessTitle)}>
        <FormattedMessage {...messages.resetPasswordSuccessTitle} />
      </LoginHeader>
      <div className="reset-password__wrapper">
        <section className="reset-password__success">
          <div className="hint">
            <FormattedMessage {...messages.resetPasswordSuccessHint} values={{email: <b>{responseEmail}</b>}} />
          </div>
          <UIButton type="button" onClick={closeResetPassword}>
            <FormattedMessage {...messages.ok} />
          </UIButton>
        </section>
      </div>
    </div>
  );
};

export default connect(controller)(injectIntl(ResetPassword));
