import {defineMessages} from 'react-intl';

const messages = defineMessages({
  resetPassword: {
    id: 'CentralLogin.resetPassword.heading',
    defaultMessage: 'Reset password',
  },
  resetPasswordFor: {
    id: 'CentralLogin.resetPassword.hint',
    defaultMessage: 'Reset the password for {email}.',
  },
  passwordPlaceHolder: {
    id: 'CentralLogin.resetPassword.passwordPlaceHolder',
    defaultMessage: 'New Password',
  },
  repeatPwdPlaceHolder: {
    id: 'CentralLogin.resetPassword.repeatPwdPlaceHolder',
    defaultMessage: 'Re-enter Password',
  },
  invalidToken: {
    id: 'CentralLogin.resetPassword.invalidToken',
    defaultMessage: 'This link to reset password has been expired. Please reset again.',
  },
  invalidPassword: {
    id: 'CentralLogin.resetPassword.invalidPwd',
    defaultMessage:
      'Oops! Your password needs to be 8-20 characters; a combo of numbers, letters, and/or special characters; and cannot be your email address.',
  },
  invalidRepeatPassword: {
    id: 'CentralLogin.resetPassword.confirmPwd',
    defaultMessage: 'Password doesn’t match',
  },
  usedpwd: {
    id: 'CentralLogin.resetPassword.usedpwd',
    defaultMessage: 'For your security, you cannot re-use a previous password.',
  },
  resetPasswordSuccessTitle: {
    id: 'CentralLogin.resetPassword.successTitle',
    defaultMessage: 'Password reset successful',
  },
  resetPasswordSuccessHint: {
    id: 'CentralLogin.resetPassword.successHint',
    defaultMessage: 'The password for {email} has been reset sucessfully.',
  },
  ok: {
    id: 'CentralLogin.resetPassword.ok',
    defaultMessage: 'OK',
  },
  genericError: {
    id: 'CentralLogin.resetPassword.genericError',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
});

export default messages;
