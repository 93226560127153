import {defineMessages} from 'react-intl';

const connectSocialMessages = defineMessages({
  emailPlaceholder: {
    id: 'Login.connectSocial.emailPlaceholder',
    defaultMessage: 'Current StubHub email',
  },
  passwordPlaceholder: {
    id: 'Login.connectSocial.passwordPlaceholder',
    defaultMessage: 'StubHub password',
  },
  registerLogan: {
    id: 'Login.connectSocial.registerLogan',
    defaultMessage: 'New to StubHub?',
  },
  connectSocialTimeout: {
    id: 'Login.connectSocial.error.connectSocialTimeout',
    defaultMessage: 'Oopse, the request took too long. Please try again.',
  },
  connectSocialGenericError: {
    id: 'Login.connectSocial.error.genericerror',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
  maxattemptsexceeds: {
    id: 'Login.connectSocial.error.maxattemptsexceeds',
    defaultMessage:
      'Your account has been temporarily locked due to multiple failed login attempts. Please try again later.',
  },
  connectSocialWith403: {
    id: 'Login.connectSocial.error.403',
    defaultMessage: 'Enter a valid email and password combo. Need help?',
  },
  socialLoginAccountExists: {
    id: 'Login.connectSocial.error.accountExists',
    defaultMessage: 'Looks like your email address is already registered with StubHub.',
  },
  emptyEmailInput: {
    id: 'Login.connectSocial.error.emptyEmail',
    defaultMessage: 'Oops! We need an email address.',
  },
  connectTitle: {
    id: 'CentralLogin.social.connect.title',
    defaultMessage: 'Connect StubHub to {socialType}',
  },
  loginConnectForgotPassword: {
    id: 'CentralLogin.connectSocial.forgotPassword',
    defaultMessage: 'Forgot your password?',
    description: 'Forgot password link text',
  },
  loadingTextLabel: {
    id: 'CentralLogin.connectSocial.loadingText',
    defaultMessage: 'One moment...',
    description: 'Link accounts button loading text',
  },
  linkAccountTextLabel: {
    id: 'CentralLogin.social.connect.linkAccount',
    defaultMessage: 'Connect',
    description: 'Link accounts button text',
  },
  subNote: {
    id: 'CentralLogin.social.connect.subNote',
    defaultMessage:
      'Looks like you already have a Stubhub account. Please enter the password for {email} to connect to your {socialType} profile.',
    description: 'Connect social panel sub note',
  },
  signUp: {
    id: 'CentralLogin.connectSocial.signUp',
    defaultMessage: 'Create account',
    description: 'Create account link text',
  },

});

export default connectSocialMessages;
