import {HTTP_CODES, TIME} from '@stubhub/general-utils';
import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';

import {
  processPasswordLoginWith2FA,
  SOCIAL_RESET_ALL_ACTION,
  switchLoginTypeAction,
  _getRequestHeaders,
} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

const CONNECT_SOCIAL_SUCCESS = 'CONNECT_SOCIAL_SUCCESS';
const SOCIAL_LOGIN_FLOW_TYPE_CHANGED = 'SOCIAL_LOGIN_FLOW_TYPE_CHANGED';
const USER_CONTACT_INFO_SAVED = 'USER_CONTACT_INFO_SAVED';

const BIND_SOCIAL_FAIL = 'BIND_SOCIAL_FAIL';
const BIND_SOCIAL_START = 'BIND_SOCIAL_START';

const beforeSocialBindLogin = () => {
  return (dispatch) => {
    dispatch({type: BIND_SOCIAL_START, hasAPIError: false});
  };
};

export const connectSocial = async ({idpType, getState, idpUserId, idpToken}) => {
  await post({
    host: process.env.REACT_APP_API_HOST,
    path: `/social/bind/${idpType}`,
    json: true,
    headers: _getRequestHeaders(getState()),
    timeout: TIME.MILLIS_OF_SECOND * TIME.SECONDS_OF_MIN,
    body: {
      idpUserId,
      idpToken,
    },
  });
};

const processSocialBindLogin = (
  {idpType, idpUserId, idpToken, username, password, source},
  funcAfterSuccess,
  funcAfterFail
) => {
  return processPasswordLoginWith2FA(
    {username, password, source},
    async (dispatch, getState) => {
      try {
        connectSocial({idpType, getState, idpUserId, idpToken});
      } catch (e) {
        // TODO specify error
        dispatch(propagateError('connectSocialGenericError'));
        await funcAfterFail();
      }
      dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
      await funcAfterSuccess();
      dispatch({type: SOCIAL_RESET_ALL_ACTION});
    },
    async (dispatch, getState, error) => {
      // TODO specify error
      const errorBody = error.body;
      if (error.status === HTTP_CODES.FORBIDDEN && errorBody && errorBody.message === 'EXCEED_LIMIT') {
        dispatch(propagateError('maxattemptsexceeds'));
      } else if (error.status === HTTP_CODES.BAD_REQUEST) {
        dispatch(propagateError('connectSocialWith403'));
      } else {
        dispatch(propagateError('connectSocialGenericError'));
      }
      await funcAfterFail();
    },
    {idpType, idpUserId, idpToken}
  );
};

const propagateError = (apiErrorMsgKey) => {
  return {
    type: BIND_SOCIAL_FAIL,
    apiErrorMsgKey,
    hasAPIError: true,
  };
};

const NAMESPACE = 'login_connect_social';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const {login_social = {}, login_social_signup = {}} = state;
    const {socialData = {}} = login_social;
    const {signUpEmail} = login_social_signup;
    const localState = this.getLocalState(state);

    return {
      apiErrorMsgKey: localState.apiErrorMsgKey,
      hasAPIError: localState.hasAPIError,
      socialData,
      signUpEmail,
    };
  },
  actionCreators: {
    beforeSocialBindLogin,
    processSocialBindLogin,
  },
  // TODO delete useless below
  reducers: [
    CONNECT_SOCIAL_SUCCESS,
    SOCIAL_LOGIN_FLOW_TYPE_CHANGED,
    USER_CONTACT_INFO_SAVED,

    BIND_SOCIAL_START,
    BIND_SOCIAL_FAIL,
  ],
});

controller.addResetReducer([SOCIAL_RESET_ALL_ACTION]);

export default controller;
