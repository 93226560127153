import {NONE_CURRENCY_VALUE} from '@stubhub/currency-utils';

/**
 * Sends a scren_view track with this data:
 *
 * dataLayer.push({
 *  'event': 'screen_view', // a default GA4 event
 *  'screen_name': 'home', // a literal
 *  'store_id': '', // the SH Store ID as string (for compatibility with OB & TB)
 *  'store_country': '', // the two-letter ISO code of the country, such as 'ES'
 *  'visit_platform': '', // one of 'sh-web', 'sh-ios', 'sh-android'
 *  'visit_locale': '', // the locale of the current pageview, such as 'es-ES'
 *  'is_advisory_currency_enabled': false, // whether the advisory currency is enabled
 *  'advisoryCurrency': '', // one of 'OFF', 'NONE', 'EUR', 'USD', etc.
 *  'userGUID': '', // the current user's GUID if logged-in, null otherwise
 *  'redirect': '', // the URL of the page that was just loaded
 *});
 *
 * @param {object} track the track object with at least screen_view method
 * @param {object} data the data sent to track
 */
const pageView = (
  track,
  {screenName, storeId, storeCountry, locale, isAdvisoryCurrencyEnabled, advisoryCurrency, userGUID, redirect} = {}
) => {
  track.screen_view({
    screen_name: screenName,
    store_id: storeId?.toString() || null,
    store_country: storeCountry || null,
    visit_platform: 'sh-web',
    visit_locale: locale || null,
    visit_advisory_currency: isAdvisoryCurrencyEnabled ? advisoryCurrency || NONE_CURRENCY_VALUE : 'OFF',
    visit_guid: userGUID || null,
    redirect: redirect || null,
  });
};

const loginSuccess = (track, {redirect, method, userGUID} = {}) => {
  track.login({
    event: 'login',
    redirect,
    method,
    visit_guid: userGUID,
  });
};

const signUpSuccess = (
  track,
  {redirect, method, userGUID, userEmail, userFirstName, userLastName, marketingComms} = {}
) => {
  track.sign_up({
    event: 'sign_up',
    redirect,
    method,
    visit_guid: userGUID,
    visit_email: userEmail,
    visit_first_name: userFirstName,
    visit_last_name: userLastName,
    marketing_comms: marketingComms,
  });
};

export {pageView, loginSuccess, signUpSuccess};
