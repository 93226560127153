import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import {FooterLinks} from '../../util/helper';
import LoginLink from '../react-login-link';
import {InputWithError} from '../react-ui-components/input';
import InputError from '../react-ui-components/input-error';
import LoginHeader from '../react-ui-components/login-header';
import validate from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';

import controller, {FLOW} from './controller';
import messages from './messages';

import './index.scss';
// Const FooterLinks = require('@stubhub/react-footer-new/lib/footer-links-data').FooterLinks;

@injectIntl
export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.userEmail || '',
      showError: false,
      errors: {},
    };
  }

  static contextTypes = {
    globalRegistry: PropTypes.object,
    track: PropTypes.object,
  };

  trackingOptions(appInteraction) {
    const {track} = this.context;
    const appInteractionType = 'NE: Login Component';
    const s = __CLIENT__ && window.s;

    if (!s) {
      return;
    }

    const trackingObj = {
      appInteraction: `${appInteractionType}: ${appInteraction}`,
      appInteractionType,
      userStatus: s.eVar69,
      userGUID: s.eVar66,
      socialSiteInteraction: s.eVar25,
      login_type: s.eVar105,
    };

    if (track) {
      track.click(trackingObj.appInteractionType, trackingObj);
    }

    return trackingObj;
  }

  forgotPassword = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.trackingOptions('Reset Password: Submit');

    if (this.state.showError) {
      return false;
    }

    this.props.forgotPassword(this.state.email, this.trackingOptions.bind(this));
  };
  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    const errors = {...this.state.errors, ...validate(values)};
    const {handleOnChange} = this.props;

    handleOnChange && handleOnChange(field, value);
    this.setState({
      [field]: value,
      errors,
      showError: Object.values(errors).filter((it) => it).length > 0,
    });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {onSignInClick, intl, flow, location} = this.props;
    const {globalRegistry} = this.context;
    const {email, showError} = this.state;
    const btnDisabled = this.state.email === '' || flow === FLOW.STARTED;
    const submitBtnText = flow === FLOW.STARTED ? 'loading' : 'sendEmail';
    const links = FooterLinks(globalRegistry.gsConfig.shstoreId, globalRegistry.gsConfig.currentLocale);

    return (
      <section className="forgot-password__container">
        {flow !== FLOW.FORGOT_PASSWORD && (
          <>
            <LoginHeader title={intl.formatMessage(messages.title)}>
              <FormattedMessage {...messages.title} />
            </LoginHeader>
            <div className="forgot-password__form-container">
              <div className="hint">
                <FormattedMessage {...messages.hint} />
              </div>
              <form name="forgot-password-form" onSubmit={this.forgotPassword}>
                <InputWithError
                  label={intl.formatMessage(messages.email)}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoCapitalize="off"
                  onChange={this.handleOnChange('email')}
                  onBlur={this.handleBlur}
                  value={email}
                  errorMessage={
                    showError && (
                      <InputError
                        {...{
                          errorMessage: intl.formatMessage(messages.invalidEmail),
                        }}
                      />
                    )
                  }
                />
                <UIButton className="central-login__email-btn" disabled={btnDisabled} type="submit">
                  <FormattedMessage {...messages[submitBtnText]} />
                </UIButton>
              </form>
            </div>
            <div className="links__container">
              <LoginLink
                to={{
                  pathname: '/login',
                  search: location.search,
                }}
                className="auth-link signin"
                onClick={onSignInClick}
              >
                <FormattedMessage {...messages.signin} />
              </LoginLink>
            </div>
          </>
        )}
        {flow === FLOW.FORGOT_PASSWORD && (
          <ForgotPasswordSuccess
            reset={this.props.reset}
            search={this.props.search}
            email={email}
            onLoginClose={(event) => this.forgotPassword(event)}
            helpLinks={links.help.help.href}
            headerTitle={intl.formatMessage(messages.title)}
          />
        )}
      </section>
    );
  }
}

const ForgotPasswordSuccess = ({onLoginClose, email, search, reset, helpLinks, headerTitle}) => {
  return (
    <>
      <div>
        <LoginHeader title={headerTitle}>
          <FormattedMessage {...messages.title} />
        </LoginHeader>
        <div className="forgot-password__form-container success">
          <div className="note">
            <FormattedMessage {...messages.note1} values={{email: <b>{email}</b>}} />
          </div>
          <div className="note">
            <FormattedMessage {...messages.note2} />
          </div>
          <UIButton secondary onClick={onLoginClose}>
            <FormattedMessage {...messages.ok} />
          </UIButton>
        </div>
      </div>
      <div className="links__container">
        <div className="instruction">
          <FormattedMessage {...messages.instruction} />
        </div>
        <div>
          <LoginLink
            to={{
              pathname: '/login/forgot-password',
              search,
            }}
            onClick={reset}
            className="auth-link"
          >
            <FormattedMessage {...messages.retry} />
          </LoginLink>
          {' | '}
          <FormattedLink href={helpLinks} className="auth-link">
            <FormattedMessage {...messages.contact} />
          </FormattedLink>
        </div>
      </div>
    </>
  );
};

export default connect(controller)(injectIntl(ForgotPassword));
