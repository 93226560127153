import {HTTP_CODES, TIME} from '@stubhub/general-utils';
import {Storage} from '@stubhub/legacy-cache/lib';
import {get, post} from '@stubhub/rest-method';
import {getDomainName, parseUrl} from '@stubhub/sh-url-utils';
import uuid from '@stubhub/visitor-id';

import {LOGIN_TOPIC, LOGIN_TYPE} from '../modules/react-login/controller';

const PERSISTENT_COOKIE_NAME_LIST = ['session_CSRFtoken', 'session_userGUID', 'track_session_userGUID'];

export const setCookiesData = (cookies, name, value) => {
  const loginSessionPersistent = PERSISTENT_COOKIE_NAME_LIST.some((item) => name === item);
  const domain = __CLIENT__ && getDomainName(window.location.href);
  const protocol = __CLIENT__ && parseUrl(window.location.href).protocol;

  if (name === 'session_CSRFtoken') {
    Storage.setInCookie(name, value, !loginSessionPersistent, undefined, domain, '/', protocol === 'https:');
  } else {
    cookies.set(name, value, {
      domain,
      path: '/',
      secure: protocol === 'https:',
      expires: loginSessionPersistent ? TIME.DAYS_OF_YEAR : undefined,
    });
  }
};

/**
 * @param loginType only for foobunny, use stubhub as default, because foobunny can not handle 'apple'
 */
export const postLoginSuccess = (cookies, {guid, sessionId, csrfToken}, loginType = 'stubhub') => {
  if (guid) {
    setCookiesData(cookies, 'session_userGUID', guid);
    setCookiesData(cookies, 'track_session_userGUID', guid);
    setCookiesData(cookies, 'session_uAuthenticated', 1);
  }
  if (csrfToken) {
    setCookiesData(cookies, 'session_CSRFtoken', csrfToken);
  }
  setCookiesData(cookies, 'session_login_type', loginType);
  setCookiesData(cookies, 'session_sessionId', sessionId);
  setCookiesData(cookies, 'session_loginStatus', true);
};

export const switchLoginTypeAction = (loginType) => {
  return {type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType};
};

/**
 * Cache two-fa data from sign in API into the LOGIN redux namespace used in two-fa flow later
 * @param {Object} customerContact - an object contains phone list and email
 * @param {string} auth_id - auth id used in send challenge and verify code API
 * @param {string} auth_status - auth status to indicate the two-fa auth status
 * @param {boolean} code_sent - indicate whether the PIN code has already been sent - KEY NOTE: in the new two-fa flow, FE will ignore this flag and always hard-code its value as false
 * @returns {Object} - redux action object
 */
export const cacheTwoFADataAction = (
  {customerContact, auth_id, auth_status, code_sent, is_compromised_credential, username, oldPassword},
  bindData = null
) => {
  return {
    type: LOGIN_TOPIC.CACHE_TWO_FA_DATA,
    twoFaData: {
      customerContact,
      auth_id,
      auth_status,
      code_sent,
      is_compromised_credential,
      username,
      oldPassword,
      bindData,
    },
  };
};

export const cacheForceResetDataAction = (forceResetData) => {
  return {
    type: LOGIN_TOPIC.CACHE_FORCE_RESET_DATA,
    forceResetData,
  };
};

export const extractSocialData = (socialType, socialData) => {
  switch (socialType.toLowerCase()) {
    case 'facebook':
      return {
        idpType: 'facebook',
        idpToken: socialData.accessToken,
        idpUserId: socialData.userID,
        email: socialData.email,
        firstName: socialData.first_name,
        lastName: socialData.last_name,
      };
    case 'apple':
      return {
        idpType: 'apple',
        idpToken: socialData.accessToken,
        idpUserId: socialData.userID,
        email: socialData.email,
        firstName: socialData.first_name,
        lastName: socialData.last_name,
        isPrivateEmail: socialData.isPrivateEmail,
      };
    case 'google':
      return {
        idpType: 'google',
        idpToken: socialData.tokenId,
        idpUserId: socialData.decoded?.sub,
        email: socialData.decoded?.email,
        firstName: socialData.decoded?.given_name,
        lastName: socialData.decoded?.family_name,
      };
    default:
      return {};
  }
};

const countryDestinationMapping = {
  GB: 'UK',
};
export const getDestination = (lang) => {
  const country = lang.split('-').pop();

  return countryDestinationMapping[country] || country;
};

const WEB_SUB_DOMAIN_REG_EXP = /^www\.(.*)$/;
export const _getRequestHeaders = (state) => {
  const ret = {};
  const {lang} = state;
  const w = __CLIENT__ && window;
  const hostname = w && w.location.hostname;
  const tld = state.gsConfig.defaultWebTLD;

  /* istanbul ignore else */
  if (hostname && !hostname.match(WEB_SUB_DOMAIN_REG_EXP)) {
    ret.tld = tld;
  }
  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

export const sendApiErrorLog = ({title, data}) => {
  const path = '/login/api-log';
  post({
    path,
    host: process.env.REACT_APP_API_HOST,
    json: true,
    body: {
      title,
      data,
    },
  });
};

/**
 * @param username
 * @param password
 * @param source
 * @param funcAfterLoginSuccess function like async (dispatch, getState, {cookies}) => {}
 * @param funcAfterLoginFail function like async (dispatch, getState, {cookies}, error) => {}
 * @returns {Function}
 */
export const processPasswordLoginWith2FA = (
  {username, password, staySignIn, source},
  funcAfterLoginSuccess = () => {
    // Do nothing.
  },
  funcAfterLoginFail = () => {
    // Do nothing.
  },
  bindData = null
) => {
  return async (dispatch, getState, {cookies}) => {
    let userGuid;
    const state = getState();
    const {lang, login = {}} = state;
    const {accertifyData = {}} = login;
    const {accertifyRefId = uuid()} = accertifyData;
    const destination = getDestination(lang);
    const MILLISECONDS_OF_TIMEOUT = 30000;

    try {
      sendApiErrorLog({title: 'AccertifyLog', data: accertifyData});
    } catch {
      // Console.log('post api log failed');
    }

    try {
      const loginResponse = await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/login/signin',
        json: true,
        headers: _getRequestHeaders(state),
        body: {
          loginData: {username, password, staySignIn, accertifyRefId, source},
          uaData: {
            customer: {
              acceptedAgreement: {destination},
            },
          },
        },
        timeout: MILLISECONDS_OF_TIMEOUT,
      });
      userGuid = loginResponse.guid;
      const {csrfToken} = loginResponse;
      const {sessionId} = loginResponse;
      postLoginSuccess(cookies, {guid: userGuid, sessionId, csrfToken});
      (await funcAfterLoginSuccess) && funcAfterLoginSuccess(dispatch, getState, {cookies});
    } catch (e) {
      const errorBody = e.body;
      if (e.status === HTTP_CODES.TOO_MANY_REQUESTS) {
        dispatch({type: LOGIN_TOPIC.SET_LOGIN_TYPE, loginType: LOGIN_TYPE.TOO_MANY_ATTEMPTS});
      }

      if (e.status === HTTP_CODES.UNAUTHORIZED && errorBody && errorBody.auth_status === 'MFA_NEED_CHALLENGE') {
        errorBody.username = username;
        bindData ? dispatch(cacheTwoFADataAction(errorBody, bindData)) : dispatch(cacheTwoFADataAction(errorBody));
        dispatch(switchLoginTypeAction(LOGIN_TYPE.TWO_FA_SEND_CODE));
      } else {
        (await funcAfterLoginFail) && funcAfterLoginFail(dispatch, getState, {cookies}, e);
      }
    }
  };
};

/**
 * @param guid
 * @param dispatch
 * @param getState
 * @param cookies
 * @param funcAfterContactSuccess function like async (dispatch, getState, {cookies}) => {}
 * @param funcAfterContactFail function like async (dispatch, getState, {cookies}, error) => {}
 * @returns {Promise<void>}
 */
export const processGetUserContactV2 = async (
  guid,
  {dispatch, getState, cookies},
  funcAfterContactSuccess = () => {
    // Do nothing.
  },
  funcAfterContactFail = () => {
    // Do nothing.
  }
) => {
  try {
    const userData = await get({
      host: process.env.REACT_APP_API_HOST,
      path: `/login/user/customers/v1/${guid}/contactsV2`,
      json: true,
      headers: _getRequestHeaders(getState()),
    });
    const contacts = (userData.contactsV2 && userData.contactsV2.contactV2) || /* istanbul ignore next */ [];
    const primaryContact = contacts.find((obj) => obj.defaultInd === 'Y') || /* istanbul ignore next */ {};
    if (primaryContact && primaryContact.contactGuid) {
      setCookiesData(cookies, 'session_contactGUID', primaryContact.contactGuid);
      dispatch({type: LOGIN_TOPIC.SET_PRIMARY_CONTACT, primaryContact});
    }
    (await funcAfterContactSuccess) && funcAfterContactSuccess(dispatch, getState, {cookies});
  } catch (e) {
    (await funcAfterContactFail) && funcAfterContactFail(dispatch, getState, {cookies}, e);
  }
};

// For resetting all three social component after login success
export const SOCIAL_RESET_ALL_ACTION = 'SOCIAL_RESET_ALL_ACTION';
