import {defineMessages} from 'react-intl';

const messages = defineMessages({
  facebookSignInText: {
    id: 'CentralLogin.Facebook.signInText',
    defaultMessage: 'Sign in with Facebook',
  },
  facebookSignUpText: {
    id: 'CentralLogin.Facebook.signUpText',
    defaultMessage: 'Sign up with Facebook',
  },
  facebookLoginProcessing: {
    id: 'CentralLogin.Facebook.isProcessing',
    defaultMessage: 'One moment...',
  },
  facebookLoginGenericError: {
    id: 'CentralLogin.social.error.genericerror',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
  facebookLoginNotValidError: {
    id: 'CentralLogin.social.error.notValidError',
    defaultMessage: 'Enter a valid email and password combo. Need help?',
  },
  socialLoginTimeout: {
    id: 'CentralLogin.social.error.socialLoginTimeout',
    defaultMessage: 'Oopse, the request took too long. Please try again.',
  },
  signupFaster: {
    id: 'CentralLogin.socialconnect.label',
    defaultMessage: 'Or sign up faster with',
  },
  signinFaster: {
    id: 'CentralLogin.socialconnect.signin.label',
    defaultMessage: 'Or sign in faster with',
  },
});

export default messages;
