import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {TIME} from '@stubhub/general-utils';
import {publish} from '@stubhub/pubsub-utils';
import ModalDialog from '@stubhub/react-modal-dialog';
import Spinner from '@stubhub/react-spinner';
import {connect} from '@stubhub/react-store-provider';
import uuid from '@stubhub/visitor-id';

import {LOGIN_EVENTS} from '../../apps/events-enum';
import {getRedirectUrl} from '../../util/helper';
import ConnectSocial from '../react-login-connect-social';
import CreatePasswordContainer from '../react-login-create-password';
import DecisionSocial from '../react-login-decision-social';
import ChangePassword from '../react-login-force-change-password';
import ForgotPasswordContainer from '../react-login-forgot-password';
import SignInContainer from '../react-login-signin';
import SignUp from '../react-login-signup';
import SocialSignUp from '../react-login-signup-social';
import SocialLogin from '../react-login-social-login/social-login-with-banner';
import TooManyAttempts from '../react-login-too-many-attempts';
import ReadToken from '../react-login-validate-token';
import TwoFA from '../react-twofa';

import controller, {LOGIN_TYPE} from './controller';

import './index.scss';

class Login extends Component {
  static defaultProps = {
    onLoginClose: () => {
      // Do nothing.
    },
    onLoginSuccess: () => {
      // Do nothing.
    },
    defaultLoginType: LOGIN_TYPE.SIGNIN,
  };

  static contextTypes = {
    track: PropTypes.object,
  };

  componentWillUnmount() {
    this.props.resetLoginType();
  }

  onLoginSuccess = () => {
    this.props.setLoginType(LOGIN_TYPE.LOGIN_SUCCESS);
  };

  onLoginClose = () => {
    this.props.setLoginType(LOGIN_TYPE.CLOSE);
  };

  onSignInClick = () => {
    this.props.setLoginType(LOGIN_TYPE.SIGNIN);
  };

  onSignUpClick = () => {
    this.props.setLoginType(LOGIN_TYPE.SIGNUP);
  };

  onForgotPasswordClick = (userEmail = '') => {
    this.userEmail = userEmail;
    this.props.setLoginType(LOGIN_TYPE.FORGOT_PASSWORD);
  };

  goToForceChangePassword = () => {
    this.props.setLoginType(LOGIN_TYPE.FORCE_CHANGE_PASSWORD);
  };

  goToResetPassword = () => {
    this.props.setLoginType(LOGIN_TYPE.RESET_PASSWORD);
  };

  onTwoFAExpired = () => {
    this.props.resetLoginType();
    this.props.setLoginType(LOGIN_TYPE.SIGNIN);
  };

  onForceChangeClose = () => {
    this.props.setLoginType(LOGIN_TYPE.LOGIN_SUCCESS);
  };

  onSocialLoginSuccess = (type) => {
    publish(LOGIN_EVENTS.SUCCESS, {redirect: getRedirectUrl(this.props.location), method: type});
  };

  renderSocialLogin = () => {
    const {location} = this.props;

    return (
      <SocialLogin onSignUpClick={this.onSignUpClick} onLoginSuccess={this.onSocialLoginSuccess} location={location} />
    );
  };

  renderSocialRegister = () => {
    return <SocialLogin loginType="signUp" onSignUpClick={this.onSignUpClick} onLoginSuccess={this.onLoginSuccess} />;
  };

  initAccertify = () => {
    const {cacheAccertifyData} = this.props;

    // Check enabled
    const ENV_VAR_TRUE_REGEX = /^true|1$/i;
    // Console.log("Accertify check enabled", process.env.REACT_APP_ACCERTIFY_ENABLED);
    if (!ENV_VAR_TRUE_REGEX.test(process.env.REACT_APP_ACCERTIFY_ENABLED) || !window) {
      return null;
    }

    // Configuration
    const collector_URL = process.env.REACT_APP_ACCERTIFY_COLLECTOR_URL || 'https://test.accdab.net/cdn/cs/';
    const client_id = process.env.REACT_APP_ACCERTIFY_CLIENT_ID || 'nMRq5-NShKkpkBOkM-3rSWH5dkU';
    const src = `${collector_URL}${client_id}.js`;

    const script = window?.document.querySelector(`[src^="${src}"]`);

    // Accertify Data Collector script
    !script &&
      (function () {
        let loadTimeCost;
        let sendTimeCost;
        let successRefId;
        const handleCacheAccertifyData = () => {
          cacheAccertifyData({accertifyRefId, loadTimeCost, sendTimeCost, successRefId});
        };

        const accertifyRefId = uuid();
        handleCacheAccertifyData();
        let start = performance.now();
        const s = document.createElement('script');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', src);
        s.setAttribute('dvci', accertifyRefId);
        s.setAttribute('dvct', '500');
        s.setAttribute('id', 'bcn');
        s.setAttribute('dvc', 'a');
        s.onload = function () {
          const callback = performance.now();
          const lag = (callback - start) / TIME.MILLIS_OF_SECOND;
          // eslint-disable-next-line no-console
          console.log(`Time for loaded ${lag} seconds`);
          start = callback;
          loadTimeCost = lag;
          handleCacheAccertifyData();
          if (window._bcn && window._bcn.dvc) {
            window._bcn.dvc.setSubmissionCallback(registeredCallBackMethod);
          }
        };
        document.head.appendChild(s);
        function registeredCallBackMethod() {
          const callback = performance.now();
          const lag = (callback - start) / TIME.MILLIS_OF_SECOND;
          // eslint-disable-next-line no-console
          console.log(`Time for callback ${lag} seconds`);
          sendTimeCost = lag;
          handleCacheAccertifyData();
          if (window._bcn && window._bcn.dvc) {
            successRefId = window._bcn.dvc.getTID();
            // eslint-disable-next-line no-console
            console.log(`Set the device transaction ID: ${successRefId}`, accertifyRefId);
            handleCacheAccertifyData();
          }
        }
      })();
  };

  componentDidMount() {
    const {/* location, */ setLoginType, defaultLoginType} = this.props;

    /*
     * Post({
     *   host: process.env.REACT_APP_API_HOST,
     *   path: '/login/session/validate',
     *   credentials: 'include'
     * }).then(() => {
     */

    /*
     *   Window.location.href = getRedirectUrl(location);
     *   return null;
     * }).catch((err) => {});
     */

    setLoginType(defaultLoginType);

    this.initAccertify();
  }

  render() {
    let Child = null;
    let props = {};
    const {
      loginType,
      connectSocialAccounts,
      onLoginClose,
      onLoginSuccess,
      render,
      onGuestClick,
      location,
      idpType,
      socialButtonEnabled,
    } = this.props;
    switch (loginType) {
      case LOGIN_TYPE.FORGOT_PASSWORD:
        Child = ForgotPasswordContainer;
        props = {
          onSignInClick: this.onSignInClick,
          userEmail: this.userEmail,
          location,
        };
        break;
      case LOGIN_TYPE.SIGNIN:
        Child = SignInContainer;
        props = {
          renderSocialLogin: this.renderSocialLogin,
          onSignUpClick: this.onSignUpClick,
          onForgotPasswordClick: this.onForgotPasswordClick,
          socialButtonEnabled,
          onGuestClick,
          location,
        };
        break;
      case LOGIN_TYPE.SIGN_UP_SOCIAL:
        Child = SocialSignUp;
        props = {
          onSignInClick: this.onSignInClick,
          socialButtonEnabled: false,
          afterSuccess: connectSocialAccounts,
          idpType,
          location,
        };
        break;
      case LOGIN_TYPE.SIGNUP:
        Child = SignUp;
        props = {
          onSignInClick: this.onSignInClick,
          renderSocialLogin: this.renderSocialRegister,
          socialButtonEnabled,
          location,
        };
        break;
      // TODO delete this
      case LOGIN_TYPE.DECISION_SOCIAL:
        Child = DecisionSocial;
        props = {
          goToConnectSocial: this.goToConnectSocial,
          goToRegisterSocial: this.goToRegisterSocial,
          goToForgotPassword: this.onForgotPasswordClick,
        };
        break;
      case LOGIN_TYPE.BIND_SOCIAL:
        Child = ConnectSocial;
        props = {
          goToSignUp: this.onSignUpClick,
          goToForgotPassword: this.onForgotPasswordClick,
          onLoginSuccess: this.onLoginSuccess,
        };
        break;
      case LOGIN_TYPE.RESET_PASSWORD:
        Child = ReadToken;
        props = {
          location,
        };
        break;
      case LOGIN_TYPE.TWO_FA_SEND_CODE:
        Child = TwoFA;
        props = {
          onTwoFAClose: this.onSignInClick,
          goToForceChangePassword: this.goToForceChangePassword,
          onTwoFAExpired: this.onTwoFAExpired,
          location,
        };
        break;
      case LOGIN_TYPE.FORCE_CHANGE_PASSWORD:
        Child = ChangePassword;
        props = {
          onResetClick: this.goToResetPassword,
          location,
        };
        break;
      case LOGIN_TYPE.CREATE_PASSWORD:
        Child = CreatePasswordContainer;
        props = {
          onResetClick: this.goToResetPassword,
          location,
        };
        break;
      case LOGIN_TYPE.LOGIN_SUCCESS:
        Child = null;
        onLoginSuccess();
        break;
      case LOGIN_TYPE.CLOSE:
        Child = null;
        onLoginClose();
        break;
      case LOGIN_TYPE.TOO_MANY_ATTEMPTS:
        Child = TooManyAttempts;
        break;
      default:
        Child = null;
        break;
    }

    /* istanbul ignore else */
    if (loginType === LOGIN_TYPE.LOGIN_SUCCESS) {
      // TODO Remove this when SSR
      if (!window) {
        return render();
      }

      window.location.href = getRedirectUrl(location);

      return null;
    }

    /* istanbul ignore else */
    if (!Child) {
      return <Spinner />;
    }

    return Child && loginType === LOGIN_TYPE.TWO_FA_SEND_CODE ? (
      <Child {...props} />
    ) : (
      <ModalDialog
        closable={Child && loginType === LOGIN_TYPE.FORCE_CHANGE_PASSWORD}
        responsive={false}
        scrollable
        className="central-login__container"
        onClose={Child && loginType === LOGIN_TYPE.FORCE_CHANGE_PASSWORD ? this.onForceChangeClose : this.onLoginClose}
      >
        <Child {...props} />
      </ModalDialog>
    );
  }
}

const LogoutComp = (props) => {
  props
    .signout()
    .then(() => {
      window.location.href = getRedirectUrl(props.location);

      return null;
    })
    .catch(() => {
      return null;
    });

  return <div />;
};
const Logout = connect(controller)(LogoutComp);

export {Login, Logout};
export default connect(Login, controller);
