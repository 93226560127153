import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import messages from './messages';

import ConnectedSocialLogin from './';

import './social-login-with-banner.scss';

const SocialLoginWithBanner = (props) => {
  const {loginType} = props;

  return (
    <div className="social-login-with-banner">
      <p className="social-login-with-banner__label">
        <FormattedMessage {...messages[loginType === 'signUp' ? 'signupFaster' : 'signinFaster']} />
      </p>
      <ConnectedSocialLogin {...props} />
    </div>
  );
};

export default injectIntl(SocialLoginWithBanner);
