import PropTypes from 'prop-types';

import {toBase64} from '@stubhub/general-utils';
import {useSubscriber} from '@stubhub/pubsub-utils';
import {connect} from '@stubhub/react-store-provider';

import {LOGIN_EVENTS, SIGNUP_EVENTS} from '../apps/events-enum';

import controller from './controller';
import {pageView, loginSuccess, signUpSuccess} from './track-utils';

const TrackListener = (props, {track, cookies}) => {
  const loginTracking = {
    [LOGIN_EVENTS.LOADED]: (redirect) => {
      const {storeId, locale, storeCountry, isAdvisoryCurrencyEnabled, advisoryCurrency} = props;
      const userGUID = cookies?.get('session_userGUID');
      pageView(track, {
        screenName: 'login',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        redirect,
      });
    },
    [LOGIN_EVENTS.SUCCESS]: ({redirect, method = 'stubhub'}) => {
      const userGUID = cookies.get('session_userGUID');
      loginSuccess(track, {
        redirect,
        method,
        userGUID,
      });
    },
  };
  const signUpTracking = {
    [SIGNUP_EVENTS.LOADED]: (redirect) => {
      const {storeId, locale, storeCountry, isAdvisoryCurrencyEnabled, advisoryCurrency} = props;
      const userGUID = cookies?.get('session_userGUID');
      pageView(track, {
        screenName: 'sign_up',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        redirect,
      });
    },
    [SIGNUP_EVENTS.SOCIAL]: (redirect) => {
      const {storeId, locale, storeCountry, isAdvisoryCurrencyEnabled, advisoryCurrency} = props;
      const userGUID = cookies.get('session_userGUID');
      pageView(track, {
        screenName: 'social_sign_up',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        redirect,
      });
    },
    [SIGNUP_EVENTS.SUCCESS]: ({redirect, method, userEmail, userFirstName, userLastName, marketingEmailOptIn}) => {
      const {uncheckMarketingOptIn} = props;
      const userGUID = cookies.get('session_userGUID');
      const marketingComms =
        !uncheckMarketingOptIn || marketingEmailOptIn === 'N' || !marketingEmailOptIn ? 'subscribed' : 'opted_in';
      signUpSuccess(track, {
        redirect,
        method,
        userGUID,
        userEmail: toBase64(userEmail),
        userFirstName: toBase64(userFirstName),
        userLastName: toBase64(userLastName),
        marketingComms,
      });
    },
  };

  useSubscriber(LOGIN_EVENTS.MAIN, loginTracking);
  useSubscriber(SIGNUP_EVENTS.MAIN, signUpTracking);

  return null;
};

TrackListener.propTypes = {
  storeId: PropTypes.string,
  locale: PropTypes.string,
  storeCountry: PropTypes.string,
  isAdvisoryCurrencyEnabled: PropTypes.bool,
  advisoryCurrency: PropTypes.string,
};

TrackListener.contextTypes = {
  track: PropTypes.object,
  cookies: PropTypes.object,
};

export default connect(controller)(TrackListener);
