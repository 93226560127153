import React from 'react';
import PropTypes from 'prop-types';
import {parse} from 'query-string';

import {post} from '@stubhub/rest-method';

export const ProxyLogin = (LoginComponent) => {
  class LoginWrapper extends React.Component {
    domain;
    location;
    state = {
      showLogin: false,
    };
    static contextTypes = {
      cookies: PropTypes.object,
      globalRegistry: PropTypes.object,
    };
    getUrlParams = () => {
      let queryParams = this.location.search ? this.location.search.substring(1) : this.location.search;
      queryParams = parse(queryParams);

      return queryParams;
    };
    getUserDetails = () => {
      const loginDataArray = this.getUrlParams();

      return {
        email_address: loginDataArray.pxy_email,
        operator_app: loginDataArray.pxy_app,
        operator_id: loginDataArray.pxy_op_id,
        operator_role: loginDataArray.pxy_op_role,
      };
    };

    signInUser = (userCredentials) => {
      const headers = {};
      const {cookies, globalRegistry} = this.context;
      const lang = globalRegistry.getDefaultLocale();

      if (lang) {
        headers['Accept-Language'] = lang;
      }

      return post({
        host: process.env.REACT_APP_API_HOST,
        path: '/login/proxy/signin',
        json: true,
        headers,
        query: userCredentials,
      })
        .then((res) => {
          // eslint-disable-next-line promise/always-return
          try {
            const data = res.login;
            const {domain} = this;
            cookies.set('session_sessionId', data.session_id, {domain, path: '/', secure: true});
            cookies.set('session_userGUID', data.user_guid, {domain, path: '/', secure: true});
            cookies.set('track_session_userGUID', data.user_guid, {domain, path: '/', secure: true});
            cookies.set('session_uAuthenticated', 1, {domain, path: '/', secure: true});
            cookies.set('session_login_type', 'stubhub', {domain, path: '/', secure: true});
            cookies.set('session_contactGUID', data.contact_guid, {domain, path: '/', secure: true});
            cookies.set('session_CSRFtoken', data.csrf_token, {domain, path: '/', secure: true});
          } catch (e) {
            this.setState({
              showLogin: true,
            });
            throw new Error(e);
          }
        })
        .catch(() => {
          this.setState({
            showLogin: true,
          });
        });
    };

    componentDidMount() {
      const {cookies} = this.context;
      this.location = typeof __CLIENT__ !== 'undefined' && __CLIENT__ && window.location;
      const {hostname} = this.location;
      this.domain = hostname && hostname.replace(/^(www\.*\.)/, '.');
      const {pxy_email} = this.getUrlParams();
      const MILLISECONDS_OF_TIMEOUT = 5000;

      if (pxy_email) {
        cookies.set('session_proxyLogin', true, {domain: this.domain, path: '/', secure: true});
        cookies.set('session_proxy_signin', 1, 'session', MILLISECONDS_OF_TIMEOUT);
        this.signInUser(this.getUserDetails());
      } else {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          showLogin: true,
        });
      }
    }

    renderLayout = () => {
      return this.children || null;
    };

    render() {
      const {children, ...others} = this.props;

      this.children = children;

      return this.state.showLogin ? <LoginComponent {...others} render={this.renderLayout} /> : null;
    }
  }
  ProxyLogin.displayName = `ProxyLogin(${LoginComponent.displayName || LoginComponent.name || 'ProxyLoginComponent'}`;

  return LoginWrapper;
};

export default ProxyLogin;
