import React from 'react';
import PropTypes from 'prop-types';

const GlobalCssValues = ['initial', 'inherit', 'unset', undefined];

const WrapValue = ['nowrap', 'wrap', 'wrap-reverse', ...GlobalCssValues];

const JustifyValue = [
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'left',
  'right',
  'baseline',
  'first baseline',
  'last baseline',
  'space-between',
  'space-around',
  'space-evenly',
  'stretch',
  'safe center',
  'unsafe center',
  ...GlobalCssValues,
];

const AlignValue = [
  'normal',
  'stretch',
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'self-start',
  'self-end',
  'left',
  'right',
  'first baseline',
  'last baseline',
  'safe center',
  'unsafe center',
  ...GlobalCssValues,
];

const FlexBox = ({style, ...props}) => (
  <div
    {...props}
    style={{
      ...style,
      display: 'flex',
      flex: props.auto ? '1 1 auto' : null,
      justifyContent: props.justify ? props.justify : null,
      alignItems: props.align ? props.align : null,
      flexWrap: props.wrap ? props.wrap : null,
      flexDirection: props.column
        ? `column${props.reverse ? '-reverse' : ''}`
        : `row${props.reverse ? '-reverse' : ''}`,
    }}
  >
    {props.children}
  </div>
);

FlexBox.propTypes = {
  auto: PropTypes.bool,
  column: PropTypes.bool,
  reverse: PropTypes.bool,
  justify: PropTypes.oneOf(JustifyValue),
  align: PropTypes.oneOf(AlignValue),
  wrap: PropTypes.oneOf(WrapValue),
  className: PropTypes.string,
};

export default FlexBox;
