import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';

import {HTTP_CODES} from '@stubhub/general-utils';
import FormattedLink from '@stubhub/react-formatted-link';
import Spinner from '@stubhub/react-spinner';
import {connect} from '@stubhub/react-store-provider';

import LegalTerms from '../react-legal-terms';
import {InputWithError} from '../react-ui-components/input';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import PasswordValidator, {ConfirmPwdValidator} from '../react-ui-components/password-validator';
import validate, {password_validator} from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';

import controller from './controller';
import messages from './messages';

import './index.scss';

export class CreatePassword extends Component {
  state = {
    password: '',
    confirmPwd: '',
    backendError: false,
    showPasswordError: false,
    showConfirmPwdError: false,
    email: '',
    isLoading: true,
    code: '',
  };

  static propTypes = {
    // Props injected by associated controller
    location: PropTypes.object,
    submitting: PropTypes.bool,
  };

  /*
   * ComponentWillUnmount() {
   *   this.props.reset();
   * }
   */
  async componentDidMount() {
    const {lang, location} = this.props;
    const {search} = location;
    if (search) {
      // eslint-disable-next-line prefer-destructuring
      const code = search.split('code=')[1];
      const headers = lang ? {'Accept-Language': lang} : {};

      try {
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_HOST}/password/guestValidate`,
          responseType: 'json',
          headers,
          data: {
            code,
          },
        });
        const {statusCode, body} = response.data;
        if (statusCode === HTTP_CODES.OK) {
          const {email, requireCreatePwd} = body;
          if (requireCreatePwd) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({isLoading: false, email, code});
          } else {
            this.props.history.replace('/login');
          }
        } else {
          this.props.history.replace('/login');
        }
      } catch (error) {
        this.props.history.replace('/login');
      }
    } else {
      this.props.history.replace('/login');
    }
  }

  handleOnChange = (field) => (value) => {
    const values = {
      [field]: value,
    };
    let showPasswordError = false;
    let showConfirmPwdError = false;

    /* istanbul ignore else */
    if (field === 'password') {
      showPasswordError = validate(values)[field];
      /* istanbul ignore next */
      showConfirmPwdError = this.state.confirmPwd && this.state.confirmPwd !== value;
    } else if (field === 'confirmPwd') {
      showConfirmPwdError = value && value !== this.state.password;
    }

    this.setState({
      [field]: value,
      showPasswordError,
      showConfirmPwdError,
    });
  };

  getSubmitButton = () => {
    const {submitting} = this.props;

    if (submitting) {
      return (
        <UIButton disabled type="submit">
          <FormattedMessage {...messages.createButton} />
        </UIButton>
      );
    }

    return (
      <UIButton
        disabled={
          !(this.state.password && this.state.confirmPwd) ||
          this.state.showPasswordError ||
          this.state.showConfirmPwdError
        }
        type="submit"
      >
        <FormattedMessage {...messages.createButton} />
      </UIButton>
    );
  };

  afterSuccess = () => {
    window.location.replace('/my/orders/my/orders');
  };

  afterFail = (errorType) => {
    const {intl} = this.props;

    if (errorType === 'CREATE_PASSWORD') {
      const errorMessage = intl.formatMessage(messages.genericError);
      this.setState({
        backendError: errorMessage,
      });
    } else {
      this.props.history.replace('/login');
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const {createPassword} = this.props;
    const {email, code, password} = this.state;
    const newState = {
      backendError: '',
      showPasswordError: false,
      showConfirmPwdError: false,
    };
    this.setState(newState);
    /* istanbul ignore else */

    createPassword(email, code, password, this.afterSuccess, this.afterFail);
  };

  render() {
    const {intl, username} = this.props;
    const {password, confirmPwd, backendError, showConfirmPwdError, isLoading} = this.state;
    const {score, validators} = password_validator(password);

    const getCreatePassword = () => {
      return (
        <div className="reset-password__container">
          <LoginHeader title={intl.formatMessage(messages.createPassword)}>
            <FormattedMessage {...messages.createPassword} />
          </LoginHeader>
          <div className="reset-password__wrapper">
            {backendError && <LoginAlert>{backendError}</LoginAlert>}
            {username && (
              <div className="reset-password__hint">
                <FormattedMessage {...messages.createPasswordFor} values={{email: <b>{username}</b>}} />
              </div>
            )}
            <form name="resetpassword-form" className="reset-password__form" onSubmit={this.onSubmit}>
              <div className="reset-password__form__resetpassword">
                <InputWithError
                  label={intl.formatMessage(messages.passwordPlaceHolder)}
                  name="password"
                  type="password"
                  autoComplete="password"
                  autoCapitalize="off"
                  onChange={this.handleOnChange('password')}
                  value={password}
                  isPassword
                  showErrorBorder={password && !score}
                  errorMessage={
                    password && (
                      <PasswordValidator
                        {...{
                          score,
                          validators,
                        }}
                      />
                    )
                  }
                />
                <InputWithError
                  label={intl.formatMessage(messages.repeatPwdPlaceHolder)}
                  name="confirmPwd"
                  type="password"
                  autoComplete="password"
                  autoCapitalize="off"
                  onChange={this.handleOnChange('confirmPwd')}
                  value={confirmPwd}
                  isPassword
                  showErrorBorder={showConfirmPwdError}
                  errorMessage={showConfirmPwdError && <ConfirmPwdValidator />}
                />
                {this.getSubmitButton()}
                <LegalTerms isSignUp />
              </div>
            </form>
          </div>
        </div>
      );
    };

    const getSpinner = () => {
      return (
        <div>
          <header className="central-login__header">
            <FormattedLink href="/" className="central-login__logo" />
          </header>
          <div className="spinner__container">
            <Spinner />
          </div>
        </div>
      );
    };

    return isLoading ? getSpinner() : getCreatePassword();
  }
}

export default withRouter(connect(controller)(injectIntl(CreatePassword)));
