import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';

import {FormMessage, Input} from '@stubhub/bamboo';
import {HTTP_CODES, TIME} from '@stubhub/general-utils';
import {publish} from '@stubhub/pubsub-utils';
import FormattedLink from '@stubhub/react-formatted-link';
import {connect} from '@stubhub/react-store-provider';

import {LOGIN_EVENTS} from '../../apps/events-enum';
import {getRedirectUrl} from '../../util/helper';
import LegalTerms from '../react-legal-terms';
import LoginLink from '../react-login-link';
import LoginAlert from '../react-ui-components/login-alert';
import LoginHeader from '../react-ui-components/login-header';
import validate from '../react-ui-components/validate';
import UIButton from '../react-uikit-button';
import CheckBox from '../react-uikit-checkbox';

import controller from './controller';
import messages from './messages';

import './index.scss';

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      backendError: '',
      showEmailError: false,
      staySignIn: true,
      buttonEnabled: false,
      hidePassword: true,
      displayForm: false,
    };
  }

  static contextTypes = {
    globalRegistry: PropTypes.object,
    track: PropTypes.object,
  };

  static propTypes = {
    onForgotPasswordClick: PropTypes.func,
    onSignUpClick: PropTypes.func,
    onGuestClick: PropTypes.func,
    signIn: PropTypes.func,
    socialButtonEnabled: PropTypes.bool,
    renderSocialLogin: PropTypes.func,
  };

  static defaultProps = {
    socialButtonEnabled: true,
  };

  trackingOptions(appInteraction) {
    const {track} = this.context;
    const appInteractionType = 'NE: Login Component';
    const s = __CLIENT__ && window.s;

    if (!s) {
      return;
    }
    const trackingObj = {
      appInteraction: `${appInteractionType}: ${appInteraction}`,
      appInteractionType,
      userStatus: s.eVar69,
      userGUID: s.eVar66,
      socialSiteInteraction: s.eVar25,
      login_type: s.eVar105,
    };

    if (track) {
      track.click(trackingObj.appInteractionType, trackingObj);
    }

    return trackingObj;
  }

  componentDidMount() {
    publish(LOGIN_EVENTS.LOADED, getRedirectUrl(this.props.location));
    setTimeout(() => this.setState({buttonEnabled: true}), TIME.MILLIS_OF_SECOND);

    // Workaround to properly focus on email input when rendering
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({displayForm: true});
  }

  componentWillUnmount() {
    // Reset redux data
    this.props.reset();
  }

  onOrderLookup = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onGuestClick && this.props.onGuestClick();
  };

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const userInputdata = {
      username: this.state.email,
      password: this.state.password,
      staySignIn: this.state.staySignIn,
    };

    this.setState({
      backendError: '',
    });
    this.trackingOptions('Sign In: StubHub Sign In Click');
    this.props.loginWithPassword(
      userInputdata,
      () => {
        this.trackingOptions.bind(this, 'Sign In: Stubhub Sign in Success');
        if (__CLIENT__) {
          publish(LOGIN_EVENTS.SUCCESS, {redirect: getRedirectUrl(this.props.location), method: 'stubhub'});
        }
      },
      this.afterFail
    );
  };

  afterFail = (error) => {
    let errorMessage = '';

    try {
      const {body, status} = error;
      const code = body.error;

      switch (status) {
        case HTTP_CODES.BAD_REQUEST:
        case HTTP_CODES.FORBIDDEN:
          if (status === HTTP_CODES.FORBIDDEN && code === 'EXCEED_LIMIT') {
            const contactUsUrl = (
              <FormattedLink target="_blank" href="/contact-us" className="contact-us-link">
                <FormattedMessage {...messages.contactUs} />
              </FormattedLink>
            );
            errorMessage = (
              <FormattedMessage {...messages.maxAttemptsExceedsErrorToContactUs} values={{contactUsUrl}} />
            );
          } else {
            errorMessage = <FormattedMessage {...messages.invalidCompoError} />;
          }
          break;
        case HTTP_CODES.UNAUTHORIZED:
        default:
          errorMessage = <FormattedMessage {...messages.genericError} />;
          break;
      }
    } catch (e) {
      errorMessage = <FormattedMessage {...messages.invalidCompoError} />;
    }

    this.setState({
      backendError: errorMessage,
    });
    this.trackingOptions.bind(this, 'Sign In: StubHub Sign In Failure');
  };

  onSignUpClick = () => {
    this.trackingOptions('Sign In: Sign Up Click');
    this.props.onSignUpClick();
  };

  onForgotPasswordClick = () => {
    this.trackingOptions('Sign In: Forgot Password Click');
    this.props.onForgotPasswordClick(this.state.email);
  };

  handleOnChange = (field) => (value) => {
    const fieldValue = value?.target.value;
    const values = {
      [field]: fieldValue,
    };
    let showEmailError = false;

    if (field === 'email') {
      showEmailError = validate(values).email;
    }

    this.setState({
      [field]: fieldValue,
      showEmailError,
    });
  };

  isGuestFlow = () => {
    const redirect = getRedirectUrl(this.props.location); // Window.location.search.split('&').find((it) => it.indexOf('redirect') !== -1).split('=')[1]

    return redirect && redirect.match(/\/buy|\/purchase|\/checkout\/review|\/checkout\/thankyou|\/next-checkout\//gi);
  };

  onGuestCheckoutClick = () => {
    const redirect = getRedirectUrl(this.props.location);
    window.location.href =
      redirect +
      (redirect.indexOf('isNewGuest=true') === -1
        ? `${redirect.indexOf('?') !== -1 ? '&isNewGuest=true' : '?isNewGuest=true'}`
        : '');
  };

  handleCheckboxChange = (checked) => {
    this.setState({
      staySignIn: checked,
    });
  };

  handleOnClickClearValue = () => {
    this.setState({email: ''});
  };

  handleOnClickChangeType = () => {
    this.setState({hidePassword: !this.state.hidePassword});
  };

  getSubmitButton = () => {
    const {email, password, showEmailError, buttonEnabled} = this.state;
    if (this.props.submitting) {
      return (
        <UIButton disabled type="submit">
          <FormattedMessage {...messages.signing} />
        </UIButton>
      );
    }

    return (
      <UIButton disabled={(!(email && password) || showEmailError) && !buttonEnabled} type="submit">
        <FormattedMessage {...messages.signin} />
      </UIButton>
    );
  };

  render() {
    const {renderSocialLogin, location, socialButtonEnabled, intl} = this.props;
    const {email, password, backendError, showEmailError, hidePassword, displayForm} = this.state;
    const displayEmailError = showEmailError && email;

    return (
      <section className="central-login-signin__container">
        <LoginHeader title={intl.formatMessage(messages.title)}>
          <FormattedMessage {...messages.title} />
        </LoginHeader>
        <div className="links__container">
          <div className="signup__link">
            <FormattedMessage {...messages.newtostubhub} />
            &nbsp;
            <LoginLink
              to={{
                pathname: '/login/signup',
                search: location.search,
              }}
              className="auth-link signup"
              onClick={this.onSignUpClick}
            >
              <FormattedMessage {...messages.signup} />
            </LoginLink>
          </div>
        </div>
        <div className="signin__form-wrapper">
          {backendError && <LoginAlert>{backendError}</LoginAlert>}
          {displayForm && (
            <form name="signin-form" onSubmit={this.onSubmit} autoComplete="on">
              <Input
                className={displayEmailError ? 'email-input-with-error' : 'email-input'}
                labelText={<FormattedMessage {...messages.email} />}
                id="email"
                name="email"
                type="email"
                autoComplete="on"
                autoCorrect="off"
                autoFocus={!email}
                onChange={this.handleOnChange('email')}
                value={email}
                status={displayEmailError && 'error'}
                rightElement={
                  <div
                    className="input-clear"
                    onMouseDown={this.handleOnClickClearValue}
                    onTouchStart={this.handleOnClickClearValue}
                  >
                    <span className="sh-iconset sh-iconset-close clear-search-button" />
                  </div>
                }
              />
              {displayEmailError && (
                <FormMessage id="email_input_error" className="email_input_error" status="error">
                  <FormattedMessage {...messages.emailError} />
                </FormMessage>
              )}
              <Input
                labelText={<FormattedMessage {...messages.password} />}
                id="password"
                name="password"
                type={!hidePassword ? 'text' : 'password'}
                autoComplete="on"
                autoCorrect="off"
                autoFocus={email && !password}
                onChange={this.handleOnChange('password')}
                value={password}
                rightElement={
                  <div
                    className="input-hide input-clear"
                    onMouseDown={this.handleOnClickChangeType}
                    onTouchStart={this.handleOnClickChangeType}
                  >
                    <span className={`sh-iconset ${hidePassword ? 'sh-iconset-unhide' : 'sh-iconset-hide'}`} />
                  </div>
                }
              />

              <div className="forgot-password__wrapper">
                <CheckBox
                  defaultChecked={this.state.staySignIn}
                  label={intl.formatMessage(messages.staySignIn)}
                  name="staySignIn"
                  handleCheckboxChange={this.handleCheckboxChange}
                />
                <LoginLink
                  to={{
                    pathname: '/login/forgot-password',
                    search: location.search,
                  }}
                  className="forgot-password__link"
                  onClick={this.onForgotPasswordClick}
                >
                  <FormattedMessage {...messages.forgotPassword} />
                </LoginLink>
              </div>
              {this.getSubmitButton()}
              {this.isGuestFlow() && (
                <UIButton secondary className="continue-guest" onClick={this.onGuestCheckoutClick}>
                  <FormattedMessage {...messages.guest} />
                </UIButton>
              )}
              {socialButtonEnabled && renderSocialLogin()}
              <LegalTerms />
            </form>
          )}
        </div>

        <div className="links__container">
          <FormattedMessage {...messages.haveAccessCode} />
          &nbsp;
          <FormattedLink className="continue-guest" onClick={this.onOrderLookup}>
            <FormattedMessage {...messages.findYourOrder} />
          </FormattedLink>
        </div>
      </section>
    );
  }
}

export default connect(controller)(injectIntl(SignIn));
