const baseRouteConfig = {
  pageType: 'Identity',
  siteSection: 'Login',
};

export default {
  base: {
    pageInfo: {
      pageName: 'Default Login',
      path: '/login',
      ...baseRouteConfig,
    },
  },
  bindSocial: {
    pageInfo: {
      pageName: 'Login - Social Connect',
      path: '/login/social-connect',
      ...baseRouteConfig,
    },
  },
  signUpSocial: {
    pageInfo: {
      pageName: 'Login - Social Sign Up',
      path: '/login/social-signup',
      ...baseRouteConfig,
    },
  },
  signup: {
    pageInfo: {
      pageName: 'Login - Sign Up',
      path: '/login/signup',
      ...baseRouteConfig,
    },
  },
  signOut: {
    pageInfo: {
      pageName: 'Login - Sign Out',
      path: '/login/signout',
      ...baseRouteConfig,
    },
  },
  forgotPassword: {
    pageInfo: {
      pageName: 'Login - Forgot Password',
      path: '/login/forgot-password',
      ...baseRouteConfig,
    },
  },
  resetPassword: {
    pageInfo: {
      pageName: 'Login - Reset Password',
      path: '/login/reset-password',
      ...baseRouteConfig,
    },
  },
  forceChangePassword: {
    pageInfo: {
      pageName: 'Login - Force Change Password',
      path: '/login/change-your-password',
      ...baseRouteConfig,
    },
  },
  createPassword: {
    pageInfo: {
      pageName: 'Login - Create Password',
      path: '/login/create-password',
      ...baseRouteConfig,
    },
  },
};
