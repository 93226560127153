import React from 'react';
import cls from 'classnames';
import {injectIntl} from 'react-intl';

import messages from './messages';

import './password-validator.scss';

/**
 * @param {number} score - a pure number value: 0 - weak, 1 - good, 2 - strong, 3 - very strong
 * @param {Object} validators - an object contains each rule passed or not: {length: true, digit: true, lowerCase: true, upperCase: false, symbol: false}
 * @param {Object} intl - react intl injected object
 * @returns {Object} an react component
 */
const PasswordValiator = ({score, validators, intl}) => {
  const {length, digit, symbol} = validators;
  const complexity = digit || symbol;
  const propKey = ['password', 'strength', 'score', score].join('_');

  return (
    <div className="password-validation__result">
      <div className={cls('strength rule', score ? 'passed' : '')}>
        {intl.formatMessage(messages.password_strength)}{' '}
        <span className="score">{intl.formatMessage(messages[propKey])}</span>
      </div>
      <div className={cls('length rule', length ? 'passed' : '')}>
        {intl.formatMessage(messages.password_rule_length)}
      </div>
      <div className={cls('symbol rule', complexity ? 'passed' : '')}>
        {intl.formatMessage(messages.password_rule_complexity)}
      </div>
    </div>
  );
};

/**
 * Generate a react component to render it in the case of password and confirm password not match
 * @param {Object} intl - react intl injected object
 * @returns {Object} an react component
 */
let ConfirmPwdValidator = ({intl}) => {
  return (
    <div className="password-validation__result">
      <div className="match rule">{intl.formatMessage(messages.confirmPwdNotMatch)}</div>
    </div>
  );
};

ConfirmPwdValidator = injectIntl(ConfirmPwdValidator);

export {ConfirmPwdValidator};
export default injectIntl(PasswordValiator);
