import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'CentralLogin.forgotPassword.title',
    defaultMessage: 'Forget password',
  },
  hint: {
    id: 'CentralLogin.forgotPassword.hint',
    defaultMessage:
      'Please enter your email address associated with your account, we’ll send you a link to reset your password.',
  },
  note1: {
    id: 'CentralLogin.forgotPassword.note1',
    defaultMessage: 'A link to reset your password has been sent to: {email}.',
  },
  note2: {
    id: 'CentralLogin.forgotPassword.note2',
    defaultMessage:
      'If you don’t receive it in a few minutes, check your junk mail folder, or try another email to reset.',
  },
  ok: {
    id: 'CentralLogin.forgotPassword.ok',
    defaultMessage: 'Resend email',
  },
  loading: {
    id: 'CentralLogin.forgotPassword.loading',
    defaultMessage: 'One moment...',
  },
  signin: {
    id: 'CentralLogin.forgotPassword.signin',
    defaultMessage: 'Return to sign in',
  },
  signup: {
    id: 'CentralLogin.forgotPassword.signup',
    defaultMessage: 'Sign up',
  },
  email: {
    id: 'CentralLogin.forgotPassword.email',
    defaultMessage: 'Email',
  },
  sendEmail: {
    id: 'CentralLogin.forgotPassword.sendEmail',
    defaultMessage: 'Send email',
  },
  invalidEmail: {
    id: 'CentralLogin.forgotPassword.invalidEmail',
    defaultMessage: 'Enter a valid email address.',
  },
  instruction: {
    id: 'CentralLogin.forgotPassword.instruction',
    defaultMessage: 'Still having trouble?',
  },
  retry: {
    id: 'CentralLogin.forgotPassword.retry',
    defaultMessage: 'Try another email',
  },
  contact: {
    id: 'CentralLogin.forgotPassword.contact.support',
    defaultMessage: 'Contact support',
  },
});

export default messages;
