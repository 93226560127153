/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

import FormattedLink from '@stubhub/react-formatted-link';

import LoginHeader from '../react-ui-components/login-header';
import UIButton from '../react-uikit-button';

import messages from './messages';

import './index.scss';

export class ForceChangePassword extends Component {
  constructor(props) {
    super(props);
  }

  static contextTypes = {
    globalRegistry: PropTypes.object,
    track: PropTypes.object,
  };

  trackingOptions(actionType) {
    const {track} = this.context;
    if (!track) {
      return;
    }

    const trackingPayload = {
      pageName: `Change your password ${actionType}`,
      Page_type: 'Reset Password',
      Prop61: `Change your password ${actionType}`,
      Prop62: actionType,
      Prop63: 'log-in',
    };

    if (actionType === 'Pageview') {
      track.view(actionType, trackingPayload);
    } else if (actionType === 'Click') {
      track.click(actionType, trackingPayload);
    }
  }

  onResetClick = () => {
    this.trackingOptions('Click');
    this.props.onResetClick();
  };

  render() {
    const {intl} = this.props;

    return (
      <section className="change-password__container">
        <LoginHeader title={intl.formatMessage(messages.forceChangePasswordTitle)}>
          <FormattedMessage {...messages.forceChangePasswordTitle} />
        </LoginHeader>
        <div className="change-password__wrapper">
          <div className="change-password-instruction1">
            <FormattedMessage {...messages.forceChangePasswordInstruction1} />
            &nbsp;
            <FormattedLink
              href="https://support.stubhub.com/en/support/solutions/articles/61000284827-changing-your-password-if-credentials-are-compromised"
              target="_blank"
            >
              <FormattedMessage {...messages.forceChangePasswordHelpLink} />
            </FormattedLink>
          </div>
          <div className="change-password-instruction2">
            <FormattedMessage {...messages.forceChangePasswordInstruction2} />
          </div>
          <UIButton onClick={this.onResetClick}>
            <FormattedMessage {...messages.forceChangePasswordSubmit} />
          </UIButton>
        </div>
      </section>
    );
  }
}

export default injectIntl(ForceChangePassword);
