import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createPassword: {
    id: 'CentralLogin.createPassword.heading',
    defaultMessage: 'Set up to get your tickets',
  },
  createPasswordFor: {
    id: 'CentralLogin.createPassword.hint',
    defaultMessage: 'Set up your password to create a StubHub account for {email}.',
  },
  createButton: {
    id: 'CentralLogin.createPassword.button',
    defaultMessage: 'Set up',
  },
  passwordPlaceHolder: {
    id: 'CentralLogin.createPassword.passwordPlaceHolder',
    defaultMessage: 'Password',
  },
  repeatPwdPlaceHolder: {
    id: 'CentralLogin.createPassword.repeatPwdPlaceHolder',
    defaultMessage: 'Re-enter Password',
  },
  genericError: {
    id: 'CentralLogin.createPassword.genericError',
    defaultMessage: 'Hmm, that didn\'t work. Give it another shot.',
  },
});

export default messages;
