import {parse} from 'query-string';
import isURL from 'validator/lib/isURL';

const getRedirectUrl = (location) => {
  const search = location?.search?.substring?.(1);
  const query = parse(search);
  const options = {
    protocols: ['https', 'http'],
    require_tld: false,
    require_host: false,
    require_protocol: true,
    host_whitelist:
      +process.env.REACT_APP_ENABLE_REDIRECT_SECURITY_CHECK === 1
        ? [
            window.location.host,
            'apc.stubhubdev.com',
            'apc.stubhub.com',
            'apc.stubclouddev.com',
            'apc.stubcloudprod.com',
          ]
        : null,
  };
  let {redirect} = query;

  if (!redirect || !isURL(redirect, options)) {
    // If not valid stubhub domain URL, then redirect to homepage
    redirect = __CLIENT__ && window.location.origin;
  }

  return redirect;
};

const getUserDeviceType = () => {
  let OSName = 'Unknow OS';
  if (window.navigator && !!window.navigator.platform) {
    const {platform} = window.navigator;
    if (/Mac/i.test(platform)) {
      OSName = 'Macintosh';
    }
    if (/Win/i.test(platform)) {
      OSName = 'Windows';
    }
    if (/iPhone/i.test(platform)) {
      OSName = 'iOS';
    }
    if (/Mac|iPhone|iPod|iPad/i.test(platform)) {
      OSName = 'Mac Like';
    }
    if (/Android/i.test(platform)) {
      OSName = 'Android';
    }
  }

  return OSName;
};
export const FooterLinks = (storeId, locale) => {
  let navLinks;
  if (locale) {
    try {
      navLinks = require(`../config/nav-links.config.${storeId}.${locale}.json`);
    } catch (e) {
      try {
        navLinks = require('../config/nav-links.config.json');
      } catch (e) {
        // Ignore
      }
    }
  }
  const links = {
    'about-us': '/about-us',
    help: navLinks,
    'press-box': 'https://www.stubhub.com/press',
    'contact-us': '/contact-us/',
    developers: 'https://developer.stubhub.com',
    careers: 'https://www.stubhub.jobs/',
    legal: '/legal/',
    partners: '/partners/',
    'gift-card': '/gift-cards/',
    advantage: '/promise/',
    notifications: '/notifications/',
    'events-by-venue': navLinks && navLinks['events-by-venue'] && navLinks['events-by-venue'].href,
    backstage: '/backstage/',
    live: 'https://www.stubhub.com/live/',
    magazine: '/magazine/',
    affiliates: navLinks && navLinks.affiliates && navLinks.affiliates.href,
    community: 'https://stubhub.community/',
    impressum: '/about-us?section=culture',
    restrictions: '/restrictions',
  };

  return links;
};

export {getRedirectUrl, getUserDeviceType};
