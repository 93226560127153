import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {withRouter} from 'react-router';

class RemoveParams extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    children: PropTypes.node,
  };

  UNSAFE_componentWillMount() {
    if (this.props.location.search !== '') {
      const {location} = this.props;
      let {search} = location;
      search = search.replace(/^\?/, '');
      const query = qs.parse(search);
      if (this.props.location.search.indexOf('redirect') === -1) {
        this.props.history.replace(`${this.props.match.url}`, query);
      }
    }
  }

  render() {
    return (
      <div className="url-container">
        {React.cloneElement(this.props.children, {...this.props.history.location.state})}
      </div>
    );
  }
}

export default withRouter(RemoveParams);
