/* eslint-disable no-console */
import {HTTP_CODES} from '@stubhub/general-utils';
import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';

import {processPasswordLoginWith2FA} from '../../util/api';

const CREATE_PASSWORD_START = 'CREATE_PASSWORD_START';
const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
const CREATE_PASSWORD_SIGNIN_SUCCESS = 'CREATE_PASSWORD_SIGNIN_SUCCESS';
const CREATE_PASSWORD_FAIL = 'CREATE_PASSWORD_FAIL';
const CREATE_PASSWORD_RESET = 'REGISTER_RESET';

const NAMESPACE = 'login_create_password';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const {submitting, signInSuccess} = this.getLocalState(state);
    const {lang} = state;

    return {
      submitting,
      signInSuccess,
      lang,
      reset,
    };
  },
  actionCreators: {
    createPassword,
  },
  reducers: [CREATE_PASSWORD_START, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_SIGNIN_SUCCESS, CREATE_PASSWORD_FAIL],
});

controller.addResetReducer([CREATE_PASSWORD_RESET]);

function reset() {
  return (dispatch) => {
    dispatch({type: CREATE_PASSWORD_RESET});
  };
}

function start() {
  return {type: CREATE_PASSWORD_START, submitting: true};
}

function success(response) {
  return {type: CREATE_PASSWORD_SUCCESS, submitting: true, registerPayload: response};
}

function signinSuccess() {
  return {type: CREATE_PASSWORD_SIGNIN_SUCCESS, submitting: false, signInSuccess: true};
}

function fail(errors) {
  return {type: CREATE_PASSWORD_FAIL, errors, submitting: false};
}

const _getRequestHeaders = (lang) => {
  const ret = {};

  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

function createPassword(username, code, password, afterSuccess, afterFail) {
  return async (dispatch, getState, {cookies}) => {
    const {lang} = getState();
    dispatch(start());
    try {
      const response = await post({
        host: process.env.REACT_APP_API_HOST,
        path: '/password/create',
        json: true,
        headers: _getRequestHeaders(lang),
        body: {
          code,
          password,
        },
      });
      console.log('create response: ', response);
      const {statusCode, body = {}} = response;
      if (statusCode === HTTP_CODES.OK) {
        dispatch(success(response));
        await processPasswordLoginWith2FA(
          {
            username,
            password,
            source: 'PWCREATE',
            staySignIn: true,
          },
          (dispatch) => {
            dispatch(signinSuccess());
            afterSuccess();
          },
          (dispatch, error) => {
            dispatch(fail(error));
            afterFail && afterFail('SIGN_IN');
          }
        )(dispatch, getState, {cookies});
      } else {
        dispatch(fail(body));
        afterFail && afterFail('CREATE_PASSWORD');
      }
    } catch (error) {
      dispatch(fail(error));
      afterFail && afterFail('CREATE_PASSWORD');
    }
  };
}

export default controller;
