import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'CentralLogin.tooManyAttempts.title',
    defaultMessage: 'Login temporarily locked',
  },
  subheader: {
    id: 'CentralLogin.tooManyAttempts.subheader',
    defaultMessage: 'Too many failed attempts',
  },
  message: {
    id: 'CentralLogin.tooManyAttempts.message',
    defaultMessage:
      'For security reasons, access has been temporarily restricted due to multiple failed login attempts. Please wait 10 minutes and try again.',
  },
});

export default messages;
