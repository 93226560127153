import {HTTP_CODES} from '@stubhub/general-utils';
import {Controller} from '@stubhub/react-store-provider';
import {post} from '@stubhub/rest-method';
import uuid from '@stubhub/visitor-id';

import {
  cacheTwoFADataAction,
  extractSocialData,
  getDestination,
  postLoginSuccess,
  sendApiErrorLog,
  SOCIAL_RESET_ALL_ACTION,
  switchLoginTypeAction,
} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

// Action Types
const SOCIAL_LOGIN_FAILED = 'SOCIAL_LOGIN_FAILED';
const SOCIAL_LOGIN_SUCCEEDED = 'SOCIAL_LOGIN_SUCCEEDED';
const SOCIAL_LOGIN_FLOW_TYPE_CHANGED = 'SOCIAL_LOGIN_FLOW_TYPE_CHANGED';
const SOCIAL_CONNECT_SUCCEEDED = 'SOCIAL_CONNECT_SUCCEEDED';
const SOCIAL_CONNECT_FAILED = 'SOCIAL_CONNECT_FAILED';
const USER_CONTACT_INFO_SAVED = 'USER_CONTACT_INFO_SAVED';
const CACHE_SOCIAL_DATA = 'CACHE_SOCIAL_DATA';
const SOCIAL_LOGIN_STATUS = 'SOCIAL_LOGIN_STATUS';

const MILLISECONDS_OF_TIMEOUT = 30000;

const _getRequestHeaders = (lang) => {
  const ret = {};

  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

const cacheSocialTypeAndData = (socialType, socialData) => {
  return (dispatch) => {
    dispatch({type: CACHE_SOCIAL_DATA, socialData: extractSocialData(socialType, socialData)});
  };
};

const processSocialDirectLogin = ({idpType, idpToken, idpUserId, cookies}) => {
  return async (dispatch, getState) => {
    dispatch({type: SOCIAL_LOGIN_STATUS, hasError: false, errorMsg: '', isFacebookIdentityLoginProcessing: true});

    const {lang, login_social = {}, login = {}} = getState();
    const {accertifyData = {}} = login;
    const {accertifyRefId = uuid()} = accertifyData;
    const {socialData = {}} = login_social;
    const {email, isPrivateEmail, lastName, firstName} = socialData;
    const destination = getDestination(lang);

    try {
      sendApiErrorLog({title: 'AccertifyLog', data: accertifyData});
    } catch {
      // Console.log("post api log failed");
    }

    try {
      const resBody = await post({
        host: process.env.REACT_APP_API_HOST,
        path: `/social/direct/${idpType}`,
        json: true,
        headers: _getRequestHeaders(lang),
        timeout: MILLISECONDS_OF_TIMEOUT,
        body: {
          socialData: {
            idpToken,
            idpUserId,
            accertifyRefId,
          },
          uaData: {
            customer: {
              acceptedAgreement: {destination},
            },
          },
        },
      });
      postLoginSuccess(cookies, resBody);
      dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
      dispatch({type: SOCIAL_RESET_ALL_ACTION});

      return true;
    } catch (e) {
      const errorBody = e.body;
      if (e.status === HTTP_CODES.UNAUTHORIZED && errorBody && errorBody.auth_status === 'MFA_NEED_CHALLENGE') {
        dispatch(resetStatus());
        dispatch(cacheTwoFADataAction(errorBody));
        dispatch(switchLoginTypeAction(LOGIN_TYPE.TWO_FA_SEND_CODE));
      } else if (
        e.status === HTTP_CODES.RESOURCE_NOT_FOUND &&
        errorBody &&
        errorBody.error === 'IDP_USER_NOT_CONNECTED'
      ) {
        if (isPrivateEmail) {
          socialSignUpLogin({idpType, idpToken, idpUserId, email, firstName, lastName}, getState, dispatch, cookies);
        } else {
          dispatch(resetStatus());
          dispatch(switchLoginTypeAction(LOGIN_TYPE.SIGN_UP_SOCIAL));
        }
      } else {
        dispatch(propagateError('facebookLoginGenericError'));
        // TODO errors
      }
      console.log('-- error in social signin --', errorBody); // eslint-disable-line

      return false;
    }
  };
};

const propagateError = (errorMsgKey) => {
  return {
    type: SOCIAL_LOGIN_FAILED,
    hasError: true,
    errorMsgKey,
    isFacebookIdentityLoginProcessing: false,
  };
};

const resetStatus = () => {
  return (dispatch) => {
    dispatch({type: SOCIAL_LOGIN_STATUS, hasError: false, errorMsg: '', isFacebookIdentityLoginProcessing: false});
  };
};

const socialSignUpLogin = async (
  {idpType, idpToken, idpUserId, firstName, lastName, email},
  getState,
  dispatch,
  cookies
) => {
  const {lang, gsConfig} = getState();
  const shstoreId = (gsConfig || {}).shstoreId || 1;
  try {
    const resBody = await post({
      host: process.env.REACT_APP_API_HOST,
      path: `/social/signup/${idpType}`,
      json: true,
      headers: _getRequestHeaders(lang),
      timeout: MILLISECONDS_OF_TIMEOUT,
      body: {
        idpToken,
        idpUserId,
        email,
        firstName,
        lastName,
        shstore: shstoreId.toString(),
      },
    });
    postLoginSuccess(cookies, resBody);
    dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
    dispatch({type: SOCIAL_RESET_ALL_ACTION});
  } catch (e) {
    dispatch(propagateError('generic_error'));
  }
};

const NAMESPACE = 'login_social';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const localState = this.getLocalState(state);
    const {lang} = state;

    return {
      errorMsgKey: localState.errorMsgKey,
      fbAppId: process.env.REACT_APP_FB_MESS_APP_ID || '983148971714807',
      hasError: localState.hasError,
      isFacebookIdentityLoginProcessing: localState.isFacebookIdentityLoginProcessing,
      lang,
      socialData: localState.socialData,
      socialLoginFlowType: localState.socialLoginFlowType,
      userContactInfo: localState.userContactInfo,
    };
  },
  actionCreators: {
    processSocialDirectLogin,
    cacheSocialTypeAndData,
    propagateError,
  },
  // TODO delete below
  reducers: [
    SOCIAL_LOGIN_SUCCEEDED,
    SOCIAL_LOGIN_FLOW_TYPE_CHANGED,
    SOCIAL_CONNECT_SUCCEEDED,
    SOCIAL_CONNECT_FAILED,
    USER_CONTACT_INFO_SAVED,

    CACHE_SOCIAL_DATA,
    SOCIAL_LOGIN_STATUS,
    SOCIAL_LOGIN_FAILED,
  ],
});

controller.addResetReducer([SOCIAL_RESET_ALL_ACTION]);

export default controller;
