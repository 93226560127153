import {shstoreIdMappings} from '@stubhub/general-utils';
import {getSelectedCurrencyCode} from '@stubhub/react-currency-exchange';
import getStoreCurrencyCode from '@stubhub/react-store-default-currency';
import {Controller} from '@stubhub/react-store-provider';

import {
  isAdvisoryCurrencyFFEnabledSelector,
  isAdvisoryCurrencyOptOutFFEnabledSelector,
} from '../store/advisoryCurrency/selectors';
import {currentLocaleSelector, storeIdSelector, isMarketingOptInFFEnabledSelector} from '../store/gsConfig/selectors';

const NAMESPACE = 'tracking';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps: (state) => {
    const storeId = storeIdSelector(state);
    const {site: storeCountry} = shstoreIdMappings[storeId] || {};

    const isAdvisoryOptOutEnabled = isAdvisoryCurrencyOptOutFFEnabledSelector(state);
    let advisoryCurrency = getSelectedCurrencyCode();
    if (isAdvisoryOptOutEnabled && !advisoryCurrency) {
      advisoryCurrency = getStoreCurrencyCode(storeId);
    }

    return {
      storeId,
      storeCountry,
      locale: currentLocaleSelector(state),
      isAdvisoryCurrencyEnabled: isAdvisoryCurrencyFFEnabledSelector(state),
      uncheckMarketingOptIn: isMarketingOptInFFEnabledSelector(state),
      advisoryCurrency,
    };
  },
});

export default controller;
