import {Controller} from '@stubhub/react-store-provider';
import {put} from '@stubhub/rest-method';

import {processPasswordLoginWith2FA, switchLoginTypeAction} from '../../util/api';
import {LOGIN_TYPE} from '../react-login/controller';

const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_SIGNIN_SUCCESS = 'RESET_PASSWORD_SIGNIN_SUCCESS';
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
const RESET_PASSWORD_RESET = 'REGISTER_RESET';

const NAMESPACE = 'login_reset_password';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps(state) {
    const {submitting, signInSuccess} = this.getLocalState(state);
    let {lang = ''} = state;
    const {login = {}} = state;
    const {twoFaData, forceResetData} = login;
    const username = twoFaData ? twoFaData.username : '';
    lang = lang.slice(0, 2);

    return {
      submitting,
      signInSuccess,
      username,
      isAccountCompromised: !!forceResetData,
      forceResetData,
      lang,
    };
  },
  actionCreators: {
    resetPassword,
    changePassword,
    reset,
    closeResetPassword,
  },
  reducers: [RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_SIGNIN_SUCCESS, RESET_PASSWORD_FAIL],
});

controller.addResetReducer([RESET_PASSWORD_RESET]);

function reset() {
  return (dispatch) => {
    dispatch({type: RESET_PASSWORD_RESET});
  };
}

function start() {
  return {type: RESET_PASSWORD_START, submitting: true};
}

function success(response) {
  return {type: RESET_PASSWORD_SUCCESS, submitting: true, registerPayload: response};
}

function signinSuccess() {
  return {type: RESET_PASSWORD_SIGNIN_SUCCESS, submitting: false, signInSuccess: true};
}

function fail(errors) {
  return {type: RESET_PASSWORD_FAIL, errors, submitting: false};
}

const _getRequestHeaders = (lang) => {
  const ret = {};

  /* istanbul ignore else */
  if (lang) {
    ret['Accept-Language'] = lang;
  }

  return ret;
};

function closeResetPassword() {
  return (dispatch) => {
    dispatch(switchLoginTypeAction(LOGIN_TYPE.LOGIN_SUCCESS));
  };
}

function resetPassword(token, password, afterSuccess, afterFail) {
  return async (dispatch, getState, {cookies}) => {
    const {lang} = getState();
    dispatch(start());
    try {
      const response = await put({
        host: process.env.REACT_APP_API_HOST,
        path: '/password/reset',
        json: true,
        headers: _getRequestHeaders(lang),
        body: {
          resetPasswordToken: token,
          newPassword: password,
        },
      });
      const username = response.email;
      dispatch(success(response));
      (await afterSuccess) && afterSuccess(response);
      await processPasswordLoginWith2FA(
        {
          username,
          password,
          source: 'PWRESET',
          staySignIn: true,
        },
        (dispatch) => {
          dispatch(signinSuccess());
        },
        (dispatch, error) => {
          dispatch(fail(error));
          afterFail && afterFail(error);
        }
      )(dispatch, getState, {cookies});
    } catch (error) {
      dispatch(fail(error));
      afterFail && afterFail(error);
    }
  };
}

function changePassword(username, guid, otpId, otpCode, newPassword, afterSuccess, afterFail) {
  return async (dispatch, getState, {cookies}) => {
    const {lang} = getState();
    dispatch(start());
    try {
      const response = await put({
        host: process.env.REACT_APP_API_HOST,
        path: `/password/change/${guid}`,
        headers: {
          ..._getRequestHeaders(lang),
          'content-type': 'application/json',
          'x-csrf-token': cookies.get('session_CSRFtoken'),
        },
        body: JSON.stringify({
          otpId,
          otpCode,
          newPassword,
        }),
      });
      dispatch(success(response));
      dispatch(signinSuccess());
      (await afterSuccess) && afterSuccess(username);
    } catch (error) {
      dispatch(fail(error));
      afterFail && afterFail(error);
    }
  };
}

export default controller;
